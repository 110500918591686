import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import notify from '../notifications-helper'
import api from '../api-client'
import CompanySearch from '../company-search'
import { FaExclamationTriangle, FaTimesCircle, FaCheck } from 'react-icons/fa'
import Spinner from '../spinner'

type Props = {
  parentId?: string;
  onChange: (id: string, name: string) => void;
}

const CorporateParentEditor: React.FC<Props> = ({ parentId, onChange }) => {
  const [parentName, setParentName] = useState('')
  const [hasInput, setHasInput] = useState(false)
  const [isValid, setIsValid] = useState(false)

  const dispatch = useDispatch()
  const changeTimeout = useRef<number>(NaN)

  const verify = (nameOrId: string) => {
    api.verifyCorporateParent(nameOrId).then(({ id, name }) => {
      setIsValid(!!id)
      if (id) {
        setParentName(name)
        setHasInput(true)
      }
    }).catch(ex => dispatch(notify.error({
      message: `error verifying corporate parent: ${ex.message}`
    })))
  }

  useEffect(() => {
    if (parentId) {
      verify(parentId)
    }
  }, [parentId])

  const flags = () => {
    if (!hasInput) {
      return 
    }

    return isValid ? <FaCheck style={{ color: 'green' }} /> : (
      <span title="Could not find parent company">
        <FaExclamationTriangle style={{ color: 'orange' }} />
      </span>
    )
  }

  return (
    <div className="form-group" >
      <label>Corporate Parent</label>
      <CompanySearch
        initialSearch={parentName}
        autoFocus={false}
        hideNoResults={true}
        inputPrependChildren={flags()}
        inputAppendChildren={<span style={{ cursor: 'pointer' }}>
          <FaTimesCircle onClick={() => {
            setParentName('')
            setIsValid(false)
            onChange(null, null)
          }} />
        </span>}
        onInputChange={ev => {
          const val = ev.target.value
          setHasInput(!!ev.target.value)
          if (changeTimeout.current) {
            clearTimeout(changeTimeout.current)
          }

          changeTimeout.current = setTimeout(() => {
            verify(val)
          }, 300)
        }}
        onSelect={(id, co) => {
          onChange(id, co.name)
        }}
      />
    </div>
  )
}

export default CorporateParentEditor