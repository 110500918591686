import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { v4 } from 'uuid'
import { IImpactArea, Indicator } from '@ecountabl/lib'

import api from './api-client'
import Spinner from './spinner'
import notify from './notifications-helper'
import { Dictionary } from '@reduxjs/toolkit'
import IconChooser from './icon-chooser'

export const ImpactAreaAdmin = () => {
  const dispatch = useDispatch()

  const [areas, setAreas] = useState<IImpactArea[]>([])
  const [valueOptions, setValueOptions] = useState([])
  const [indicators, setIndicators] = useState([])
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(true)
  const [hasChanges, setHasChanges] = useState(false)
  const [indsByAbb, setIndsByAbb] = useState<Dictionary<Indicator>>({})

  useEffect(() => {
    Promise.all([
      api.getImpactAreas(),
      api.getValues(),
      api.getAdminIndicators()
    ]).then(([_areas, _values, { indicators, byAbbreviation }]) => {
      setAreas(_areas.list)

      setValueOptions(
        _values.sort((a, b) => a.label.localeCompare(b.label))
          .map(v => ({
            label: v.label,
            value: v.slug
          })))

      setIndicators(indicators)
      setIndsByAbb(byAbbreviation)

      setLoading(false)
    })
  }, [])

  const onSubmit = () => {
    setSaving(true)
    api.putImpactAreas(areas).then(res => {
      setSaving(false)
      dispatch(notify.success({ message: 'Impact Area configuration saved successfully' }))
    }).catch(ex => {
      dispatch(notify.error({ message: ex.message }))
    })
  }

  const handleChange = (area: IImpactArea, field: string, value: any) => {
    const _areas = [...areas]
    const _area = { ...area }
    const idx = _areas.findIndex(a => a._id === _area._id)
    _area[field] = value
    _areas.splice(idx, 1, _area)
    setAreas(_areas)
    setHasChanges(true)
  }

  if (loading) {
    return <Spinner />
  }

  return <div className="admin-page">
    <div className="container">
      <h2>Impact Areas</h2>
      <div className="detail-editor">
        {areas.length === 0
          ? <div className="alert alert-secondary">No Impact Areas Configured</div>
          : <></>
        }
        {areas.map((area, idx) => {
          return <div key={idx} className="impact-area-form">
            <div style={{
              position: 'absolute',
              right: 0,
              top: 0,
              padding: 5
            }}>
              <span onClick={() => {
                setAreas(areas.filter(a => a._id != area._id))
                setHasChanges(true)
              }}><i className="fa fa-trash" /></span>
            </div>
            <div className="form-wrap">
              <div className="form-group">
                <label>Label</label>
                <input
                  value={area.label || ''}
                  name="label"
                  onChange={e => handleChange(area, 'label', e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group">
                <label>Display Under Value</label>
                <select
                  className="form-control"
                  onChange={e => handleChange(area, 'valueSlug', e.target.value)}
                  value={area.valueSlug}
                >
                  <option></option>
                  {valueOptions.map(v => (
                    <option key={v.value} value={v.value}>{v.label}</option>
                  ))}
                </select>
              </div>
              <div className="form-group">
                <label>Consumer Facing Label</label>
                <input
                  value={area.description || ''}
                  name="description"
                  onChange={e => handleChange(area, 'description', e.target.value)}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group" style={{ minWidth: '400px' }}>
                <label>Impact Language</label>
                <input
                  value={area.impactLanguage || ''}
                  name="impactLanguage"
                  onChange={e => handleChange(area, 'impactLanguage', e.target.value)}
                  type="text"
                  className="form-control"
                  placeholder={`e.g., "${area.label?.toLowerCase()} loans funded"`}
                />
                <p className="faq-ex">
                  {area.impactLanguage ? `Ex: 84 ${area.impactLanguage}` : '<not yet defined>'}
                </p>
              </div>
              <div className="form-group" style={{ flex: 0 }}>
                <label>Hex Color</label>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                  <input
                    value={area.hexColor || ''}
                    name="hexColor"
                    onChange={e => handleChange(area, 'hexColor', e.target.value)}
                    type="text"
                    className="form-control"
                    placeholder={`#ff0000`}
                  />
                  <div style={{
                    backgroundColor: area.hexColor || 'transparent',
                    width: '40px',
                    border: '1px solid #ccc',
                    marginLeft: 10
                  }}></div>
                </div>
              </div>
            </div>
            <div className="form-group ic-wrap">
              <label>Icon</label>
              <IconChooser
                value={area.icon}
                onChange={i => handleChange(area, 'icon', i)}
              />
            </div>
          </div>
        })}
      </div>
      <div className="buttons">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!hasChanges || saving}
          onClick={onSubmit}>{saving ? <Spinner /> : "Save Changes"}</button>
        <button
          type="button"
          className="btn btn-success"
          disabled={saving}
          onClick={() => {
            setAreas([...areas, {
              _id: 'ia_' + v4().split('-').pop(),
              label: '',
              valueSlug: ''
            }])
            setHasChanges(true)
          }}>Add</button>
      </div>
    </div>
  </div>
}

export default ImpactAreaAdmin