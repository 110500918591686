import { ObjectId } from "mongodb"

enum IndicatorScale {
  YesNo = "Y/N",
  AtoC = "A to C",
  AtoF = "A to F",
  SBTI = "SBTI"
}

export type FAQLanguage = {
  yes ?: string
  no ?: string
  q: string
  nonBinaryAnswer?: string
  attribution?: string
}

export type IndicatorAttachment = "bank" | "biz"

export interface Indicator {
  _id: ObjectId | string
  abbreviation: string            // "wasi",
  attachment?: IndicatorAttachment     // is the indicator tracked at a bank or business level?
  certification_value?: string    // if rating matches this, it's considered a certification
  data_year: string               // "2020",
  description: string
  faqLanguage?: FAQLanguage
  included_in_scoring?: boolean
  indicator_family: string        // "WeAreStillIn",
  impact_area?: string            // id of impact area
  label: string                   // "Paris Climate Commitment",
  logo_height: number             // 58,
  logo_width: number              // 124,
  name: string                    // "WeAreStillIn"
  rating_type: string             // "Commitment",
  scale: IndicatorScale           // "Y/N",
  segment: string                 // "Climate Protection",
  seo_slug: string                // current seo slug
  seo_slugs: string[]             // historical slugs so we can 301 redirect if needed
  slug: string                    // "CLIMATE_PROTECTION",
  superceded_by?: string          // abbreviation of the indicator that supercedes it
  twitter: string                 // "wearestillin",
  weight_in_segment: number       // 20,
}

export interface ITag {
  _id: string
  type?: 'TAG' | 'FROM_INDICATOR'
  label?: string // deprecated
  featured?: boolean
  enabled?: boolean
  imgUrl?: string

  // used in app when displaying featured tag section
  featuredHeader?: string
  featuredSubtext?: string

  indicator?: {
    abbreviation: string
    targetValue: {
      equals?: string | number
      greaterThan?: number
    }
  }
}
