import React, { useState, useEffect } from 'react'
import './App.scss'
import { Provider } from 'react-redux'
import Login from './login'
import SignUp from './sign-up'
import Spinner from './spinner'
import api from './api-client'
import Page from './page'
import PublicPage from './public-page'
import Home from './home'
import CompanyLandingPage from './company-landing-page'
import { Redirect, Router, Switch, Route } from 'react-router-dom'
import history from './history'
import { useSelector } from 'react-redux'
import { setUser } from './redux/slices/user'
import store from './redux/store'
import UserSite from './user-site'

const App = (props) => {
  const [loading, setLoading] = useState(true)

  const { user, hasToken } = useSelector(s => s.user)

  useEffect(() => {
    if (user) {
      if (user.isSuperUser && ['/', '/login'].includes(window.location.pathname)) {
        history.push('/moderate')
      } else if (!user.isSuperUser) {
        history.push('/home')
      }
    } else if (!user && hasToken) {
      api.getMe().then(me => {
        store.dispatch(setUser(me))
      }).catch(ex => {
        // if they previously had a token, assume that an error means they are logged out
        history.push('/login')
      }).finally(() => setLoading(false))
    } else {
      setLoading(false)
    }
  }, [hasToken, user])

  if (loading) {
    return <div className="container">
      <Spinner variant='block' />
    </div>
  }

  return (
    <Router history={history}>
      <div>
        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/login" component={Login} />
          <Route path="/signup" component={SignUp} />
          <Route path="/forgot-password" component={PublicPage} />
          <Route path="/reset-password/:token" component={PublicPage} />
          {/* <Redirect from='/b/:slug/:abbreviation' to='/b/:slug'/> */}
          <Route path="/b/:slug" component={CompanyLandingPage} />
          {user ? (user.isSuperUser ? (
            <Route {...props} render={p => (
              <Page {...p} />
            )} />
          ) : (
            <UserSite />
          )) : <Redirect to={{
            pathname: "/login"
            // state: { from: location }
          }}
          />}
        </Switch>
      </div>
    </Router>
  )
}

const ConnectedApp = () => {
  return (
    <Provider store={store}>
      <App />
    </Provider>
  )
}

export default ConnectedApp
