import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import notify from './notifications-helper'
import Spinner from './spinner'

export const UpdatePerformanceByValue = () => {
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  return (
    <button
      type="button"
      className="btn btn-danger"
      disabled={saving}
      onClick={ev => {
        setSaving(true)
        api.updatePerformanceByValue().then(({ message }) => {
          dispatch(notify.success({ message }))
        }).finally(() => {
          setSaving(false)
        })
      }}>Update Performance by Value for ALL Companies {saving && <Spinner />}</button>
  )

}

export default UpdatePerformanceByValue
