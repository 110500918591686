import React from 'react'
import { CompanyDataUpload, Indicator } from '@ecountabl/lib'

type Props = {
  upload: CompanyDataUpload
  onChange: (coLookupHeader: string) => void
  onPreview: () => void
}

const HeaderNameLookup = ({
  upload,
  onChange,
  onPreview
}: Props) => {
  return (
    <div className="form-group">
      <div>
        <label>Which field should be used to lookup on company name?</label>
        <select
          className="form-control"
          onChange={ev => onChange(ev.target.value)}
          value={upload.coLookupHeader || ''}
        >
          <option></option>
          {Object.keys(upload.headerMap).map(h => <option key={h} value={h}>{h}</option>)}
        </select>
      </div>
      <button
        type="button"
        className="btn btn-primary"
        disabled={upload.coLookupHeader == null}
        onClick={onPreview}>Preview Company Matches</button>
    </div>
  )
}

export default HeaderNameLookup