import React from 'react'
import { Indicator } from '@ecountabl/lib'

export type ActionProps = {
  ind: Indicator
  onArchive: (abb: string) => void
  onEdit: (ind: Indicator) => void
}

export const IndicatorActions = ({ ind, onArchive, onEdit }: ActionProps): React.ReactElement => {
  return <div className='actions'>
    <i className='fa fa-trash' title='archive'
      onClick={() => onArchive(ind.abbreviation)}
    />
    <i className='fa fa-edit' title='edit'
      onClick={() => onEdit(ind)}
    />
  </div>
}

export default IndicatorActions