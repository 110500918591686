
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaBars } from "react-icons/fa";
import logo from './img/logo_full_blue.png';

const WEBSITE_ROOT = "https://ecountabl.com"
const LOGO_URL = "https://static1.squarespace.com/static/5aa53d87cef372a9f7be40bd/t/5d3f966f3432a00001a79216/1564931259598/?format=1500w"

class EcountablNav extends Component {
  state = {
    toggled: false
  }
  
  render() {
    const { toggled } = this.state 

    return <nav className="navbar navbar-expand-lg ecountabl-nav">
      <a className="navbar-brand" target="_blank" href={WEBSITE_ROOT}><img src={logo} alt="logo" /></a>
      <button className="navbar-toggler" type="button" onClick={() => { this.setState({ toggled: !toggled })}}>
        <FaBars />
      </button>

      <div className="navbar-collapse" style={{ display: toggled ? 'block' : '' }}>
        <ul className="navbar-nav">
          <li className="nav-item">
            <a className="nav-link" target="_blank" href={`${WEBSITE_ROOT}/features`}>How it Works</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" target="_blank" href={`${WEBSITE_ROOT}/data`}>Data & Innovation</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" target="_blank" href={`${WEBSITE_ROOT}/about`}>About</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" target="_blank" href={`${WEBSITE_ROOT}/home/#contact`}>Get Early Access</a>
          </li>
        </ul>
      </div>
    </nav>
  }
}

export default EcountablNav
