import React, { useState } from 'react'
import Spinner from './spinner'
import api from './api-client'
import { MdStore, MdBusiness, MdAdd } from "react-icons/md"
import { FaBan, FaCity } from "react-icons/fa"
import { useHistory } from 'react-router-dom'

const Transaction = props => {
  const { onClick, onResolved, tx, className } = props

  // hide these details from the admin user
  const json = { ...tx }
  delete json.userId
  delete json.amount
  delete json.account_id
  delete json.account_owner

  const history = useHistory()
  const [isLoading, setLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)

  const markNoMatches = async () => {
    setLoading(true)
    // TODO: this should now effectively be used to mark something as ignored in moderation,
    // but we don't really want to support "no_matches" anymore
    await api.setUnknownType(tx._id, "no_matches")
    setLoading(false)
    onResolved()
  }

  const addCompany = () => {
    const name = tx.merchant_name || tx.name
    history.push(`/companies/new?name=${name}`)
  }

  const _getButtonsHtml = () => {
    if (isLoading) {
      return <Spinner variant='block' />
    } else {
      return <div style={{ whiteSpace: 'nowrap' }}>
        <button className="btn btn-secondary"
          type="button"
          title="no matches"
          onClick={markNoMatches}><FaBan /></button>
          &nbsp;
        <button className="btn btn-secondary"
          type="button"
          title="add business"
          onClick={addCompany}><MdBusiness /><MdAdd /></button>
          &nbsp;
        <a onClick={ev => {
          ev.stopPropagation()
          setExpanded(!expanded)
        }}>json</a>
      </div>
    }
  }

  const icons = {
    company: <FaCity size="1.3em" />,
    localbiz: <MdStore size="1.3em" />,
    no_matches: <FaBan size="1.3em" />
  }

  const { approval, target, companyName } = tx.mapping || {}

  return <li className={className} onClick={() => onClick()}>
    <div style={{ position: 'relative' }}>
      <div><b>Merchant:</b> {tx.merchant_name}</div>
      <div><b>Label:</b> {tx.name}</div>
      <div className="categories">{tx.category && tx.category.join(', ')}</div>
      {tx.mapping && (
        <div>
          <span><b>Match:</b>&nbsp;({approval})&nbsp;</span>
          <div className={`mapping-info ${approval}`} title={target}>
            {icons[tx.mapping.target]}&nbsp;&nbsp;{companyName}
          </div>
        </div>
      )}
      <div>Moderation: {JSON.stringify(tx.moderation)}</div>
      <div className="date" title="last updated">{tx.date}</div>
      <div className="buttons">{_getButtonsHtml()}</div>
    </div>
    {expanded && <div className="raw-json">
      <pre>{JSON.stringify(json, false, '  ')}</pre>
    </div>}
  </li>
}

export default Transaction
