import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import notify from './notifications-helper'
import Spinner from './spinner'

export const TrickleDownUpdater = () => {
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  return (
    <button
      type="button"
      className="btn btn-danger"
      disabled={saving}
      onClick={ev => {
        const proceed = window.confirm([
          'Trickle down ratings may take some time, and does not update scores. ',
          'Are you sure you want to proceed?'
        ].join(''))

        if (proceed) {
          setSaving(true)
          api.trickleDownRatings().then(res => {
            console.log('trickle down result', res)
            dispatch(notify.success({
              message: `successfully applied inherited ratings with ${res.countUpdated} companies updated`
            }))
          }).finally(() => {
            setSaving(false)
          })
        }
      }}>Trickle Down Ratings {saving && <Spinner />}</button>
  )
}

export default TrickleDownUpdater
