import React, { useEffect, useState } from 'react'
import api from './api-client'
import { Link } from 'react-router-dom'

type Value = {
  _id: string;
  label: string;
}

const Values: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState(undefined)

  useEffect(() => {
    api.getValues().then(
      (values) => {
        setValues(values)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        console.error('There was an error loading the values:', error)
      }
    )
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <div
            className="spinner-border"
            style={{ width: '3rem', height: '3rem' }}
          ></div>
        </div>
      ) : (
        <div className="container mt-5">
          <h1>Values</h1>
          Click on any of the values below to view or edit them.
          <table className="table mt-5">
            <tbody>
              {values.map((value: Value) => (
                <tr key={value._id}>
                  <td style={{ cursor: 'pointer' }}>
                    <Link to={`/value/${value._id}`}>{value.label}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default Values
