import React from 'react'
import Logo from './logo'

export default class Brands extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showToggle: this.props.brands.length > 5,
      expanded: false
    }
  }

  _getColumnCount(num) {
    let columns = 5
    
    if ( num <= columns || num % columns === 0 ) { return columns }

    while ( num % columns === 1 && columns > 3 ) {
      columns--
    }

    let squareCheck = columns - 1
    while ( squareCheck >= 4 ) {
      if ( num % squareCheck === 0 ) {
        return squareCheck
      } else if ( num % squareCheck > num % columns ) {
        columns = squareCheck
      }

      squareCheck--
    }

    return columns
  }

  render() {
    const { brands } = this.props
    const { showToggle, expanded } = this.state

    const colCount = this._getColumnCount(brands.length)    
    const rowCount = showToggle && !expanded ? 1 : Math.ceil(brands.length / colCount)
    const justifyContent = colCount <= 5 ? 'flex-start' : 'center'

    let rows = []

    for (let r = 0; r < rowCount; r++) {
      let col = []
      for (let c = 0; c < colCount; c++) {
        let idx = (r * colCount) + c
        let brand
        if ( brand = brands[idx] ) {
          col.push(
            <Logo key={idx} 
              company={brand}
              style={{ width: '60px', margin: 5 }} 
            />
          )
        }
      }

      rows.push(<div style={{ justifyContent }} key={r}>{col}</div>)
    }

    return <div style={{ ...styles.wrap }}>
      {rows}
      {showToggle && <div 
        style={styles.toggleButton}
        onClick={() => this.setState({ expanded: !expanded })} >
        { expanded ? 'Less' : 'More (' + brands.length + ')'}
      </div>}
    </div>
  }
}

const styles = {
  wrap: {
    justifyContent: 'center',
    alignItems: 'stretch',
    alignSelf: 'stretch',
    flex: 1,
    marginBottom: "1em"
  }, 
  toggleButton: {
    backgroundColor: '#ccc',
    flexDirection: 'row',
    padding: 10,
    fontSize: 18,
    justifyContent: 'center'
  },
  row: {
    flex: 1, 
    flexDirection: 'row', 
    justifyContent: 'center', 
  }
}
