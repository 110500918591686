import axios from 'axios'
import cookies from 'js-cookie';
import history from '../../history';

export default {
  setUser: function(user) {
    return {type: 'SET_USER', user}
  },
  clearUser: function() {
    return {type: 'CLEAR_USER'}
  },
  setLoginError(message) {
    return {type: 'SET_LOGIN_ERROR', message}
  },
  unsetLoginError() {
    return {type: 'UNSET_LOGIN_ERROR'}
  },
  logout() {
    return (dispatch, getState) => {
      window.localStorage.removeItem('_token')
      dispatch(this.clearUser())
      history.push('/login')
    }
  },
  login: function(username, password, history) {
    return (dispatch, getState) => {
      axios.post('/api/authenticate', {
        username,
        password
      }).then(res => {
        // console.log(`res`, res)
        dispatch(this.setUser(res.data))
        dispatch(this.unsetLoginError())
        history.push('/login')
      }).catch(err => {
        // console.log(`err`, err.response)
        try {
          if ( err.response.data && err.response.data.msg ) {
            dispatch(this.setLoginError(err.response.data.msg))
          } else {
            dispatch(this.setLoginError(err.response.statusText))
          }
        } catch (ex) {
          dispatch(this.setLoginError("There was a problem logging in. Try again or contact your account administrator."));
        }
      })
    }
  }
}
