import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import notify from '../notifications-helper'
import api from '../api-client'
import Spinner from '../spinner'

export const ApiKeys = () => {
  const [label, setLabel] = useState('')
  const [showForm, setShowForm] = useState(false)
  const [visibleKey, setVisibleKey] = useState('')
  const [saving, setSaving] = useState(false)
  const [deletingId, setDeletingId] = useState('')

  const dispatch = useDispatch()

  const refetch = useRef(0)

  const { data: keys, error, isLoading } = useQuery(
    ['api-keys', refetch.current],
    () => api.getApiKeys()
  )

  return (
    <div>
      <h4>API Keys</h4>
      <div className="api-keys">
        {isLoading ? <Spinner variant='block' /> : (
          <div>
            {keys.length === 0 && <div className="alert alert-secondary">You do not have any API keys</div>}
            {keys.map(k => (
              <div key={k._id} className="card">
                <div className="card-body">
                  <div className="api-key-meta card-title">
                    <span>Label: <b>{k.label}</b></span>
                    <span className="actions">
                      {deletingId === k._id ? <Spinner /> : <a href="#"
                        onClick={ev => {
                          ev.preventDefault()
                          const msg = [
                            `Deleting your API key means any call to the API that use the key will be blocked.`,
                            `This action cannot be reversed, are you sure you want to proceed?`
                          ].join(' ')

                          if (window.confirm(msg)) {
                            setDeletingId(k._id)
                            api.deleteApiKey(k._id).then(res => {
                              dispatch(notify.success({ message: `successfully deleted API key` }))
                              refetch.current = Date.now()
                            }).catch(ex => {
                              dispatch(notify.error({ message: `error deleting API key: ${ex.message}` }))
                            }).finally(() => setDeletingId(''))
                          }
                        }}>Delete</a>}
                    </span>
                  </div>
                  <div className="api-stats">
                    <div><b>Created:</b> {k.created}</div>
                    <div><b>Last Used:</b> {k.lastUsed}</div>
                    <div><b>Usage:</b> </div>
                    <ul className="usage-list">
                      {Object.keys(k.usage).length === 0 && <li style={{ fontStyle: 'italic' }}>(none)</li>}
                      {Object.keys(k.usage).map((endpoint, i) => <li key={i}>
                        <span>{endpoint}</span><span> {k.usage[endpoint]}</span>
                      </li>)}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
        {error && <div className="alert alert-error">Failed to load API keys</div>}
        {showForm ? (
          <div style={{ marginTop: '1em' }}>
            <div className="card create-api-key">
              <div className="card-body">
                <label>
                  Treat your API Key like a password and store it securely. You will only be able to view your API key
                  when it is created. Ecountabl stores a hash of the key and cannot help you recover the key if lost.
                </label>
                {visibleKey ? (
                  <div>
                    <div style={{ marginBottom: '1em' }}>Your API key for &quot;{label}&quot; is <b>{visibleKey}</b></div>
                    <button type="button" className="btn btn-primary" onClick={() => {
                      setVisibleKey('')
                      setShowForm(false)
                      setLabel('')

                      // trigger a refetch
                      refetch.current = Date.now()
                    }}>I have saved it securely</button>
                  </div>
                ) : (
                  <>
                    <input
                      className="form-control"
                      placeholder="Label"
                      onChange={ev => setLabel(ev.target.value)}
                      value={label}
                    />
                    <div className="buttons">
                      <button
                        disabled={saving}
                        className="btn btn-primary"
                        onClick={() => {
                          setSaving(true)
                          api.createApiKey(label).then(res => {
                            setVisibleKey(res.key)
                          }).catch(ex => {
                            dispatch(notify.error({ message: `error creating API key: ${ex.message}` }))
                          }).finally(() => setSaving(false))
                        }}>{saving ? <Spinner /> : <>Create API Key</>}</button>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        ) : (
          <div className="buttons">
            <button onClick={() => setShowForm(true)} className="btn btn-primary">New API Key</button>
          </div>
        )}
      </div>
    </div>
  )
}

export default ApiKeys