import React, { useEffect, useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import notify from './notifications-helper'
import api from './api-client'
import { useQuery } from 'react-query'
import { FaExternalLinkAlt, FaRecordVinyl } from "react-icons/fa"
import { ITag, ISegment, Indicator } from '@ecountabl/lib'
import { TableForm, TextCell, ReadOnlyCell, CheckboxCell } from './table-form'
import ImageUploader from './image-uploader'
import IndicatorFormSelect from './indicator-form-select'
import Overlay from './overlay'
import { v4 } from 'uuid'

const columns = {
  '_id': {
    component: ReadOnlyCell,
    width: '300px',
    label: 'Tag',
    value: tag => tag.type === 'FROM_INDICATOR' ? `[From Indicator: ${tag.featuredHeader}]`: tag._id
  },
  'featured': {
    component: CheckboxCell,
    width: '100px',
    label: 'Featured'
  },
  'enabled': {
    component: CheckboxCell,
    width: '100px',
    label: 'Enabled'
  }
}

type WorkingTag = ITag & {
  modified?: boolean
}

const Segments = () => {
  const [tags, setTags] = useState<ITag[]>([])
  const [active, setActive] = useState<WorkingTag | undefined>()
  const [companies, setCompanies] = useState([])
  const [indicators, setIndicators] = useState<Indicator[]>([])

  const dispatch = useDispatch()
  const refetch = useRef(0)

  const { data, error, isLoading } = useQuery(
    ['admin-tags', refetch.current],
    () => api.getTags()
  )

  useEffect(() => {
    if (Array.isArray(data)) {
      data.sort((a, b) => a._id.localeCompare(b._id))
    }
    setTags(data || [])
  }, [data])

  useEffect(() => {
    api.getAdminIndicators()
      .then(res => setIndicators(res.indicators))
      .catch(ex => dispatch(notify.error({ message: `error fetching indicators: ${ex.message}` })))
  }, [])

  useEffect(() => {
    columns.actions = {
      label: 'Actions',
      component: ({ record }: { record: ITag }) => {
        return <div className="actions">
          {record.imgUrl ? <i className="fa fa-photo"
            style={{ color: 'green' }}
          /> : null}
          <i className="fa fa-edit"
            onClick={() => {
              setActive(record)

              api.companySearch({ tags: [record._id] }).then(cos => {
                cos.sort((a, b) => a.name.localeCompare(b.name))
                setCompanies(cos)
              }).catch(ex => dispatch(notify.error({ message: `error fetching companies: ${ex.message}` })))
            }}
          />
          <i className="fa fa-trash"
            onClick={() => {
              if (window.confirm(`Deleting the tag will remove it from exising companies, are you sure you want to proceed?`)) {
                api.deleteTag(record._id).then(({ message }) => {
                  refetch.current = Date.now()
                  dispatch(notify.success({ message }))
                }).catch(ex => dispatch(notify.error({ message: `error deleting tag: ${ex.message}` })))
              }
            }}
          />
        </div>
      }
    }
  }, [])

  if (isLoading) {
    return <>
      <div className="d-flex vh-100 justify-content-center align-items-center ">
        <div
          className="spinner-border"
          style={{ width: '3rem', height: '3rem' }}
        ></div>
      </div>
    </>
  }

  if (error) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center ">
        <div className="alert alert-error">
          {error}
        </div>
      </div>
    )
  }

  const ind = indicators.find(i => i.abbreviation === active?.indicator?.abbreviation)

  return (
    <div className="container vh-100">
      <h3>Segments &amp; Tags</h3>
      <div className="alert alert-info">
        <div><b>Featured</b> tags are shown on the Explore page in the app</div>
        <div><b>Enabled</b> tags will show on the company profile page AND users may receive notifications</div>
      </div>
      <div style={{ display: 'flex' }}>
        <div style={{ flex: 3 }}>
          <TableForm
            columns={columns}
            records={tags}
            defaultSort={'segment'}
            addButtonText="Add From Indicator"
            onAdd={() => {
              setActive({
                _id: 't_' + v4().split('-').pop(),
                type: 'FROM_INDICATOR',
                indicator: {
                  abbreviation: undefined,
                  targetValue: {}
                }
              })
            }}
            onSubmit={(modifiedRecords) => {
              api.updateTags(modifiedRecords).then(res => {
                dispatch(notify.success({ message: `saved modified tags` }))
                refetch.current = Date.now()
              }).catch(ex => dispatch(notify.error({ message: `error loading upload: ${ex.message}` })))
            }}
            activeId={active?._id}
          />
        </div>
        {active ? (
          <Overlay onClose={() => setActive(undefined)}>
            <div className="detail-editor" style={{
              width: '600px',
              maxHeight: '700px',
              overflow: 'scroll'
            }}>
              <h4>Tag Editor</h4>
              <div style={{ marginBottom: '1em' }}>
                <div><b>Tag:</b> {active._id}</div>
                <div><b>Tag Type:</b> {active.type === 'FROM_INDICATOR' ? 'From Indicator' : 'Tag'}</div>
              </div>
              {active.type === 'FROM_INDICATOR' ? <>
                <p>
                  Use indicator data to drive what companies appear in the featured alternatives.
                  When configuring this option,
                  you must choose an indicator to use and the value of the indicator that will cause
                  it to be included in the featured list. IE, if you choose an indicator with Y/N scale
                  and with Value Equals "Y", it will not show in the featured list if the indicator data
                  for that company is "N" or empty.
                </p>
                <div className="form-group">
                  <label>Indicator</label>
                  <IndicatorFormSelect
                    indicators={indicators}
                    onChange={ev => {
                      const _i = indicators.find(i => i.abbreviation === ev.target.value)
                      setActive({
                        ...active,
                        modified: true,
                        indicator: {
                          abbreviation: _i.abbreviation,
                          targetValue: _i.scale === 'Y/N' ? { equals: 'Y' } : { greaterThan: 100 }
                        }
                      })
                    }}
                    value={ind?.abbreviation}
                  />
                </div>
                {active.indicator ? (ind?.scale === 'Y/N' ? <div className="form-group">
                  <label>Value Equals</label>
                  <input
                    type="text"
                    className="form-control"
                    value={active.indicator?.targetValue?.equals || ''}
                    onChange={ev => setActive({
                      ...active,
                      modified: true,
                      indicator: {
                        abbreviation: active.indicator.abbreviation,
                        targetValue: {
                          equals: ev.target.value
                        }
                      }
                    })}
                  />
                </div> : <div className="form-group">
                  <label>Value is at least</label>
                  <input
                    type="number"
                    className="form-control"
                    value={active.indicator.targetValue?.greaterThan || ''}
                    onChange={ev => setActive({
                      ...active,
                      modified: true,
                      indicator: {
                        abbreviation: active.indicator.abbreviation,
                        targetValue: {
                          greaterThan: parseFloat(ev.target.value)
                        }
                      }
                    })}
                  />
                </div>) : null}
              </> : null}

              <div>
                <label>Image for tag&nbsp;<b>{active._id}</b></label>
                <ImageUploader
                  onChange={(imgUrl) => setActive({
                    ...active,
                    imgUrl,
                    modified: true
                  })}
                  imgSrc={active.imgUrl || ''}
                  targetSize={{ width: 500, height: 200 }}
                />
              </div>
              <div className="form-group">
                <label>Featured Header</label>
                <input
                  type="text"
                  className="form-control"
                  value={active.featuredHeader || ''}
                  placeholder={`Default: "${active.label} brands for you"`}
                  onChange={ev => {
                    setActive({
                      ...active,
                      modified: true,
                      featuredHeader: ev.target.value
                    })
                  }}
                />
                <p className="help">Displayed in app when featured</p>
              </div>
              <div className="form-group">
                <label>Featured Subtext</label>
                <input
                  type="text"
                  className="form-control"
                  value={active.featuredSubtext || ''}
                  placeholder="Default: <none>"
                  onChange={ev => {
                    setActive({
                      ...active,
                      modified: true,
                      featuredSubtext: ev.target.value
                    })
                  }}
                />
                <p className="help">Displayed in app when featured</p>
              </div>
              <div>
                <h6>Companies with tag <b>{active._id}</b></h6>
                <div style={{ maxHeight: '300px', overflow: 'scroll' }}>
                  {companies.map(co => (
                    <div key={co._id}>
                      <a target="_blank" href={`/companies/${co._id}`}>
                        {co.name}
                        &nbsp;
                        <FaExternalLinkAlt />
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <div className="buttons">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => {
                    const idx = tags.findIndex(t => t._id === active._id)

                    if (idx > -1) {
                      setTags([
                        ...tags.slice(0, idx),
                        active,
                        ...tags.slice(idx + 1)
                      ])
                    } else {
                      setTags([ ...tags, active ])
                    }

                    setActive(undefined)
                  }}
                >Done</button>
                <button
                  type="button"
                  className="btn btn-cancel"
                  onClick={() => {
                    setActive(undefined)
                  }}
                >Cancel</button>
              </div>
            </div>
          </Overlay>
        ) : null}
      </div>
    </div>
  )
}

export default Segments
