import React, { useState, cloneElement } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar'
import api from './api-client'
import Spinner from './spinner'

const ResetPasswordRequest = (props) => {
  const { token } = props.match.params

  const [email, setEmail] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')
  const [error, setError] = useState()

  const handleSubmit = ev => {
    ev.preventDefault()
    setLoading(true)

    api.requestResetPassword(email).then(res => {
      setLoading(false)
      setResult('Request received, check your email for a secure link to reset your password.')
    }).catch(ex => {
      setLoading(false)
      setError(ex.message)
    })

    return false
  }

  return <div className="reset-password">
    <h5>Request Password Reset</h5>
    <form onSubmit={handleSubmit}>
      <input 
        type="text" 
        placeholder="Enter email"
        autoFocus
        className="form-control"
        onChange={(ev) => setEmail(ev.target.value)}
      />
      {loading ? <Spinner /> : (
        <button 
          className="btn btn-md btn-primary" 
          type="submit"
        >Submit</button>
      )}
      {result && (
        <div className='alert alert-info'>{result}</div>
      )}
      {error && (
        <div className="alert alert-danger">
          Error: {error}
        </div>
      )}
    </form>
  </div>
}

export default ResetPasswordRequest