import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import notify from './notifications-helper'
import Spinner from './spinner'

export const ReindexElasticsearch = () => {
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  return (
    <button
      type="button"
      className="btn btn-danger"
      disabled={saving}
      onClick={ev => {
        const proceed = window.confirm([
          'This will drop the companies index then reindex all companies into elasticsearch, are you sure you want to proceed?'
        ].join(''))

        if (proceed) {
          setSaving(true)
          api.reindexElasticsearch().then(res => {
            dispatch(notify.success({
              message: res.message
            }))
          }).finally(() => {
            setSaving(false)
          })
        }
      }}>Reindex Elasticsearch {saving ? <Spinner /> : null}</button>
  )
}

export default ReindexElasticsearch
