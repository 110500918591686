import React, { useState } from 'react'
import { CompanyUploadMatch } from '@ecountabl/lib'
import CompanySearch from '../company-search'
import { FaCity, FaStore } from "react-icons/fa"


type SortedMatch = CompanyUploadMatch & {
  inboundName?: string;
}

type CoMatchRowProps = {
  match: SortedMatch;
  onMatchChange: (o: SortedMatch) => void;
  viewHeaders?: string[]
}

const CoMatchRow: React.FC<CoMatchRowProps> = ({
  match,
  onMatchChange,
  viewHeaders
}: CoMatchRowProps) => {
  const [editingCo, setEditingCo] = useState(false)

  return (
    <tr className={[match.reviewed ? 'reviewed' : ''].join(' ')}>
      <td>{match.inboundName}</td>
      <td>{match.type}</td>
      <td>
        {editingCo ? (
          <div className="match-name">
            <CompanySearch
              initialSearch={match.inboundName.split(' ')[0]}
              autoFocus
              style={{ flex: 7 }}
              buttonText="Select"
              onSelect={(id, co) => {
                setEditingCo(false)
                onMatchChange({ ...match, reviewed: true, name: co.name, parent: co.corporate_parent })
              }}
            />
            {/* TODO: eventually this should be "send to moderation" */}
            <span className="match-buttons" style={{ flex: 1 }}>
              <a title="no matches" onClick={() => {
                setEditingCo(false)
                onMatchChange({ ...match, reviewed: true, noMatches: true, name: '' })
              }}><i className="fa fa-close" /></a>
            </span>
          </div>
        ) : (
          <div className="match-name">
            <span>
              <span>{match.name ? (match.parent ? <FaStore /> : <FaCity />) : null}</span>
              &nbsp;
              <span>{match.name}</span>
            </span>
            <span className="match-buttons">
              <a onClick={() => { setEditingCo(true) }}>
                <i className="fa fa-search" />
              </a>
              <a onClick={() => {
                setEditingCo(false)
                onMatchChange({ ...match, reviewed: true })
              }}>
                <i className="fa fa-check" />
              </a>
            </span>
          </div>
        )}
      </td>
      {viewHeaders?.length ? viewHeaders.map((vh, i) => <td key={i}>{match.data && match.data[vh]}</td>) : null}
    </tr>
  )
}

export default CoMatchRow
