import notify from '../notifications-helper'

import user from './actions/user'
import ui from './actions/ui'

function ajaxErrorHandler(err) {
  return (dispatch, getState) => {
    const message = "Fail whale - an error occurred. Hit up an admin for help."

    try {
      if ( err && err.status === 403 ) {
        dispatch(this.setLoginError('Your session has expired, please log back in'))
        dispatch(this.logout())
        return
      } 
    } catch (ex) {
      console.log( 'Failure during error handling', ex )
    }

    dispatch(notify.error({ message }))
  }
}

export default Object.assign({
    ajaxErrorHandler
  },
  ui,
  user,
)
