import React from 'react'
import { logoUrl } from '@ecountabl/lib'
import { REACT_APP_CDN_ROOT } from './config'

const Logo = (props) => {
  const { company } = props

  const src = logoUrl(company, REACT_APP_CDN_ROOT)

  return <img
    alt={company.logoref}
    style={props.style}
    src={src}
  />
}

export default Logo