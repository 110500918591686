import React, { useEffect, useState } from 'react'
import api from './api-client'
import Spinner from './spinner'
import { useDispatch } from 'react-redux'
import ImageUploader from './image-uploader'
import notify from './notifications-helper'
import FormField from './form-field'
import { Customer, IImpactData } from '@ecountabl/lib'

type ImpactDataFormProps = {
  customer: Customer
}

const yearOptions = (): number[] => {
  const year = new Date().getFullYear()
  const years = [year]

  for (let i = year - 1; i >= 2010; i--) {
    years.push(i)
  }

  return years
}

const digit2 = (x: number | string): string => {
  return x < 10 ? '0' + x.toString() : x.toString()
}

type MonthSelectProps = {
  value: string
  onChange: (s: string) => void
}

const MonthSelect = ({ value, onChange }: MonthSelectProps) => {
  const yearOpts = yearOptions()
  const months = ['jan', 'feb', 'mar', 'apr', 'may', 'jun', 'jul', 'aug', 'sep', 'oct', 'nov', 'dec']
  const [year, month] = value.split('-')

  return (
    <div className="form-group">
      <label>Month Ending</label>
      <div>
        <select
          className="form-control"
          onChange={ev => { onChange(`${ev.target.value}-${month}`) }}
        >
          {yearOpts.map(yo => <option key={yo} value={yo}>{yo}</option>)}
        </select>
        <div className="month-select">
          {months.map((mo, i) => (
            <div
              key={mo}
              className={["mo", `${digit2(i + 1)}` === month ? "selected" : ""].join(' ')}
              onClick={() => onChange(`${year}-${digit2(i + 1)}`)}>
              {mo}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const ImpactDataEntry = ({ bankIndicators, current, onChange }) => {
  const handleChange = (ind, key: string, value: string) => {
    const impactData = { ...current }
    impactData.data[ind._id] = {
      ...(impactData.data[ind._id] || {}),
      [key]: parseFloat(value)
    }
    onChange(impactData)
  }

  return <>
    <div className="idf-col-hdr">
      <div>$ Value of Loans</div>
      <div># of Loans</div>
    </div>
    {bankIndicators.map((ind, i) => (
      // Decision: bank-originating indicators will capture multiple data points on the bank side;
      // This pattern may introduce inconsistencies with handling of indicators; it may not make sense
      // to force these into the existing Indicator pattern.
      // Might also need to setup warnings about deleting bank indicators cuz it could invalidate data captured 
      // in the "Impact Data" capture form... need to enable capture based on periods
      <div key={i} className="form-group bank-indicator-row">
        <label>{ind.label}</label>
        <div style={{}} className="form-inline">
          <input
            className="form-control"
            type="number"
            placeholder="% of loans"
            value={current.data[ind._id]?.quantity || ''}
            name={`${ind._id}.quantity`}
            onChange={ev => handleChange(ind, 'quantity', ev.target.value)}
          />
          <input
            className="form-control"
            type="number"
            placeholder="total $ value"
            value={current.data[ind._id]?.amount || ''}
            onChange={ev => handleChange(ind, 'amount', ev.target.value)}
          />
        </div>
      </div>
    ))}
  </>
}

export const ImpactDataForm = ({ customer }: ImpactDataFormProps) => {
  const now = new Date()
  const dispatch = useDispatch()
  const template = {
    customerId: customer._id,
    monthEnding: `${now.getFullYear()}-${digit2(now.getMonth())}`,
    data: {}
  }

  const [bankIndicators, setBankIndicators] = useState([])
  const [records, setRecords] = useState([])
  const [submitting, setSubmitting] = useState(false)
  const [adding, setAdding] = useState(false)
  const [current, setCurrent] = useState<Partial<IImpactData>>({ ...template })

  useEffect(() => {
    Promise.all([
      api.getAdminIndicators(),
      api.getCustomerImpactData(customer._id)
    ]).then(([{ indicators }, impactDataRecords]) => {
      setBankIndicators(indicators.filter(ind => ind.attachment === 'bank'))
      setRecords(impactDataRecords)

      if (impactDataRecords.length) {
        setCurrent(impactDataRecords[0])
      }
    })
  }, [])

  const handleSubmit = async () => {
    setSubmitting(true)
    try {
      if (adding) {
        await api.postCustomerImpactData(current)
      } else {
        await api.putCustomerImpactData(current)
      }
      const impactDataRecords = await api.getCustomerImpactData(customer._id)
      setRecords(impactDataRecords)
      if (impactDataRecords.length) {
        setCurrent(impactDataRecords[0])
      } else {
        setCurrent({})
      }
      setAdding(false)
      dispatch(notify.success({ message: `successfully saved impact report` }))
    } catch (ex) {
      dispatch(notify.error({ message: `error saving impact data: ${ex.message}` }))
    }
    setSubmitting(false)
  }

  return (
    <div className="data-form">
      <div className="idf-hdr">
        <h4>Impact Reporting</h4>
        {!adding && <div className="buttons">
          <button
            type="button"
            className="btn btn-normal"
            onClick={() => {
              setAdding(true)
              setCurrent({ ...template })
            }}
          >Add New Impact Report <i className="fa fa-plus-circle" /></button>
        </div>}
      </div>
      {!adding && records.length === 0 ? (
        <div className="alert alert-secondary">No Impact Data records exist for this customer</div>
      ) : ''}
      {adding ? (
        <>
          <MonthSelect
            onChange={s => setCurrent({ ...current, monthEnding: s })}
            value={current.monthEnding}
          />
          <ImpactDataEntry
            bankIndicators={bankIndicators}
            current={current}
            onChange={d => setCurrent(d)}
          />
        </>
      ) : (
        <>
          <div className="form-group">
            <label>Viewing Report for Month Ending</label>
            <div>
              <select
                className="form-control"
                onChange={ev => { setCurrent(records.find(r => r._id === ev.target.value)) }}
              >
                {records.map(data => <option key={data._id} value={data._id}>{data.monthEnding}</option>)}
              </select>
            </div>
          </div>
          <ImpactDataEntry
            bankIndicators={bankIndicators}
            current={current}
            onChange={d => setCurrent(d)}
          />
        </>
      )}
      <div className="buttons" style={{ display: 'flex', flexDirection: 'row-reverse' }}>
        <button type="button" className="btn btn-primary" onClick={handleSubmit}>
          {submitting ? (
            <Spinner />
          ) : (
            <div>Save Impact Report</div>
          )}
        </button>
        {adding && <button type="button" className="btn btn-secondary" onClick={() => {
          setAdding(false)
          if (records.length) {
            setCurrent(records[0])
          }
        }}>Cancel</button>}
      </div>
    </div>
  )
}

export default ImpactDataForm