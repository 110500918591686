import { ObjectID } from 'mongodb'

export type PlaidTxLocation = {
  address?: any;
  city?: any;
  country?: any;
  lat?: any;
  lon?: any;
  postal_code?: any;
  region?: any;
  store_number?: any;
}

export type PlaidTxMeta = {
  by_order_of?: any;
  payee?: any;
  payer?: any;
  payment_method?: any;
  payment_processor?: any;
  ppd_id?: any;
  reason?: any;
  reference_number?: any;
}

export interface PlaidTransaction {
  _id?: string;
  account_id?: string;
  account_owner?: any;
  amount: number;
  authorized_date?: any;
  category: string[];
  category_id?: string;
  date: string;
  iso_currency_code: string;
  location: PlaidTxLocation;
  merchant_name?: string;
  name: string;
  payment_channel?: string;
  payment_meta: PlaidTxMeta;
  pending: boolean;
  pending_transaction_id?: any;
  transaction_code?: any;
  transaction_id: string;
  transaction_type: string;
  unofficial_currency_code?: any;
}

enum MatchType {
  Charity = "CHARITY",
  LocalBiz = "LOCALBIZ",
  Search = "SEARCH",
  Known = "KNOWN",
  NoMatches = "NO_MATCHES",
  Moderation = "MODERATION"
}

export interface MatchedPlaidTransaction extends PlaidTransaction {
  match?: string; // company name
  match_type?: MatchType;
  score?: number;
  match_id?: string | ObjectID;
  error?: Error;
  dataCompleteness?: number;
  moderation?: {
    type: MatchType; // some entries in the db are lowercase
    companyId?: string;
  };
  matching?: {
    dedupe_key: string;
    dedupe_type: 'merchant' | 'label'
  }
  isValuesAligned?: boolean
}