import React from 'react'
import { Link } from 'react-router-dom'
import NavUserDropdown from '../nav-user-dropdown'

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="navbar-collapse">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item"><Link to="/home">Home</Link></li>
        </ul>
        <NavUserDropdown />
      </div>
    </nav>
  )
}

export default Nav
