import React from 'react'
import { CompanyDataUpload, Indicator } from '@ecountabl/lib'

type Props = {
  upload: CompanyDataUpload
  indicators: Indicator[]
  onChange: (header: string, value: string) => void
}

export const HeaderMappings = ({ 
  upload,
  indicators,
  onChange
}: Props) => {
  const getOptions = () => {
    const inds = indicators.map(i => ({ value: `ratings.${i.abbreviation}`, text: `Indicator: ${i.label}` }))
    const fields = [
      { value: 'segment', text: 'Segment' },
      { value: 'sub_segment', text: 'Sub Segment' },
      { value: 'ticker', text: 'Ticker' },
      { value: 'city', text: 'City' },
      { value: 'state', text: 'State' },
      { value: 'twitter', text: 'Twitter' },
      { value: 'website', text: 'Website' },
    ]

    return fields.concat(inds).map(opt => (
      <option key={opt.value} value={opt.value}>{opt.text}</option>
    ))
  }

  return (
    <div>
      <h5>Header Mappings</h5>
      <div className="header-mappings">
        <p>Unmapped headers will be ignored</p>
        {Object.keys(upload.headerMap).map(header => (
          <div key={header} className="header-mapping">
            <span className="header-name">{header}</span>
            <span className="header-arrow"><i className="fa fa-arrow-right"></i></span>
            <select
              className="form-control"
              value={upload.headerMap[header] || ''}
              onChange={ev => onChange(header, ev.target.value)}
            >
              <option></option>
              {getOptions()}
            </select>
            <span>OR</span>
            <input
              className="form-control"
              placeholder="write in (caution!!)"
              value={upload.headerMap[header] || ''}
              onChange={ev => onChange(header, ev.target.value)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default HeaderMappings