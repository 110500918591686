import React, { useState, useEffect } from 'react'
import { FaCaretDown } from 'react-icons/fa'
import { Dictionary } from '@ecountabl/lib'
import Spinner from './spinner'
import { rootCertificates } from 'node:tls'

type TextCellProps = {
  value: string;
  onChange: React.ChangeEventHandler;
}

type DropdownOption = {
  value: string | number;
  label: string;
}

type DropdownProps = {
  value: string;
  values: DropdownOption[];
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
}

export const CheckboxCell = ({ value, onChange }) => {
  return <input className="form-control" type="checkbox" checked={value || false} onChange={onChange} />
}

export const DropdownCell: React.FC<DropdownProps> = ({ value, values, onChange }) => {
  return <select
    className="form-control"
    onChange={onChange}
    value={value}
  >
    <option></option>
    {values.map(v => <option key={v.value} value={v.value}>{v.label}</option>)}
  </select>
}

export const TextAreaCell: React.FC<TextCellProps> = ({ value, onChange }: TextCellProps) => {
  return <textarea className="form-control" value={value} onChange={onChange} />
}

export const TextCell: React.FC<TextCellProps> = ({ value, onChange }: TextCellProps) => {
  // value || '' is to prevent warning about controlled vs uncontrolled 🙄
  return <input type="text" className="form-control" value={value || ''} onChange={onChange} />
}

export const ReadOnlyCell = ({ value }) => {
  return <span>{value}</span>
}

type TableFormColumn = {
  component: React.Component;
  width?: string;
  label?: string;
  value?: string | ((r: Record<string, unknown>) => string)
}

type TableRecord = Record<string | number | symbol, any>

type TableFormProps = {
  onSubmit: (modifiedRecords: TableRecord[]) => void;
  onAdd?: () => void;
  addButtonText?: string;
  columns: Dictionary<TableFormColumn>;
  records: TableRecord;
  activeId: string;
  defaultSort?: string;
}

export const TableForm: React.FC<TableFormProps> = ({
  onSubmit,
  onAdd,
  addButtonText,
  columns,
  records,
  activeId,
  defaultSort
}: TableFormProps) => {
  const [saving, setSaving] = useState(false)
  const [hasChanges, setHasChanges] = useState(false)
  const [sort, setSort] = useState('')
  const [sortedRecords, setSortedRecords] = useState(records)

  useEffect(() => {
    const _records = doSort(sort)
    setSortedRecords(_records)
    setHasChanges(!!_records.find(r => r.modified))
    console.log('records changed', records.find(r => r.active))
  }, [records])

  const doSort = (prop: string) => {
    const key = prop || defaultSort
    const _records = records.slice()
    _records.sort((a, b) => {
      if (a[key] === b[key]) {
        return 0
      }

      if (a[key] === undefined) {
        return 1
      }

      if (b[key] === undefined) {
        return -1
      }

      if (typeof a[key] === 'string') {
        return a[key].localeCompare(b[key])
      } else if (typeof a[key] === 'number') {
        return a[key] - b[key]
      } else if (typeof a[key] === 'boolean') {
        return a[key] - b[key]
      }
    })

    return _records
  }

  const onSort = (key) => {
    setSort(key)
    const _records = doSort(key)
    setSortedRecords(_records)
  }

  const onChange = (key: string, id: string, ev: React.SyntheticEvent) => {
    const _records = sortedRecords.slice()
    const record = _records.find(r => r._id === id)

    if (ev.target.type === 'checkbox') {
      record[key] = ev.target.checked
    } else {
      record[key] = ev.target.value
    }

    record.modified = true
    setHasChanges(true)
    setSortedRecords(_records)

    // TODO: fire onRecordsChange() ? 
  }

  return (
    <div className="table-wrapper">
      <div className="buttons">
        <button
          type="button"
          className="btn btn-primary"
          disabled={!hasChanges || saving}
          onClick={() => {
            let modifiedRecords = sortedRecords.filter(r => r.modified)
            modifiedRecords = JSON.parse(JSON.stringify(modifiedRecords))
            modifiedRecords.forEach(r => delete r.modified)
            onSubmit(modifiedRecords)
          }}>{saving ? <Spinner /> : "Save Changes"}</button>
        {onAdd ? (
          <button
            type="button"
            className="btn btn-secondary"
            onClick={onAdd}
          >{addButtonText || "Add Record"}</button>
        ) : null}
      </div>

      <table className="table-form">
        <thead>
          <tr>
            {Object.entries(columns).map(([key, stx]) => (
              <th
                key={key}
                style={{ minWidth: stx.width }}
                onClick={onSort.bind(this, key)}
              >
                {stx.label || key}
                {key === sort ? <FaCaretDown /> : ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {sortedRecords.map(record => {
            const classes = [
              record.modified ? "modified" : null,
              record._id === activeId ? "active" : null
            ].filter(i => i).join(' ')
            
            return (
              <tr key={record._id} className={classes}>{
                Object.entries(columns).map(([key, stx]) => {
                  const p = {
                    value: typeof stx.value === 'function' ? stx.value(record) : record[key],
                    onChange: onChange.bind(undefined, key, record._id),
                    record
                  }

                  return <td key={key}>
                    <stx.component {...p} />
                  </td>
                })
              }
              </tr>
            )
          })}
        </tbody>
      </table>
    </div>
  )
}
