import React, { useEffect, useState } from 'react'
import api from '../api-client'
import { useDispatch } from 'react-redux'
import notify from '../notifications-helper'
import Spinner from '../spinner'

type Props = {
  topTags: string[]
  onChange: (tags: string[]) => void
}

export const FeaturedTagSortOrder = ({ topTags, onChange }: Props) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [options, setOptions] = useState([])
  const [query, setQuery] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  const filteredOptions = options.filter(o => (
    !topTags?.includes(o._id) &&
    o._id.toLowerCase().includes(query.toLowerCase())
  ))

  useEffect(() => {
    api.getTags().then(_tags => {
      _tags.sort((a, b) => a._id.localeCompare(b._id)).map(t => t._id)

      setOptions(_tags)
      setLoading(false)
    }).catch(ex => dispatch(notify.error({ message: ex.message })))
  }, [])

  if (loading) {
    return <Spinner />
  }

  const onMove = (i, tag) => {
    const _tags = topTags.filter(t => t !== tag)
    _tags.splice(i, 0, tag)
    onChange(_tags)
  }

  return <div className="values-sort-order">
    <h4>Featured Tags Sort Order</h4>
    <p style={{ maxWidth: '500px' }}>
      Featured tags will be displayed in the following order in the app. Note that
      these tags will be featured even if not explicitly set as featured in the
      tags admin.
    </p>
    <div className="list-group" style={{ marginBottom: '0.5em' }}>
      {topTags.map((tag, i) => <div key={i} className="list-group-item">
        <div>{tag}</div>
        <div>
          <i className="fa fa-angle-double-up" onClick={() => onMove(0, tag)} />
          <i className="fa fa-angle-up" onClick={() => onMove(i - 1, tag)} />
          <i className="fa fa-angle-down" onClick={() => onMove(i + 1, tag)} />
          <i className="fa fa-angle-double-down" onClick={() => onMove(topTags.length - 1, tag)} />
          <i className="fa fa-trash" onClick={() => onChange(topTags.filter(t => t !== tag))} />
        </div>
      </div>)}
    </div>
    {topTags.length !== 0 ? null : <div className="alert alert-secondary">No sort order defined</div>}
    {/* TODO: consolidate with tag-input */}
    <div className="tag-input">
      <div className="input-wrap">
        <input
          type="text"
          className="form-control"
          value={query}
          onFocus={ev => {
            setShowOptions(true)
          }}
          onBlur={ev => {
            if (!ev.relatedTarget?.className?.split(' ').includes('tag-option')) {
              setTimeout(() => setShowOptions(false), 100)
            } else {
              // retain focus so this will fire again when clicked outside
              ev.currentTarget.focus()
            }
          }}
          onChange={ev => {
            setQuery(ev.target.value)
          }}
          placeholder="add tag"
        />

        {showOptions && (
          <div className="tag-options list-group">
            {filteredOptions.map((o, i) => (
              <a href="#" key={i}
                className="list-group-item tag-option"
                onClick={ev => {
                  setQuery('')
                  onChange([...topTags, o._id])
                  ev.preventDefault()
                }}
              >{o.label}</a>
            ))}
          </div>
        )}
      </div>
    </div>
  </div>
}

export default FeaturedTagSortOrder