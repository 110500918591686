import React, { Component, StyleHTMLAttributes } from 'react';

type Props = {
  style?: object;
  variant?: 'inline' | 'block'
}

export const Spinner = ({ style, variant }: Props) => {
  // default style of inline
  const v = variant || 'inline'

  return <div className={`spinner ${v}`} style={style}>
    <div className="spinner-border"></div>
  </div>
}

export default Spinner