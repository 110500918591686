import React, { useEffect, useState } from 'react'
import api from '../api-client'
import Spinner from '../spinner'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import notify from '../notifications-helper'
import FormField from '../form-field'
import ImpactDataForm from '../impact-data-form'
import FinastraForm from './finastra-form'
import ValuesSortOrder from './values-sort-order'
import HighlightedDataPointsForm from './highlighted-data-points-form'
import { Customer, WillNotFund as WNFConfig } from '@ecountabl/lib'
import SelectedIcon from '../selected-icon'
import PartnershipsForm from './partnerships-form'
import FeaturedTagSortOrder from './featured-tag-sort-order'

interface Props {
  match: {
    params: {
      id: string
    }
  }
}

const CustomerForm: React.FC<Props> = ({ match }: Props) => {
  const { id } = match.params
  const [customer, setCustomer] = useState<Partial<Customer>>({
    willNotFund: {},
    config: {
      valuesSortOrder: {}
    }
  })
  const [modifiedIntegrations, setModifiedIntegrations] = useState({})
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [impactAreas, setImpactAreas] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const init = async () => {
      if (id === 'new') {
        setLoading(false)
        return
      }

      try {
        const [_customer, _impactAreas] = await Promise.all([
          api.getCustomer(id),
          api.getImpactAreas()
        ])

        setCustomer({
          willNotFund: {},
          ..._customer,
        })
        setImpactAreas(_impactAreas.list)
        setLoading(false)
      } catch (ex) {
        setLoading(false)
      }
    }

    init()
  }, [api.getValue, id])

  const handleSubmit = async (event) => {
    setSubmitting(true)
    event.preventDefault()
    try {
      const payload = { ...customer }
      payload.integrations?.forEach(i => delete i.isModified)
      setSubmitting(false)
      if (id === 'new') {
        const res = await api.postCustomer(payload as Customer)
        history.push(`/customers/${res._id}`)
      } else {
        await api.putCustomer(payload as Customer)
      }
      dispatch(notify.success({ message: "Customer saved successfully" }))
      setCustomer(payload)
    } catch (err) {
      setSubmitting(false)
      dispatch(notify.error({ message: `Error saving value: ${err.message}` }))
    }
  }

  const handleChange = (event) => {
    setCustomer({ ...customer, [event.target.name]: event.target.value })
  }

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <Spinner />
        </div>
      ) : (
        <div className="container mt-5">
          <h2>Edit Customer</h2>
          <div>
            <div>
              <FormField
                value={customer.name || ''}
                onChange={handleChange}
                label="Name"
                type="text"
                field="name"
              />
              <div className="form-group">
                <label>Theme</label>
                <select className="form-control" value={customer.config?.theme} onChange={ev => {
                  setCustomer({
                    ...customer,
                    config: {
                      ...customer.config,
                      theme: ev.target.value
                    }
                  })
                }}>
                  <option value="dark">Dark</option>
                  <option value="light">Light</option>
                  <option value="climateFirst">Climate First</option>
                </select>
              </div>
              <div style={{ marginBottom: '1.5em' }}>
                <h4>Integrations</h4>
                <div className="integrations">
                  {customer.integrations?.map((integration, i) => <FinastraForm
                    key={i}
                    integration={integration}
                    onChange={ev => {
                      const integrations = [...customer.integrations]
                      const current = integrations[i] || { platform: 'finastra' }
                      current[ev.target.name] = ev.target.value
                      current.isModified = true
                      setCustomer({
                        ...customer,
                        integrations
                      })
                    }}
                    onDelete={() => {
                      const integrations = [...customer.integrations]
                      integrations.splice(i, 1)
                      setCustomer({
                        ...customer,
                        integrations
                      })
                    }}
                  />)}
                  {customer.integrations?.length === 0 ? <div className="alert alert-secondary">No integrations configured</div> : null}
                </div>
                <button
                  type="button"
                  className="btn btn-normal"
                  onClick={() => setCustomer({ ...customer, integrations: [...customer.integrations || [], { platform: 'finastra' }] })}
                >Add Integration</button>
              </div>
            </div>
          </div>
          <div>
            {/* <div>
              <h4>Will Not Fund</h4>
              {Object.keys(WNFConfig).map(key => (
                <div className="form-group" key={key}>
                  <label htmlFor={`wnf_${key}`} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    {WNFConfig[key].label}
                    <SelectedIcon icon={WNFConfig[key].icon} size={20} style={{ paddingLeft: '0.3em' }} />
                  </label>
                  <input
                    id={`wnf_${WNFConfig[key]}`}
                    type="checkbox"
                    className="form-control"
                    checked={customer.willNotFund[key] || false}
                    onChange={ev => setCustomer({
                      ...customer,
                      willNotFund: {
                        ...customer.willNotFund,
                        [key]: !customer.willNotFund[key]
                      }
                    })}
                  />
                </div>
              ))}
            </div> */}
            <div className="form-group">
              <ValuesSortOrder
                sortOrder={customer.config.valuesSortOrder || {}}
                onChange={order => setCustomer({
                  ...customer,
                  config: {
                    ...customer.config,
                    valuesSortOrder: order
                  }
                })}
              />
            </div>
            <div className="form-group">
              <FeaturedTagSortOrder
                topTags={customer.config?.topTags || []}
                onChange={topTags => setCustomer({
                  ...customer,
                  config: {
                    ...customer.config,
                    topTags
                  }
                })}
              />
            </div>
          </div>
          <div style={{ marginBottom: '1.5em' }}>
            <HighlightedDataPointsForm
              datapoints={customer.highlightedDataPoints || []}
              impactAreas={impactAreas}
              onChange={datapoints => setCustomer({
                ...customer,
                highlightedDataPoints: datapoints
              })}
            />
          </div>
          <div style={{ marginBottom: '1.5em' }}>
            <PartnershipsForm
              partnerships={customer.partnerships || []}
              partnershipHeader={customer.partnershipHeader}
              partnershipSubtext={customer.partnershipSubtext}
              onChange={details => setCustomer({
                ...customer,
                ...details
              })}
            />
          </div>
          <div className="mt-5">
            <form onSubmit={handleSubmit}>
              <div className="pt-4 border-top mb-5">
                <button type="submit" className="btn btn-primary">
                  {submitting ? (
                    <Spinner />
                  ) : (
                    <div>Save Customer</div>
                  )}
                </button>
              </div>
            </form>
          </div>

          {customer._id ? <ImpactDataForm customer={customer} /> : (
            <div className="alert alert-info">
              Customer must be saved before adding impact data reports
            </div>
          )}
        </div>
      )}
    </>
  )
}

export default CustomerForm
