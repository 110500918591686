import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from 'react-query'
import { Campaign, Company } from '@ecountabl/lib'
import Spinner from './spinner'
import notify from './notifications-helper'
import api from './api-client'
import CampaignEditor from './campaign-editor'

type CampaignItemProps = {
  campaign: Campaign
  onClick?: (c: Campaign) => void
  selected?: boolean
}

const CampaignItem = ({ campaign, onClick, selected }: CampaignItemProps) => {
  const { active } = campaign
  return <div
    key={campaign.CampaignId}
    className={`campaign ${selected ? 'selected' : ''} ${active ? 'active' : ''}`}
    onClick={() => onClick && onClick(campaign)}
  >
    <img src={`https://cdn3.impact.com/${campaign.CampaignLogoUri}`} />
    <div className={`details`}>
      <div><b>{campaign.CampaignName}</b></div>
      {active ? <div className="status active">Active</div> : <div className="status inactive">Inactive</div>}
      <div className="active-deal-count">Active Deals: {campaign.deals?.length}</div>
    </div>
  </div>
}

const DealsPage: React.FC = () => {
  const [campaigns, setCampaigns] = useState([])
  const [currentCampaign, setCurrentCampaign] = useState()

  const refetch = useRef(0)
  const dispatch = useDispatch()

  const { data, error, isLoading } = useQuery(
    ['admin-campaigns', refetch.current],
    () => api.getCampaigns()
  )

  useEffect(() => {
    if (Array.isArray(data)) {
      data.sort((a, b) => {
        if (a.active && !b.active) {
          return -1
        } else if (b.active && !a.active) {
          return 1
        } else {
          return b.deals?.length - a.deals?.length
        }
      })
    }

    setCampaigns(data || [])

    if (currentCampaign && data) {
      const c = data.find(c => c.CampaignId === currentCampaign.CampaignId)
      setCurrentCampaign(c)
    }
  }, [data])

  if (isLoading) {
    return <Spinner />
  }

  if (error) {
    return (
      <div className="d-flex vh-100 justify-content-center align-items-center ">
        <div className="alert alert-error">
          {error}
        </div>
      </div>
    )
  }

  return (
    <div className="deals-page container">
      <h4>Campaigns</h4>
      <div className="buttons">
        <p>
          Campaigns automatically sync from Impact every 30m. If you're not seeing the latest updates, you
          can manually trigger a sync then refresh the page in 1-2m.
        </p>
        <button type="button" className="btn btn-primary" onClick={() => {
          api.syncCampaigns().then(({ message }) => {
            dispatch(notify.success({ message }))
          }).catch(({ message }) => {
            dispatch(notify.error({ message: `error syncing campaigns: ${message}` }))
          })
        }}>Sync Campaigns</button>
      </div>
      <div className="campaigns">
        {currentCampaign ? (
          <div>
            <div className="buttons">
              <button
                className="btn btn-light"
                type="button"
                onClick={() => setCurrentCampaign(undefined)}>
                <i className="fa fa-chevron-left" />&nbsp;All Campaigns
              </button>
            </div>
            <CampaignItem
              campaign={currentCampaign}
              selected={true}
            />
            <CampaignEditor
              campaign={currentCampaign}
              onSave={c => refetch.current = Date.now()}
            />
          </div>
        ) : (
          campaigns.map(c => (
            <CampaignItem
              key={c.CampaignId}
              campaign={c}
              onClick={() => setCurrentCampaign(c)}
            />
          ))
        )}
      </div>
    </div>
  )
}

export default DealsPage