import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import notify from './notifications-helper'
import Spinner from './spinner'

export const UpdateUserScores = () => {
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  return (
    <button
      type="button"
      className="btn btn-danger"
      disabled={saving}
      onClick={ev => {
        const proceed = window.confirm([
          'This will re-sync transactions and daily scores for all users that have synced ',
          'their plaid accounts, which will take some time. Proceed?'
        ].join(''))

        if (proceed) {
          setSaving(true)
          api.updateAllPlaidItems().then(res => {
            dispatch(notify.success({
              message: `Server received the request and is processing the background, check logs for progress`
            }))
          }).finally(() => {
            setSaving(false)
          })
        }
      }}>Update ALL Plaid Transactions and Daily Scores {saving && <Spinner />}</button>
  )
}

export default UpdateUserScores
