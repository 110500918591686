import React, { useEffect, useState } from 'react'

type Props = {
  field: string;
  label?: string;
  value: number | string | readonly string[];
  onChange: (ev: React.ChangeEvent<HTMLInputElement>) => void;
  autoFocus?: boolean;
  type?: string;
  autocomplete?: 'on' | 'off' | 'current-password' | 'new-password'
}

const FormField: React.FC<Props> = (props: Props) => {
  const { field, onChange, value, autoFocus, autocomplete = 'off' } = props
  const [type, setType] = useState(props.type)
  const label = props.label || field
  if (field === "_id") { return <></> }

  const icon = type === 'password' ? 'eye-slash' : 'eye'

  return <div key={field} className="form-group">
    <label>{label}</label>
    <div className="input-group">
      <input autoFocus={autoFocus}
        type={type}
        className="form-control"
        name={field}
        onChange={onChange} value={value}
        autoComplete={autocomplete}
      />
      {props.type === 'password' && (
        <div className="input-group-append">
          <span className="input-group-text" style={{ cursor: 'pointer' }}>
            <i onClick={ev => setType(type === 'password' ? 'text' : 'password')} className={`fa fa-${icon}`} />
          </span>
        </div>
      )}
    </div>
  </div>
}

export default FormField