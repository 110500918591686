import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from '../spinner'
import { useHistory } from 'react-router-dom'
import notify from '../notifications-helper'
import api from '../api-client'

const CompanyDataUploads = () => {
  const [uploads, setUploads] = useState([])
  const [uploading, setUploading] = useState(false)
  const [file, setFile] = useState<File>()
  const [name, setName] = useState('')

  const fileUrl = useRef<string>()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    api.getCompanyDataUploads().then(res => {
      setUploads(res)
    }).catch(ex => dispatch(notify.error({ message: `error loading data uploads: ${ex.message}` })))
  }, [])

  const uploadCompanyDataFile = async (ev) => {
    try {
      const signResponse = await api.signDataUpload(file)
      setUploading(true)
      await api.uploadFile(signResponse.signedRequest, file)
      setUploading(false)
      fileUrl.current = signResponse.url
      const upload = await api.createCompanyCSVUpload(fileUrl.current, name)
      setUploads([...uploads, upload])
    } catch (ex) {
      setUploading(false)
      dispatch(notify.error({ message: ex.message }))
    }
  }

  return (
    <div className="co-data-uploads">
      <h4>Company Data Uploads</h4>
      <div className="co-data-wrap">
        <div className="list-group" style={{ flex: 1 }}>
          {uploads.map(u => (
            <div
              key={u._id}
              style={{ cursor: 'pointer' }}
              className="list-group-item"
              onClick={() => history.push(`/data/upload/${u._id}`)}
            >
              <div><b>{u.name || '(none)'}</b></div>
              <div className="small">File: {u.fileKey}</div>
              <div className="small">Status: {u.status}</div>
            </div>
          ))}
          {uploads.length == 0 ? (
            <div className="alert alert-secondary">No existing data uploads</div>
          ) : null}
        </div>
        <div style={{ flex: 1, marginLeft: '1em' }}>
          <p>
            Upload CSV data to merge it with existing company data. This will
            start a process where you can review company matches, assign fields,
            save progress, and ultimately complete the upload.
          </p>
          <div className="form-group">
            <label htmlFor="customer-data-file">Company Data Upload</label>
            <input id="customer-data-file"
              type="file"
              onChange={ev => setFile(ev.target.files[0])}
            />
            {uploading && <Spinner />}
          </div>
          <div className="form-group">
            <label>Upload Name</label>
            <input
              className="form-control"
              value={name}
              onChange={ev => setName(ev.target.value)}
            />
            {uploading && <Spinner />}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!name || !file}
            onClick={uploadCompanyDataFile}
          >New Upload</button>
        </div>
      </div>
    </div>
  )
}

export default CompanyDataUploads
