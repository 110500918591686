// import { Indicator, Value } from './'
import { SeoLanguage } from './misc'
import { Company } from './company'
import { Value } from './value'
import { Indicator, FAQLanguage } from './'

export const SCORES_INDEX = {
  // science-backed targets
  "SBTI": {
    "1.5°C": 1,
    "Well-below 2°C": 0.9,
    "2°C": 0.8,
    "Committed": 0.75
  },
  "Y/N": {
    Y: 1,
    N: 0
  },
  "A to C": {
    A: 1,
    B: 0.5,
    C: 0
  },
  "A to F": {
    "A": 0.95,
    "A-": 0.85,
    "B": 0.75,
    "B-": 0.6,
    "C": 0.5,
    "C-": 0.4,
    "D": 0.25,
    "D-": 0.15,
    "F": 0
  }
}

export const sluggifyName = (name: string): string => {
  return name.toLowerCase().replace(/[.'"$(){}[\]!@,#%&]/g, '').replace(/[ /]+/g, '-')
}

export const normalizeRating = (indicator: Indicator, rating: string | number): number => {
  let score
  const _rating: number = typeof rating === 'string' ? parseFloat(rating) : rating

  const weights = SCORES_INDEX[indicator.scale]
  if (weights) {
    // map a rating to a numerical value
    // A-F, A-C, Y/N, etc
    score = weights[_rating] || 0
  } else {
    // numeric so we can calculate a range; e.g., 0-100, -25-125
    const [min, max] = indicator.scale.split(' to ').map((v) => parseInt(v, 10))
    score = (_rating - min) / (max - min)
  }

  return score
}

export const starRating = (score: number): number => {
  let stars = 0

  switch (true) {
    case score < 0.2:
      stars = 1
      break
    case score < 0.4:
      stars = 2
      break
    case score < 0.6:
      stars = 3
      break
    case score < 0.8:
      stars = 4
      break
    case score >= 0.8:
      stars = 5
      break
    default:
      break
  }

  return stars
}

export const whitelistKeys = (
  obj: Record<string, unknown>,
  keys: string[]
): Record<string, unknown> => {
  const out = {}

  Object.keys(obj).forEach(k => {
    if (keys.includes(k)) {
      out[k] = obj[k]
    }
  })

  return out
}

type Dims = {
  height: number;
  width: number;
}

export const dimsScaledToWidth = (targetWidth: number, w: number, h: number): Dims => {
  const multiplier = targetWidth / w
  return {
    width: targetWidth,
    height: h * multiplier
  }
}

export const dimsScaledToHeight = (targetHeight: number, w: number, h: number): Dims => {
  const multiplier = targetHeight / h
  return {
    width: w * multiplier,
    height: targetHeight
  }
}

type PhotoFit = {
  height: number;
  width: number;
  x: number; // offset
  y: number; // offset
}

export const bestPhotoFit = (targetW: number, targetH: number, w: number, h: number): PhotoFit => {
  let x = 0, y = 0
  let dims

  if (w > h) {
    dims = dimsScaledToHeight(targetH, w, h)
    if (dims.width > targetW) {
      // photo is too wide, so constrain it by width
      dims = dimsScaledToWidth(targetW, w, h)
    }
  } else {
    dims = dimsScaledToWidth(targetW, w, h)
    if (dims.height > targetH) {
      dims = dimsScaledToHeight(targetH, w, h)
    }
  }

  if (dims.height === targetH) {
    x = (targetW - dims.width) / 2
  } else {
    y = (targetH - dims.height) / 2
  }

  return { ...dims, x, y }
}

export const shuffle = (array: unknown[]): unknown[] => {
  let currentIndex = array.length
  let randomIndex

  // While there remain elements to shuffle...
  while (0 !== currentIndex) {
    // Pick a remaining element...
    randomIndex = Math.floor(Math.random() * currentIndex)
    currentIndex--

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]]
  }

  return array
}

export const titleCase = (str: string): string => {
  return str.toLowerCase().split(' ').map(word => {
    return (word.charAt(0).toUpperCase() + word.slice(1))
  }).join(' ')
}

// TODO: this should be implemented as a static method of indicators once we 
// consolidate ecountabl-types and @ecountabl/util
export const verifyIndicatorConfig = (values: Value[], indicators: Indicator[]): string[] => {
  const warnings = []

  // TODO: account for superceding indicators that are inactive or in different Value assignments
  values.forEach(v => {
    const indicatorsInValue = indicators.filter(i => i.slug === v.slug)
    if (indicatorsInValue.length === 0) {
      warnings.push(`No indicators for Value ${v.label}`)
    }
  })

  indicators.forEach(i => {
    if (i.slug !== undefined && !values.find(v => v.slug === i.slug)) {
      warnings.push(`Could not find Value ${i.slug} for Indicator '${i.label}'`)
    }
  })

  return warnings
}

type Logoish = string | {
  webpurl?: string
  logourl?: string
  logoref?: string
}

// TODO: bulk of this logic could be eliminated with a 
// database migration to consolidate image patterns
export const logoUrl = (l: Logoish, origin?: string): string | undefined => {
  let nameOrUrl: string

  if (typeof l === 'string') {
    nameOrUrl = l
  } else if (l) {
    nameOrUrl = l.webpurl || l.logourl || l.logoref
  }

  if (nameOrUrl && /^https?:\/\//.test(nameOrUrl)) {
    const url = new URL(nameOrUrl)
    if (origin) {
      return url.href.replace(url.origin, origin).replace('/logos.ecountabl.com', '')
    } else {
      return nameOrUrl
    }
  } else if (nameOrUrl && origin) {
    // legacy implementation, most likely uses l.logoref and doesn't include an extension
    const ext = nameOrUrl.split('.').pop()
    if (['png', 'webp', 'jpg', 'jpeg', 'svg', 'gif', 'tiff'].includes(ext?.toLowerCase())) {
      return `${origin}/${nameOrUrl}`
    } else {
      return `${origin}/${encodeURIComponent(nameOrUrl)}.png`
    }
    
  }
}
