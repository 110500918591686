import {
  FAQLanguage,
  starRating,
  normalizeRating,
  Company,
  Indicator,
  SeoLanguage
} from "."

enum IndicatorScale {
  YesNo = "Y/N",
  AtoC = "A to C",
  AtoF = "A to F"
}

const perf = (starCount: number): string => {
  if (starCount < 3) {
    return 'underperforms'
  } else if (starCount === 3) {
    return 'has moderately good performance'
  } else if (starCount > 3) {
    return 'is a leader'
  }
}

export const defaultLanguage = (scale: IndicatorScale): FAQLanguage => {
  if (scale == IndicatorScale.YesNo) {
    return {
      q: `Does %COMPANY% support %LABEL%?`,
      yes: `Yes, %COMPANY% supports %LABEL%`,
      no: `No, %COMPANY% does not support %LABEL%`,
      attribution: `This is according to %DATASOURCE%, which measures whether or not a company %DESCRIPTION%.`
    }
  } else {
    return {
      q: `How does %COMPANY% perform regarding %LABEL%?`,
      nonBinaryAnswer: `%COMPANY% %PERFORMANCE% in %LABEL%`,
      attribution: `according to %DATASOURCE%, which measures how well a company %DESCRIPTION%.`
    }
  }
}

export const replacePlaceholders = (template: string, {
  company,
  indicator
}): string => {
  const { abbreviation } = indicator
  const rating = company.calculatedRatings[abbreviation]
  const normalizedRating = normalizeRating(indicator, rating)
  const starCount = starRating(normalizedRating)
  const { description } = indicator
  const idesc = (
    description
      ? description[0].toLowerCase() + description.substring(1)
      : 'performs in this area'
  ).replace(/\.+$/gi, '') // ensure no trailing period

  return template.replace('%COMPANY%', company.name)
    .replace('%LABEL%', indicator.label)
    .replace('%DESCRIPTION%', idesc)
    .replace('%DATASOURCE%', indicator.indicator_family)
    .replace('%PERFORMANCE%', perf(starCount))
}

export const templatesForIndicator = (i: Indicator): FAQLanguage => {
  return Object.assign(defaultLanguage(i.scale), i.faqLanguage)
}

export const seoLanguage = (
  company: Company,
  indicator: Indicator
): SeoLanguage => {
  const { abbreviation } = indicator
  const rating = company.calculatedRatings[abbreviation]

  const faqTemplates = templatesForIndicator(indicator)
  const question = replacePlaceholders(faqTemplates.q, { company, indicator })
  const attribution = replacePlaceholders(faqTemplates.attribution, { company, indicator }).trim()

  if (indicator.scale === IndicatorScale.YesNo) {
    const template = rating === 'Y' ? faqTemplates.yes : faqTemplates.no
    const answer = replacePlaceholders(template, { company, indicator }).trim()

    return {
      question,
      answer: answer + ' ' + attribution
    }
  } else {
    const answer = replacePlaceholders(faqTemplates.nonBinaryAnswer, { company, indicator }).trim()

    return {
      question,
      answer: answer + ' ' + attribution
    }
  }
}
