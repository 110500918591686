import React, { useState } from 'react'
import { Indicator, FAQ, Company } from '@ecountabl/lib'

type Props = {
  indicator: Indicator
  onDone: (i: Indicator) => void
}

export const BizDetailEditor = ({ indicator, onDone }: Props) => {
  const [ind, setInd] = useState(indicator)

  const handleChange = (ev) => {
    const { name, value } = ev.target
    const [field, subField] = name.split('.')

    // special handling to support "faqLanguage.q" subField setup
    if (subField) {
      const obj = ind[field] || {}
      obj[subField] = value
      setInd({ ...ind, [field]: obj })
    } else {
      setInd({ ...ind, [name]: value })
    }
  }

  const templates = FAQ.templatesForIndicator(ind)

  return (
    <div className="detail-editor">
      <h3>Editing Indicator: {ind.label}</h3>
      <div className="form-group">
        <label>Description</label>
        <input
          value={ind.description || ''}
          name="description"
          onChange={handleChange}
          type="text"
          className="form-control"
        />
      </div>
      <div className="form-group">
        <label>Scale</label>
        <input
          value={ind.scale || ''}
          name="scale"
          onChange={handleChange}
          type="text"
          className="form-control"
        />
        <p className="faq-ex">Y/N (binary), A to C, A to F, x to y (numeric), or SBTI</p>
      </div>
      <div className="faq-config">
        <div>
          <div className="form-group">
            <label>FAQ Question Override</label>
            <input
              value={templates.q}
              name="faqLanguage.q"
              onChange={handleChange}
              type="text"
              className="form-control"
            />
            <p className="faq-ex">
              {FAQ.replacePlaceholders(templates.q, {
                company: { name: 'Amazon', calculatedRatings: {} },
                indicator: ind
              })}
            </p>
          </div>
          {ind.scale === 'Y/N' ? <>
            <div className="form-group">
              <label>FAQ Answer Override - YES</label>
              <input
                value={templates.yes || ''}
                name="faqLanguage.yes"
                onChange={handleChange}
                type="text"
                className="form-control"
              />
              <p className="faq-ex">
                {FAQ.seoLanguage(
                  { name: 'Amazon', calculatedRatings: { [ind.abbreviation]: 'Y' } } as Company,
                  ind
                ).answer}
              </p>
            </div>
            <div className="form-group">
              <label>FAQ Answer Override - NO</label>
              <input
                value={templates.no || ''}
                name="faqLanguage.no"
                onChange={handleChange}
                type="text"
                className="form-control"
              />
              <p className="faq-ex">
                {FAQ.seoLanguage(
                  { name: 'Amazon', calculatedRatings: { [ind.abbreviation]: 'N' } } as Company,
                  ind
                ).answer}
              </p>
            </div>
          </> : (
            <div className="form-group">
              <label>FAQ Answer Override</label>
              <input
                value={templates.nonBinaryAnswer || ''}
                name="faqLanguage.nonBinaryAnswer"
                onChange={handleChange}
                type="text"
                className="form-control"
              />
              {[1, 3, 5].map(n => <p className="faq-ex" key={n}>
                {FAQ.seoLanguage(
                  { name: 'Amazon', calculatedRatings: { [ind.abbreviation]: n } } as Company,
                  // hard-code the scale to make the examples easy
                  { ...ind, scale: '1 to 5' }
                ).answer}
              </p>
              )}
            </div>
          )}
          <div className="form-group">
            <label>Attribution</label>
            <input
              value={templates.attribution || ''}
              name="faqLanguage.attribution"
              onChange={handleChange}
              type="text"
              className="form-control"
            />
          </div>
        </div>
        <div className="alert alert-info" style={{ marginLeft: '1em', maxWidth: '300px' }}>
          <p>The following variables are used in all FAQ override fields.</p>
          <dl>
            <dt>%COMPANY%</dt>
            <dd>Company Name</dd>
            <dt>%LABEL%</dt>
            <dd>Indicator Label</dd>
            <dt>%DATASOURCE%</dt>
            <dd>Datasource Label</dd>
            <dt>%DESCRIPTION%</dt>
            <dd>Indicator Description</dd>
            <dt>%PERFORMANCE%</dt>
            <dd>Star-based performance for non-binary scales</dd>
          </dl>
        </div>
      </div>
      <div className='alert alert-info'>
        <p>
          Clicking "Done" on this form will only update the working copy of the indicator in the list, you will still
          need to save the table when complete.
        </p>
      </div>
      <button type="button" className="btn btn-primary" onClick={() => onDone(ind)}>Done</button>
    </div >
  )
}

export const BankDetailEditor = ({ indicator, onDone }: Props) => {
  const [ind, setInd] = useState(indicator)

  const handleChange = (ev) => {
    const { name, value } = ev.target
    const [field, subField] = name.split('.')

    // special handling to support "faqLanguage.q" subField setup
    if (subField) {
      const obj = ind[field] || {}
      obj[subField] = value
      setInd({ ...ind, [field]: obj })
    } else {
      setInd({ ...ind, [name]: value })
    }
  }

  return (
    <div className="detail-editor">
      <h3>Editing Indicator: {ind.label}</h3>
      <div className="alert alert-secondary">No details need editing for bank indicators... yet 😱</div>
      <div className='alert alert-info'>
        <p>
          Clicking "Done" on this form will only update the working copy of the indicator in the list, you will still
          need to save the table when complete.
        </p>
      </div>
      <button type="button" className="btn btn-primary" onClick={() => onDone(ind)}>Done</button>
    </div>

  )
}

export const DetailEditor = ({ indicator, onDone }: Props) => {
  if (indicator.attachment === 'bank') {
    return <BankDetailEditor
      indicator={indicator}
      onDone={onDone}
    />
  } else {
    return <BizDetailEditor
      indicator={indicator}
      onDone={onDone}
    />
  }
}

export default DetailEditor