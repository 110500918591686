import React, { useEffect, useState } from 'react'
import api from '../api-client'
import Spinner from '../spinner'
import { useDispatch } from 'react-redux'
import notify from '../notifications-helper'
import FormField from '../form-field'
import { Customer, IImpactData, DataPoint, IImpactArea } from '@ecountabl/lib'

type FormProps = {
  datapoints: DataPoint[]
  impactAreas: IImpactArea[]
  onChange: (dps: DataPoint[]) => void
}

const HighlightedDataPointsForm = ({ datapoints, impactAreas, onChange }: FormProps) => {
  return (
    <div className="data-form">
      <h4>Highlighted Data Points</h4>
      <p>Additional datapoints that aren't directly part of the impact report</p>
      <div className="grid">
        <div className="grid-hdr">
          <div>Label</div>
          <div>Value</div>
          <div>Impact Area</div>
        </div>
        {!datapoints?.length ? <div className="alert alert-secondary">No custom datapoints configured</div> : null}
        {datapoints?.map((dp, i) => <div key={i} className="form-group form-inline bank-indicator-row">
          <input
            className="form-control"
            type="text"
            value={dp.label}
            style={{ width: '300px' }}
            onChange={ev => {
              const dps = [...datapoints]
              dps[i].label = ev.target.value
              onChange(dps)
            }}
          />
          <input
            className="form-control"
            type="text"
            value={dp.value}
            onChange={ev => {
              const dps = [...datapoints]
              dps[i].value = ev.target.value
              onChange(dps)
            }}
          />
          <select
            value={dp.impactAreaId}
            className="form-control"
            onChange={ev => {
              const dps = [...datapoints]
              dps[i].impactAreaId = ev.target.value
              onChange(dps)
            }}
          >
            <option></option>
            {impactAreas.map(ia => <option key={ia._id} value={ia._id}>{ia.label}</option>)}
          </select>
          <div style={{ padding: '0.5em' }}>
            <i className="fa fa-trash" style={{
              cursor: 'pointer',
              fontSize: '1.5em'
            }} onClick={() => {
              const dps = [...datapoints]
              dps.splice(i, 1)
              onChange(dps)
            }} />
          </div>
        </div>)}
        <div>
          <button
            type="button"
            className="btn btn-normal"
            onClick={() => {
              onChange([...datapoints, { label: '', value: '' }])
            }}
          >Add New Data Point <i className="fa fa-plus-circle" /></button>
        </div>
      </div>
    </div>
  )
}


export default HighlightedDataPointsForm