
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { FaFacebookSquare, FaLinkedin, FaTwitterSquare } from "react-icons/fa";

class Footer extends Component {
  render() {
    return <footer className="ecountabl-nav navbar-expand-lg">
        <div className="footer-links">
          <ul className="navbar-nav mr-auto">
            <li className="nav-item"><Link to="/features">How it Works</Link></li>
            <li className="nav-item"><Link to="/data">Data & Innovation</Link></li>
            <li className="nav-item"><Link to="/about">About</Link></li>
            <li className="nav-item"><Link to="/newsroom">Newsroom</Link></li>
            <li className="nav-item"><Link to="/blog/our-story">Our Story</Link></li>
            <li className="nav-item"><Link to="/home/#signup">Get The App</Link></li>
          </ul>
        </div>
        <div className="social-icons">
          <a href="https://www.facebook.com/ecountabl/" target="_blank">
            <FaFacebookSquare style={{ color: "#3b5998"}} />
          </a>
          <a href="https://www.linkedin.com/company/ecountabl/" target="_blank">
            <FaLinkedin style={{ color: "#0976b4"}} />
          </a>
          <a href="https://twitter.com/ecountabl" target="_blank">
            <FaTwitterSquare style={{ color: "#55acee"}} />
          </a>
        </div>
        <div className="footer-addl">
          <p>© Copyright 2019 ecountabl Inc. All rights reserved.</p>
          <p><b><a href="email:info@ecountabl.com">info@ecountabl.com</a></b></p>
        </div>
      </footer>
  }
}

export default Footer
