import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import api from '../../api-client'

type LoginInfo = {
  username: string;
  password: string;
}

export const login = createAsyncThunk<any, LoginInfo, {}>(
  'user/login',
  async ({ username, password }, thunkApi) => {
    try {
      const response = await api.login(username, password)
      return response
    } catch (ex) {
      return thunkApi.rejectWithValue(ex.message || ex.msg)
    }
  }
)

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    status: 'loading',
    user: undefined,
    error: '',
    hasToken: !!localStorage.getItem('_token')
  },
  extraReducers: {
    [login.pending]: (state, action) => {
      state.status = 'loading'
    },
    [login.fulfilled]: (state, action) => {
      state.status = 'succeeded'
      // Add any fetched posts to the array
      state.user = action.payload
      state.hasToken = true
    },
    [login.rejected]: (state, action) => {
      state.status = 'failed'
      state.error = action.payload || action.error?.message
      state.hasToken = false
    }
  },
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload
      state.hasToken = !!action.payload
    },
    logout: state => {
      localStorage.removeItem('_token')
      state.user = undefined
      state.hasToken = false
    }
  }
})

// Action creators are generated for each case reducer function
export const { setUser, logout } = userSlice.actions

export default userSlice.reducer
