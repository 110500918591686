import React from 'react'
import { normalizeRating, SCORES_INDEX, starRating } from '@ecountabl/lib'

const STARS = {
  1: require('./img/stars-1.png'),
  2: require('./img/stars-2.png'),
  3: require('./img/stars-3.png'),
  4: require('./img/stars-4.png'),
  5: require('./img/stars-5.png'),
}

const IMAGES = {
  'Access to Nutrition Index': require('./img/access-to-nutrition-index.png'),
  Bloomberg: require('./img/bloomberg-gender-equality.png'),
  CDP: require('./img/cdp.png'),
  'Corporate Human Rights Benchmark': require('./img/corporate-human-rights-benchmark.png'),
  CSRHub: require('./img/csrhub.png'),
  Equileap: require('./img/equileap.png'),
  'Gender Fair': require('./img/gender-fair.png'),
  'Human Rights Campaign': require('./img/human-rights-campaign-equality.png'),
  'JUST Capital': require('./img/just.png'),
  'BCorp': require('./img/bcorp.png'),
  'Know the Chain': require('./img/know-the-chain.png'),
  'Paradigm for Parity': require('./img/paradigm-for-parity.png'),
  'Thomson Reuters': require('./img/thomson-reuters.png'),
  'We Are Still In': require('./img/we-are-still-in.png'),
}


class PerformanceItem extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showScores: false,
    }
  }

  onClick() {
    window.open('https://ecountabl.com/data', '_blank')
  }

  render() {
    const { showScores } = this.state
    const { abbreviation, stars, indicator, normalizedRating } = this.props
    const starCount = starRating(normalizedRating)
    const starImg = STARS[starCount]

    return (
      <div style={styles.item} onClick={this.onClick}>
        <img style={styles.rating} src={starImg} />
        <span style={styles.detail}>{indicator.label}</span>
        <img
          style={styles.indicatorImg}
          src={IMAGES[indicator['indicator_family']]}
        />
      </div>
    )
  }
}

export default class Performance extends React.Component {
  render() {
    const { indicators, company } = this.props
    const { byAbbreviation, byCategory } = indicators
    const categories = Object.keys(indicators.byCategory)
    const ratings = company.calculatedRatings

    return (
      <div className="performance">
        {categories.map((cat) => {
          // only show category if we have at least one of the abbreviations
          let showCategory = byCategory[cat].find((abb) => ratings[abb])
          if (!showCategory) {
            return
          }

          return (
            <div key={cat}>
              <div style={styles.header}>{cat}</div>
              {byCategory[cat].map((abb) => {
                let indicator = byAbbreviation[abb]

                // only show valid scores
                if (!ratings[abb]) {
                  return
                }

                let normalizedRating = normalizeRating(indicator, ratings[abb])
                return (
                  <PerformanceItem
                    key={abb}
                    abbreviation={abb}
                    indicator={indicator}
                    normalizedRating={normalizedRating}
                  />
                )
              })}
            </div>
          )
        })}
      </div>
    )
  }
}

const styles = {
  header: {
    fontSize: 16,
    padding: 5,
    backgroundColor: '#cccccc',
  },
  rating: {
    width: 75,
    height: 20,
    margin: 5,
    display: 'inline-flex',
  },
  source: {
    flex: 4,
    textAlign: 'left',
    padding: 5,
  },
  indicatorImg: {
    resizeMode: 'contain',
    marginRight: '0.2em',
    display: 'inline-flex',
  },
  item: {
    flex: 1,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    cursor: 'pointer',
    borderBottomColor: '#333',
    borderBottomWidth: 1,
  },
  share: {
    justifyContent: 'center',
    padding: 10,
  },
  detail: {
    flex: 6,
    flexDirection: 'column',
    justifyContent: 'space-between',
    display: 'inline-flex',
    fontSize: 20,
    padding: 5,
  },
  scoreLabel: {
    fontSize: 16,
  },
  score: {
    fontSize: 14,
  },
}
