/* eslint-disable @typescript-eslint/no-namespace */
import { IndicatorAttachment, PlaidTransaction } from "."
import { Campaign } from './deals'

export namespace API {
  export type DefaultResponse = {
    errors?: string[];
    message?: string;
    msg?: string;
  }

  export type SubmitCompanyBody = {
    company: {
      name: string;
      link?: string;
      isLocal: boolean;
      city?: string;
      state?: string;
      userFreeFormInput?: string;
    },
    tx?: PlaidTransaction
  }

  enum DealSort {
    EndDate = 'Deals Ending Soon',
    StartDate = 'New Deals',
    Score = 'Highest Scoring Deals For You'
  }

  export type DealsResponse = {
    sort?: DealSort,
    campaigns: Campaign[]
  }

  export type IndicatorQuery = {
    attachment?: IndicatorAttachment
  }

  export type CreateImpactArea = {
    label: string
    valueSlug: string
  }

  export type SignFileUploadResponse = {
    signedRequest: string
    fileName: string
    url: string
  }
}
