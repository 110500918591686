import React from 'react'
import { Link } from 'react-router-dom'
import NavUserDropdown from './nav-user-dropdown'
import AdminNavMenuDropdown from './admin-nav-menu-dropdown'
import { REACT_APP_SERVER_ROOT } from './config'

const Nav = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="navbar-collapse">
        <div className="navbar-nav mr-auto">
          <div className="nav-item"><Link to="/customers">Customers</Link></div>
          <AdminNavMenuDropdown
            labelElement={<span>Database</span>}
            menuElements={
              <>
                <div className="nav-item"><Link to="/companies">Companies</Link></div>
                <div className="nav-item"><Link to="/values">Values</Link></div>
                <div className="nav-item"><Link to="/indicators">Indicators</Link></div>
                <div className="nav-item"><Link to="/impactareas">Impact Areas</Link></div>
                <div className="nav-item"><Link to="/questions">Questions</Link></div>
                <div className="nav-item"><Link to="/datasources">Datasources</Link></div>
                <div className="nav-item"><Link to="/segments">Segments &amp; Tags</Link></div>
                {/* <div className="nav-item"><Link to="/mappings">Mappings</Link></div> */}
              </>
            }
          />
          <AdminNavMenuDropdown
            labelElement={<span>Moderation</span>}
            menuElements={
              <>
                <div className="nav-item"><Link to="/moderate">Company Backlog</Link></div>
                <div className="nav-item"><Link to="/transactions">Transactions</Link></div>
              </>
            }
          />
          <div className="nav-item"><Link to="/deals">Deals</Link></div>
          <div className="nav-item"><Link to="/data">Bulk Operations</Link></div>
          <div className="nav-item"><a href='#' onClick={() => {
            window.open(
              `${REACT_APP_SERVER_ROOT}/admin/queues?token=${localStorage.getItem('_token')}`,
              '_blank'
            )
          }}>Queues <i className="fa fa-external-link" /></a></div>
        </div>
        <NavUserDropdown showCompanySearch={true} />
      </div>
    </nav>
  )
}

export default Nav
