import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Company } from '@ecountabl/lib'
import notify from './notifications-helper'
import { FaExclamationTriangle, FaCheck } from 'react-icons/fa'
import api from './api-client'
import CompanySearch, { CompanySearchProps } from './company-search'

export type VerifiedCompanySearchProps = {
  company?: Company
}

// TODO: consolidate with corporate parent editor
export const VerifiedCompanySearch = ({ company, ...props }: VerifiedCompanySearchProps & CompanySearchProps) => {
  const dispatch = useDispatch()

  const [isValid, setIsValid] = useState(true)

  useEffect(() => {
    if (company) {
      api.verifyCorporateParent(company._id || company.name).then(({ id }) => {
        setIsValid(!!id)
      }).catch(ex => dispatch(notify.error({
        message: `error verifying corporate parent: ${ex.message}`
      })))
    }
  }, [company])

  const flags = () => {
    if (!company) {
      return
    }

    return isValid ? <FaCheck style={{ color: 'green' }} /> : (
      <span title="Could not find company">
        <FaExclamationTriangle style={{ color: 'orange' }} />
      </span>
    )
  }

  return <CompanySearch
    {...props}
    initialSearch={company?.name}
    inputPrependChildren={flags()}
  />
}

export default VerifiedCompanySearch