import React, { useState } from 'react';
import api from './api-client'
import { useHistory } from 'react-router-dom'
import logo from './img/logo_e.png'
import { useDispatch, useSelector } from 'react-redux'
import { login } from './redux/slices/user'
import { useEffect } from 'react';

const Login = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')

  const dispatch = useDispatch()

  const { error } = useSelector(s => s.user)

  const history = useHistory()

  const handleSubmit = (ev) => {
    ev.preventDefault()
    dispatch(login({ username, password }))
  }

  return <div className="login mini-form">
    <a href="https://ecountabl.com"><img src={logo} alt="logo" /></a>
    <form className="form-signin" onSubmit={handleSubmit}>
      <input type="text" onChange={ev => setUsername(ev.target.value)} value={username} className="form-control" placeholder="Username" required autoFocus />
      <input onChange={ev => setPassword(ev.target.value)} type="password" id="inputPassword" className="form-control" placeholder="Password" required />
      <button className="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
      <button
        className="btn btn-lg btn-secondary btn-block"
        onClick={() => history.push('/')}
        type="button">Back</button>
      {error && <div className="alert alert-danger">Login error: {JSON.stringify(error)}</div>}
    </form>
  </div>
}

export default Login
