import React, { useState, cloneElement } from 'react'
import PasswordStrengthBar from 'react-password-strength-bar';
import api from './api-client'
import Spinner from './spinner'

const ResetPassword = (props) => {
  const { token } = props.match.params
  
  const [pw, setPw] = useState('')
  const [loading, setLoading] = useState(false)
  const [result, setResult] = useState('')
  const [error, setError] = useState()

  return <div className="reset-password">
    <h5>Password Reset</h5>
    <form onSubmit={ev => {
      ev.preventDefault()
      setLoading(true)

      api.resetPassword(pw, token).then(res => {
        setLoading(false)
        setResult(res.message)
      }).catch(ex => {
        setLoading(false)
        setError(ex.message)
      })

      return false
    }}>
      <input 
        type="password" 
        placeholder="Enter new password"
        autoFocus
        className="form-control"
        onChange={(ev) => setPw(ev.target.value)}
      />
      <PasswordStrengthBar password={pw} />
      {loading ? <Spinner /> : (
        <button 
          className="btn btn-md btn-primary" 
          type="submit"
        >Submit</button>
      )}
      {result && (
        <div className='alert alert-info'>{result}</div>
      )}
      {error && (
        <div className="alert alert-danger">
          Error: {error}
        </div>
      )}
    </form>
  </div>
}

export default ResetPassword