import React, { useEffect, useState } from 'react'
import api from './api-client'
import Spinner from './spinner'
import { useHistory } from 'react-router'
import { useDispatch } from 'react-redux'
import ImageUploader from './image-uploader'
import notify from './notifications-helper'
import IconChooser from './icon-chooser'

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

const ValueForm: React.FC<Props> = ({ match }: Props) => {
  const { id } = match.params
  const [value, setValue] = useState(null)
  const [loading, setLoading] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [questions, setQuestions] = useState([])
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    const init = async () => {
      try {
        const [_value, qs] = await Promise.all([
          api.getValue(id),
          api.getQuestions()
        ])

        // ensure questions are initialized
        _value.questions = _value.questions || []

        setValue(_value)
        setQuestions(qs)
        setLoading(false)
      } catch (ex) {
        setLoading(false)
      }
    }

    init()
  }, [api.getValue, id])

  const handleSubmit = async (event) => {
    setSubmitting(true)
    event.preventDefault()
    try {
      await api.putValue(value)
      setSubmitting(false)
      dispatch(notify.success({ message: "Value saved successfully" }))
    } catch (err) {
      setSubmitting(false)
      dispatch(notify.error({ message: `Error saving value: ${err.message}` }))
    }
  }

  const handleChange = (event) => {
    setValue({ ...value, [event.target.name]: event.target.value })
  }

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <Spinner />
        </div>
      ) : (
        <div className="container mt-5">
          <h1 className="mb-0">Value: {value.label}</h1>
          <p className="text-muted">id: {value._id}</p>
          <div className="mt-5">
            {/* The form to edit properties */}
            <form onSubmit={handleSubmit}>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Label</label>
                <input
                  value={value.label}
                  name="label"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Force Value Selection</label>
                <input
                  checked={value.forceSelection || false}
                  name="forceSelection"
                  onChange={() => {
                    setValue({
                      ...value,
                      forceSelection: !value.forceSelection
                    })
                  }}
                  type="checkbox"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Icon</label>
                <IconChooser
                  value={value.icon}
                  onChange={icon => setValue({ ...value, icon })}
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Logo</label>
                <ImageUploader
                  onChange={(logoref: string): void => setValue({ ...value, logoref: logoref })}
                  imgSrc={value.logoref || ''}
                  style={{ maxHeight: '200px' }}
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Banner</label>
                <ImageUploader
                  onChange={(bannerImg: string): void => setValue({ ...value, bannerImg })}
                  imgSrc={value.bannerImg || ''}
                  style={{ maxHeight: '200px' }}
                  targetSize={{ width: 500, height: 200 }}
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column pb-3">
                <label>Description</label>
                <textarea
                  value={value.description || ''}
                  name="description"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column pb-3">
                <label>"Contributed To" Subtext</label>
                <input
                  value={value.contributedToSubtext || ''}
                  name="contributedToSubtext"
                  type="text"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column pb-3">
                <label>Sort Order</label>
                <input
                  value={value.sortOrder}
                  name="sortOrder"
                  type="text"
                  onChange={handleChange}
                  className="form-control"
                />
              </div>

              <div className="form-group">
                <label>Questions</label>
                <div>
                  {/* the datamodel supports multiple questions, 
                        but we only support one in the app for now */}
                  <select
                    className="form-control"
                    value={value.questions[0]}
                    onChange={ev => setValue({ ...value, questions: [ev.target.value] })}
                  >
                    <option></option>
                    {questions.map(q => <option key={q._id} value={q._id}>{q.label}</option>)}
                  </select>
                  <p className="help">Question that will appear in the values setup on the app</p>
                </div>
              </div>

              <div className="pt-4 border-top mb-5">
                {submitting ? (
                  <div className="spinner-border spinner-border-sm" />
                ) : (
                  <div className="buttons">
                    <button type="submit" className="btn btn-primary ">
                      <div>Save</div>
                    </button>
                    <button
                      className="btn btn-danger"
                      type="button"
                      onClick={() => {
                        if (window.confirm('Are you sure? Deleting a Value will unset the association for all indicators and impact areas that may be associated with this Value.')) {
                          setSubmitting(true)
                          api.deleteValue(value).then(() => {
                            dispatch(notify.success({ message: "Successfully deleted Value" }))
                            history.push('/values')
                          }).catch(ex => {
                            dispatch(notify.error({ message: `Error deleting value: ${ex.message}` }))
                            setSubmitting(false)
                          })
                        }
                      }}>
                      Delete Value <i className="fa fa-trash" />
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default ValueForm
