import React, { useState, useRef, useEffect } from 'react'
import { useHistory } from 'react-router'
import { connect, useDispatch } from 'react-redux'
import Spinner from './spinner'
import api from './api-client'
import actions from './redux/actions'
import jsontotable from 'json-to-table'

const CompanyCount = () => {
  const [count, setCount] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    api.getCompanyCount().then(res => {
      setCount(res.count)
      setLoading(false)
    }).catch(console.error)
  }, [])

  return (
    <div className="company-count alert alert-info">
      {loading ? <Spinner /> : <>Total Companies: <b>{count}</b></> }      
    </div>
  )
}

const Companies = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const timeout = useRef(null)

  const [companies, setCompanies] = useState([])
  const [searchText, setSearchText] = useState('')

  const companyClick = (id, co) => {
    history.push('/companies/' + id, co)
  }

  const onAdd = () => {
    history.push('/companies/new')
  }


  const onSearchChange = (ev) => {
    setSearchText(ev.target.value)

    if (ev.target.value.length > 2) {
      // add a 300ms delay so we avoid race conditions
      // and take it easy on the server since this is an expensive search
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }

      const query = ev.target.value
      timeout.current = setTimeout(() => {
        api.tokenizedSearch(query).then(res => {
          const companies = res.companies.map(co => {
            const { _id, name, corporate_parent, segment } = co
            return { _id, name, corporate_parent, segment }
          })

          setCompanies(companies)
        }).catch(ex => dispatch(actions.ajaxErrorHandler(ex)))
      }, 300)
    }
  }

  let companiesTable = []
  if (companies.length) {
    companiesTable = jsontotable(companies)
  }

  return <div className="company-search-page">
    <CompanyCount />
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <input
        className="form-control"
        placeholder="Search"
        style={{ maxWidth: '500px', fontSize: '1.5rem' }}
        value={searchText}
        onChange={onSearchChange}
      />
    </div>
    <div className='search-results'>
      {companies.length
        ? <table>
          <tbody>
            {companiesTable.map(co => {
              return <tr key={co[0]} onClick={() => companyClick(co[0], co)} className="search-result">
                {co.map((item, idx) => <td key={idx}>{item}</td>)}
              </tr>
            })}
          </tbody>
        </table>
        : <div className='search-msg'>No results match your search</div>}
    </div>
    <div style={{ justifyContent: 'center', display: 'flex' }}>
      <button
        onClick={onAdd}
        className="btn btn-primary"
        type="button">Add Company</button>
    </div>
  </div>
}

export default connect(state => state)(Companies)
