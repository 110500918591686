import { ObjectID } from 'mongodb'
import { Dictionary } from './index'

type LastNotified = {
  weeklyScoreUpdate?: Date;
  altSuggestion?: Date;
}

// don't export enums
enum PlaidAccountStatus {
  New = 'NEW',
  InitialUpdate = 'INITIAL_UPDATE',
  HistoricalUpdate = 'HISTORICAL_UPDATE',
  Unlinking = 'UNLINKING'
}

export interface PlaidAccount {
  institution: {
    name: string;
    institution_id: string;
  };
  error?: {
    display_message?: string;
    error_code?: string;
    error_message?: string;
    error_type?: string;
  };
  access_token?: string;
  item_id: string;
  status: PlaidAccountStatus;
}

export interface BaseUser {
  email?: string;
  values?: Dictionary<number>;
}

export interface User extends BaseUser {
  _id: ObjectID;
  acceptedTermsTimestamp: string;
  accounts: object[];
  appPermissions: object;
  birthYear: number;
  city: string;
  firstName: string;
  gender: string;
  genderDescription: string;
  hasAcceptedTerms?: boolean;
  hasOnboarded?: boolean;
  lastName: string;
  lastNotifiedDates: LastNotified;
  needsValueSelection?: boolean;
  password?: string;
  signupTimestamp: Date;
  state: string;
  tenant?: string;
  valuedImpactAreaMap?: Dictionary<boolean>
}

export type UserSignupPayload = BaseUser & {
  city?: string
  hasOnboarded?: boolean
  password?: string
  source?: string
  state?: string
  trackingId?: string
}

