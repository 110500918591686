import React, { useEffect, useState } from 'react'
import { Question } from '@ecountabl/lib'
import { FaTrash } from "react-icons/fa"
import FormField from './form-field'
import api from './api-client'

type Props = {
  question?: Question;
  onDone: (q: Question) => void;
}

const QuestionForm: React.FC<Props> = ({ question, onDone }: Props) => {  
  const [loading, setLoading] = useState(false)
  const [q, setQ] = useState(question || {
    label: '', options: [], prompt: '', allowWriteIn: false
  })

  const handleChange = (event) => {
    setQ({ ...q, [event.target.name]: event.target.value })
  }

  const handleSubmit = async (ev: React.FormEvent) => {
    ev.preventDefault()

    try {
      setLoading(true)
      const saved = await api.saveQuestion(q)
      setQ(saved)            
      setLoading(false)
      onDone(saved)
    } catch (ex) {
      setLoading(false)
      console.error('error saving question', ex)
    }
  }

  const removeOption = (opt: string) => {
    setQ({
      ...q,
      options: q.options.filter(o => o !== opt)
    })
  }

  return <form onSubmit={handleSubmit}>
    <FormField
      field="label"
      autoFocus={true}
      value={q.label}
      onChange={handleChange}
    />
    <FormField
      field="prompt"
      value={q.prompt}
      onChange={handleChange}
    />    
    <div className="form-check form-group">
      <input
        type="checkbox"
        className="form-check-input"
        id="allowWriteIn"
        name="allowWriteIn"
        checked={q.allowWriteIn}
        onChange={() => setQ({ ...q, allowWriteIn: !q.allowWriteIn })}
      />
      <label className="form-check-label" htmlFor="allowWriteIn">Allow write-in options?</label>
    </div>
    <div className="form-group">
      <label>Options</label>
      <div style={{ flexDirection: 'column' }}>
        <div className="list-group" style={{ width: '400px' }}>
          {q.options.map(o => <div className="list-group-item" key={o}>
            <FaTrash onClick={removeOption.bind(this, o)} style={{ float: 'right' }} />
            <span>{o}</span>
          </div>)}
        </div>
        <input
          className="form-control"
          placeholder="add option (hit enter)"
          onKeyPress={ev => {
            if (ev.key === "Enter") {
              ev.preventDefault()
              setQ({
                ...q,
                options: [...q.options, ev.currentTarget.value]
              })
              ev.currentTarget.value = ''
            }
          }}
        />
      </div>
    </div>
    <button className="btn btn-primary" type="submit">Submit</button>
  </form>
}

export default QuestionForm