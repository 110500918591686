import { Company, Dictionary, RatingsDictionary } from '@ecountabl/lib'

export interface CSRHubCompany {
  _id: string;
  name: string;
  csrhub_name: string;
  search_name: string;
  country: string;
  website: string;
  ticker: string;
  csrhub_id: number;
  ratings: Dictionary<string>;
  source: string;
  csrhub_date_updated: Date;
  csrhub_industries: string[],
  csrhub_name_variants: string[],
}

export const mergeCsrhub = (co: Company, csrhCo: CSRHubCompany): Company => {
  const wanted = [
    'csrhub_name_variants',
    'csrhub_name',
    'website',
    'ticker',
    'csrhub_id'
  ]

  return mergeWantedFields(co, csrhCo, wanted)
}

export interface JustCompany {
  _id: string;
  name: string;
  search_name: string;
  slug: string;
  ratings: RatingsDictionary;
  just_name: string;
  just_perm_id: string;
}

export const mergeJustCapital = (co: Company, jCo: JustCompany): Company => {
  const wanted = [
    'just_name',
    'just_perm_id',
  ]

  return mergeWantedFields(co, jCo, wanted)
}

export const mergeBCorp = (co: Company, bCo: Company): Company => {
  const wanted = [
    'bcorp_id',
    'bcorp_productsservices',
    'city',
    'state',
    'postalcode',
    'country'
  ]

  return mergeWantedFields(co, bCo, wanted)
}

export const mergeHRC2021 = (co: Company, hrcCo: Company): Company => {
  const wanted = [
    'hrc_co_name',
    'city',
    'state'
  ]  

  return mergeWantedFields(co, hrcCo, wanted)
}

export const mergeUserSubmitted = (co: Company, userCo: Company): Company => {
  const wanted = [
    'city',
    'state',
  ]

  return mergeWantedFields(co, userCo, wanted)
}

const mergeWantedFields = (co: Company, newCo: Company, fields: string[]): Company => {
  let mergedCo = JSON.parse(JSON.stringify(co))

  fields.forEach(w => {
    mergedCo[w] = newCo[w] || co[w]
  })

  mergedCo = mergeCompanyRatings(co, newCo)

  return mergedCo
}

const mergeCompanyRatings = (targetCo: Company, incomingCo: Company): Company => {
  if (!targetCo.ratings) {
    targetCo.ratings = {}
  }
  
  Object.keys(incomingCo.ratings).forEach(key => {
    targetCo.ratings[key] = incomingCo.ratings[key] || targetCo.ratings[key]
  })

  return targetCo
}

export const mergeCompanies = (newCo: Company, existingCo: Company): Company => {
  if (newCo.source === 'csrhub') {
    return mergeCsrhub(existingCo, newCo)
  } else if (newCo.source === 'JUST Capital') {
    return mergeJustCapital(existingCo, newCo)
  } else if (newCo.source === 'bcorp') {
    return mergeBCorp(existingCo, newCo)
  } else if (newCo.source === 'hrc_2021') {
    return mergeHRC2021(existingCo, newCo)
  } else if (newCo.source === 'user-submitted') {
    return mergeUserSubmitted(existingCo, newCo)
  } else {
    return mergeCompanyRatings(existingCo, newCo)
  }
}