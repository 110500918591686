import React from 'react'
import ApiKeys from './api-keys'

const Home = () => {
  return (
    <div className="user-home">
      <ApiKeys />
    </div>
  )
}

export default Home
