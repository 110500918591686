import React from 'react'
import { CompanyDataUpload } from '@ecountabl/lib'

type SummaryItemProps = {
  color: string;
  label: string;
  value: string | number;
}

const SummaryItem = ({ color, label, value}: SummaryItemProps) => (
  <div className="list-group-item">
    <div><b style={{ color }}>{value}</b>&nbsp; {label}</div>
    <div className="small"></div>
  </div>
)

type Props = {
  upload: CompanyDataUpload;
}

export type UploadProgress = {
  fuzzy: number;
  exact: number;
  search: number;
  reviewed: number;
  fuzzyReviewed: number;
}

export const calculateSummary = (upload: CompanyDataUpload): UploadProgress => {
  const summary = {
    fuzzy: 0,
    exact: 0,
    search: 0,

    reviewed: 0,
    fuzzyReviewed: 0
  }

  Object.values(upload.matches).forEach(m => {
    summary[m.type]++

    if (m.reviewed) {
      summary.reviewed++

      if (m.type === 'fuzzy') {
        summary.fuzzyReviewed++
      }
    }
  })

  return summary
}

const CompanyDataUploadSummary = ({ upload }: Props) => {
  const summary = calculateSummary(upload)

  return (
    <div>
      <div className="list-group">
        <div className="list-group-item">
          <div>
            <b style={{ color: 'green' }}>{summary.fuzzyReviewed}</b>
            &nbsp;/&nbsp;
            <b style={{ color: 'red' }}>{summary.fuzzy}</b>
            &nbsp;
            Fuzzy Matches Reviewed
          </div>
          <div className="small">All fuzzy matches must be reviewed</div>
        </div>
        <SummaryItem color='green' value={summary.exact} label="Exact matches on company name" />
        <SummaryItem color='orange' value={summary.search} label="Matches on Search" />
        <div className="list-group-item">
          <div>
            <b style={{ color: '#aaa' }}>{summary.reviewed}</b>
            &nbsp;/&nbsp;
            <b style={{ color: 'black' }}>{Object.keys(upload.matches).length}</b>
            &nbsp;
            Total Matches Reviewed
          </div>
          <div className="small">"Exact" and "Search" matches do not need to be reviewed</div>
        </div>
      </div>
    </div>
  )
}

export default CompanyDataUploadSummary