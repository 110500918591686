import React, { useEffect } from 'react'
import { connect } from 'react-redux'

const MappingManager = () => {
  useEffect(() => {
    console.log('TODO')
  }, [])

  return <div className="mapping-mgr">
    todo: implement
  </div>
}

export default connect(s => s)(MappingManager)
