import React, { useState, useEffect, useRef } from 'react'
import { v4 } from 'uuid'
import api, { LogoSearchParams, LogoSearchResponse } from './api-client'
import Spinner from './spinner'
import { useDispatch } from 'react-redux'
import notify from './notifications-helper'
import { FaSearch } from 'react-icons/fa'
import { REACT_APP_WS_ROOT, REACT_APP_CDN_ROOT } from './config'
import { logoUrl } from '@ecountabl/lib'

const wsRoot = () => {
  if (REACT_APP_WS_ROOT) {
    return REACT_APP_WS_ROOT
  }

  const protocol = window.location.protocol === 'https:' ? 'wss' : 'ws'
  return `${protocol}://${window.location.host}`
}

type ImageDimensions = {
  height: number
  width: number
}

type Props = {
  onChange: (newLogoPath: string, size?: ImageDimensions) => void
  onError?: (err: React.SyntheticEvent<HTMLImageElement, Event>) => void
  onSearchResponse?: (res: LogoSearchResponse) => void
  style?: object
  imgSrc: string
  isLoading?: boolean
  searchQuery?: LogoSearchParams
  enableSearch?: boolean
  targetSize?: { width: number, height: number }
  hidePrompt?: boolean
}

const imgSize = (file): Promise<ImageDimensions> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    //Read the contents of Image File.
    reader.readAsDataURL(file)
    reader.onload = function (e) {
      //Initiate the JavaScript Image object.
      const image = new Image()

      //Set the Base64 string return from FileReader as source.
      image.src = e.target.result

      //Validate the File Height and Width.
      image.onload = function () {
        const { height, width } = this
        return resolve({ height, width })
      }
    }
  })
}

const ImageUploader: React.FC<Props> = (props: Props) => {
  const { imgSrc, onChange, onSearchResponse } = props

  const [loading, setLoading] = useState(false)
  const [showSearchInput, setShowSearchInput] = useState(false)
  const [searchText, setSearchText] = useState('')
  const [searchMessage, setSearchMessage] = useState('')
  const [useDefault, setUseDefault] = useState(!imgSrc)
  const [id,] = useState(`logo-input-${v4()}`)
  const dispatch = useDispatch()
  const ws = useRef(null)
  const [wsOpen, setWsOpen] = useState(false)

  const logoChange = async (ev): Promise<void> => {
    const file = ev.target.files[0]
    setUseDefault(false)

    if (!file) { return }

    const unsupported = ['image/heic']
    if (unsupported.includes(file.type)) {
      alert(`The following file types are not yet supported: ${JSON.stringify(unsupported)}`)
    } else {
      try {
        let needsUpload = true
        setSearchMessage('')
        setShowSearchInput(false)
        const { height, width } = await imgSize(file)
        if (props.targetSize) {
          if (height !== props.targetSize.height || width !== props.targetSize.width) {
            getResizedImage(file)
            needsUpload = false
          }
        } else if (needsUpload) {
          setLoading(true)
          const signResponse = await api.signLogoUpload(file)
          await api.uploadFile(signResponse.signedRequest, file)
          setLoading(false)
          onChange(signResponse.fileName, { height, width })
        }
      } catch (error) {
        setLoading(false)
        if (props.onError) {
          props.onError(error.message)
        }
        dispatch(notify.error({ message: `Error uploading image: ${error.message}` }))
      }
    }
  }

  const getResizedImage = (file) => {
    setLoading(true)
    const data = new FormData()
    data.append('image', file)
    data.append('targetWidth', props.targetSize.width.toString())
    data.append('targetHeight', props.targetSize.height.toString())
    data.append('name', file.name)
    data.append('type', file.type)
    api.authenticatedFetch('/api/admin/files/resize-image', {
      method: 'POST',
      body: data,
      headers: {
        'Accept': 'application/json'
      }
    }).then(res => res.json())
      .then(({ imageUrl }) => onChange(imageUrl))
      .catch(ex => {
        dispatch(notify.error({ message: `error resizing image: ${ex.message}` }))
      }).finally(() => setLoading(false))
  }

  const searchForLogo = (params: LogoSearchParams) => {
    setLoading(true)
    setSearchMessage('Beginning search...')
    setUseDefault(false)
    ws.current.send(JSON.stringify(params))
  }

  useEffect(() => {
    ws.current = new WebSocket(wsRoot() + '/admin/logo')
    ws.current.onopen = () => setWsOpen(true)
    ws.current.onclose = () => setWsOpen(false)

    ws.current.onmessage = e => {
      try {
        const { state, data } = JSON.parse(e.data)
        switch (state) {
          case 'SEARCHING_FOR_DOMAIN': setSearchMessage(`searching logo api...`); break
          case 'SEARCHING_FOR_LOGO_BY_DOMAIN': setSearchMessage(`looking up logo for ${data.domain} (this can take 2-3m)...`); break
          case 'COPYING_LOGO': setSearchMessage('found logo, copying to s3...'); break
          case 'DONE':
            setSearchMessage('')
            onChange(data.logourl)
            onSearchResponse(data)
            setLoading(false)
            break
          case 'ERROR':
            if (data?.errorType == 'NAME_NOT_FOUND') {
              setSearchMessage('could not find logo by company name, try searching by domain instead')
              setShowSearchInput(true)
            } else if (data?.errorType == 'DOMAIN_NOT_FOUND') {
              setSearchMessage(`couldn't find logo via API`)
            } else {
              setSearchMessage(`error during search: ${JSON.stringify(data)}`)
            }

            setLoading(false)
            break
        }
      } catch (ex) {
        dispatch(notify.error({ message: ex.message }))
      }
    }

    return () => {
      ws.current.close()
    }
  }, [])

  useEffect(() => {
    setUseDefault(!imgSrc)
  }, [imgSrc])

  useEffect(() => {
    if (props.searchQuery && Object.keys(props.searchQuery).length && wsOpen) {
      searchForLogo(props.searchQuery)
    }
  }, [props.searchQuery, wsOpen])

  const src = logoUrl(imgSrc, REACT_APP_CDN_ROOT)
  const _loading = loading || props.isLoading

  return (
    <div className="logo-editor">
      <label htmlFor={id}>
        <div
          className="sizer"
          style={{ ...props.style }}
        >
          {_loading ? <Spinner /> : (
            useDefault
              ? <div className="img-default">?</div>
              : <img alt="logo"
                src={src}
                onError={(err) => {
                  console.log(`error loading image`, err.nativeEvent)
                  setUseDefault(true)
                  if (props.onError) {
                    props.onError(err)
                  }
                }}
              />
          )}
          <div className="overlay-controls">
            {searchMessage && <div className="search-message">{searchMessage}</div>}
            {(!_loading && props.enableSearch) && <div className="logo-search form-group">
              {showSearchInput && (
                <div className="input-group">
                  <input
                    className="form-control"
                    type="text"
                    placeholder="search logo by domain"
                    autoFocus
                    value={searchText}
                    onChange={ev => setSearchText(ev.target.value)}
                  />
                  <div className="input-group-append">
                    <button
                      type="button"
                      onClick={ev => {
                        ev.preventDefault()
                        searchForLogo({ domain: searchText })
                      }}
                      className="btn btn-primary"
                    >
                      Search
                    </button>
                  </div>
                </div>
              )}
              <button
                type="button"
                onClick={() => setShowSearchInput(!showSearchInput)}
                className="btn btn-secondary search-toggle"
                style={{ opacity: 0.2, marginLeft: '0.5em' }}
              >
                <FaSearch />
              </button>
            </div>}
          </div>
        </div>
        {props.hidePrompt ? null : <p style={{ marginTop: "0.5em", color: "#ccc" }}>
          click image to edit {props.targetSize ? `(target: ${props.targetSize.width}w x ${props.targetSize.height}h)` : null}
        </p>}
      </label>
      <input id={id} accept="image/*" type="file" onChange={ev => logoChange(ev)} style={{ display: 'none' }} />
    </div>
  )
}

export default ImageUploader

