import React, { useState, useRef, useEffect } from 'react'
import { ITag } from '@ecountabl/lib'
import api from './api-client'

type Props = {
  tags: string[];
  options: string[];
  onAddTag: (tag: string) => void;
  onRemoveTag: (tag: string) => void;
}

export const TagInput: React.FC<Props> = ({ tags, options, onAddTag, onRemoveTag }: Props) => {
  const [query, setQuery] = useState('')
  const [showOptions, setShowOptions] = useState(false)

  const filteredOptions = options.filter(o => (
    !tags?.includes(o) &&
    o.toLowerCase().includes(query.toLowerCase())
  ))

  return (
    <div className="tag-input">
      <div className="tags">
        {tags?.length === 0 ? <span
          className="small"
          style={{ color: 'red' }}>
          No tags; all companies should have at least 1 tag.
        </span> : null}
        {tags?.map(t => {
          const isNew = !options.includes(t)
          return (
            <div key={t} className={`tag ${isNew ? 'new-tag' : ''}`}>
              {isNew && <span className="new-label">new tag</span>}
              <span className="tag-text">{t}</span>
              <i onClick={() => onRemoveTag(t)} className='fa fa-close' />
            </div>
          )
        })}
      </div>
      <div className="input-wrap">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={query}
            onFocus={ev => {
              setShowOptions(true)
            }}
            onBlur={ev => {
              if (!ev.relatedTarget?.className?.split(' ').includes('tag-option')) {
                setTimeout(() => setShowOptions(false), 100)
              } else {
                // retain focus so this will fire again when clicked outside
                ev.currentTarget.focus()
              }
            }}
            onChange={ev => {
              setQuery(ev.target.value)
            }}
            onKeyDown={ev => {
              switch (ev.key) {
                case 'ArrowDown':
                  // TODO
                  break;
                case 'ArrowUp':
                  // TODO
                  break;
                case 'Enter':
                  ev.preventDefault()
                  onAddTag(query)
                  setQuery('')
                  break;
              }
            }}
            placeholder="search for tags (enter to add new)"
          />
        </div>
        {showOptions && (
          <div className="tag-options list-group">
            {filteredOptions.map((o, i) => (
              <a href="#" key={i}
                className="list-group-item tag-option"
                onClick={() => {
                  onAddTag(o)
                  setQuery('')
                }}
              >{o}</a>
            ))}
          </div>
        )}
      </div>
    </div>
  )

}

export default TagInput