import React from 'react'
import { Company } from '@ecountabl/lib'
import { FaExclamationTriangle } from 'react-icons/fa'

export type MergeNotificationProps = {
  merge?: boolean;
  mergeSourceCo: Company;
  mergeTargetCo: Company;
  mergeSourceText: string;
}

export const MergeNotification = ({ merge, mergeSourceCo, mergeTargetCo, mergeSourceText }: MergeNotificationProps) => (
  <div style={{ flex: 1, justifyContent: 'center', display: 'flex' }}>
    {merge && <div className="card text-white bg-info mb-3" style={{ maxWidth: '500px' }}>
      <div className="card-body">
        <h5 className="card-title"><FaExclamationTriangle /> Saving this page will merge companies together</h5>
        <p className="card-text">
          If you save, it will merge '<b>{mergeSourceCo.name}</b>' from '<b>{mergeSourceText}</b>' INTO existing
          company '<b>{mergeTargetCo.name}</b>'. Any modifications you make will be included as well.
          When complete, it will delete '{mergeSourceCo.name}' from {mergeSourceText}.
        </p>
      </div>
    </div>}
  </div>
)

export default MergeNotification