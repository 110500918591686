import { configureStore } from '@reduxjs/toolkit'
import user from './slices/user'
import { reducer as notifications } from 'react-notification-system-redux'

export default configureStore({
  reducer: {
    user,
    notifications
  }
})
