import React, { Component } from 'react';
import api from './api-client'
import Performance from './performance'
import Footer from './footer.jsx'
import Brands from './brands'
import EcountablNav from './ecountabl-nav'
import GooglePlayButton from './img/google-play-badge.png'
import AppleStoreButton from './img/apple-store-badge.png'
import { FaChevronDown, FaChevronUp } from "react-icons/fa"
import { logoUrl } from '@ecountabl/lib'
import { REACT_APP_CDN_ROOT } from './config'

const FoldOut = (props) => {
  const Icon = (props.toggled ? FaChevronUp : FaChevronDown);
  const display = (props.toggled ? 'block' : 'none')

  return (
    <div className="fold-out">
      <div className="fold-out-toggle"
        onClick={props.toggleFold}>
        {props.title}
        <Icon className="icon" />
      </div>
      <div style={{display}}>
        {props.children}
      </div>
    </div>
  );
}

class CompanyLandingPage extends Component {
  state = {
    loading: true,
    loadingIndicators: true,
    company: undefined,
    showPerformance: true,
    new: false
  }

  componentDidMount() {
    api.getCompanyBySlug(this.props.match.params.slug).then(({ company }) => {
      this.setState({
        loading: false,
        company
      })
    }).catch(ex => {
      this.setState({ loading: false, error: ex })
    })

    api.getIndicators().then(indicators => {
      this.setState({
        loadingIndicators: false,
        indicators
      })
    })
  }

  render() {
    const { error, loading, company, loadingIndicators } = this.state

    if ( loading || loadingIndicators ) {
      return <div>loading</div>
    } 

    if ( error ) {
      return <div>
        Error: {JSON.stringify(error)}
      </div>
    }

    const { name, logourl, logoref, segment, brands, alternatives, sub_segment, corporate_parent } = company

    const showParent = corporate_parent && corporate_parent !== name
    const src = logoUrl(company, REACT_APP_CDN_ROOT)

    return <div>
      <EcountablNav />
      <div className="company-landing-page">
      <img alt="logo" 
        src={src} 
        style={{
          alignSelf: 'center',
          display: 'flex',
          maxHeight: '250px'
        }}
      />
      <div className="brand-info">
        <div>
          <span>NAME:&nbsp;</span><span>{name}</span>
        </div>
        <div>
          <span>INDUSTRY:&nbsp;</span><span>{segment}: {sub_segment}</span>
        </div>
        {showParent && <div>
          <span>PARENT:&nbsp;</span><span>{corporate_parent}</span>
        </div>}
      </div>
      <Brands brands={company.brands} navigation={this.props.navigation} />
      <FoldOut title={`Alternatives`}
        toggled={true}
        toggleFold={() => { }}>
          {/* <div className="store-badges">
            <p>Sign up for the beta to see how {name} compares to competitors!</p>
            <a className="beta-signup-link" href="https://ecountabl.com/home/#signup">Register for Beta</a>
          </div> */}
        <div className="store-badges">
          <p>Get the ecountabl app to see how <b>{name}</b> compares to competitors!</p>
          {/* <img src={GooglePlayButton} /> */}
          <a href="https://apps.apple.com/us/app/ecountabl-value-your-spending/id1493904001">
            <img src={AppleStoreButton} style={{ height: "55px", margin: "40px" }} />
          </a>
        </div>
      </FoldOut>
      <FoldOut title={`Performance`}
        toggled={this.state.showPerformance}
        toggleFold={() => {this.setState({ showPerformance: !this.state.showPerformance})}}>
        <Performance
          indicators={this.state.indicators}
          company={company}
        />
      </FoldOut>
      <p>ecountabl receives brand performance data from third party data sources. Some brand performance data may be derived from parent company.</p>
    </div>
    <Footer />
    </div>
  }
}

export default CompanyLandingPage