import React, { useState, useEffect } from 'react'
import { Indicator } from '@ecountabl/lib'
import { useDispatch } from 'react-redux'
import notify from '../notifications-helper'
import Spinner from '../spinner'
import api from '../api-client'
import IndicatorFormSelect from '../indicator-form-select'

export const Duplicator = () => {
  const [loading, setLoading] = useState(true)
  const [indicators, setIndicators] = useState([])
  const [fromInd, setFromInd] = useState<Indicator>()
  const [toInd, setToInd] = useState<Indicator>()

  const dispatch = useDispatch()

  useEffect(() => {
    api.getAdminIndicators().then(res => {
      setIndicators(res.indicators)
      setLoading(false)
    }).catch(ex => dispatch(notify.error({ message: ex.message })))
      .finally(() => setLoading(false))
  }, [])

  const handleChange = (abb: string, dir: string) => {
    const ind = indicators.find(i => i.abbreviation === abb)

    // note this still works even if setting to undefined
    if (dir === 'from') {
      setFromInd(ind)
    } else {
      setToInd(ind)
    }
  }

  const scalesMustMatch = fromInd && toInd && fromInd.scale !== toInd.scale

  return <div>
    <h3>Duplicate Indicator Data</h3>
    <div className="alert alert-info">
      <p>
        Use this form to copy data from one indicator to another. This is most likely to be used if you want data from
        one indicator to be reflected in multiple values.
      </p>
    </div>
    {loading ? <Spinner /> : (
      <div>
        <div>
          <div className="form-group">
            <label>From</label>
            <IndicatorFormSelect
              onChange={ev => handleChange(ev.target.value, 'from')}
              value={fromInd?.abbreviation}
              indicators={indicators}
            />
          </div>
          <div className="form-group">
            <label>To</label>
            <IndicatorFormSelect
              onChange={ev => handleChange(ev.target.value, 'to')}
              value={toInd?.abbreviation}
              indicators={indicators}              
            />
          </div>
        </div>
        <div className="data-form-buttons">
          <button
            className="btn btn-warning"
            type="button"
            disabled={scalesMustMatch}
            onClick={() => {
              setLoading(true)
              api.duplicateIndicatorData(fromInd.abbreviation, toInd.abbreviation).then(res => {
                setLoading(false)
                dispatch(
                  notify.success({
                    message: `copied indicator data for ${res.updated} companies`
                  })
                )
              }).catch(ex => dispatch(notify.error({ message: ex.message })))
            }}>Duplicate Indicator Data</button>
          {scalesMustMatch ? (
            <div className="error" style={{ margin: '1em' }}>
              <i className="fa fa-warning" />&nbsp;Indicator scales must match
            </div>
          ) : ''}
        </div>
      </div>
    )}
  </div>
}

export default Duplicator
