import React, { useState, useEffect, ChangeEventHandler } from 'react'
import { Indicator } from '@ecountabl/lib'


export const labelFormat = (i: Indicator) => {
  return `${i.indicator_family}  ${i.data_year} [${i.scale}]  ${i.label} ${i.abbreviation}`
}

type Props = {
  indicators: Indicator[]
  value?: string
  onChange: ChangeEventHandler<HTMLSelectElement>
}

export const sortIndForDisplay = (a: Indicator, b: Indicator) => {
  return a.indicator_family?.localeCompare(b.indicator_family) ||
    b.data_year?.localeCompare(a.data_year) ||
    a.label.localeCompare(b.label)  
}

export const IndicatorFormSelect = ({ indicators, value, onChange }: Props) => {
  const [opts, setOpts] = useState([])

  useEffect(() => {
    const sorted = indicators.sort(sortIndForDisplay)
    setOpts(sorted)
  }, [indicators])

  return (
    <select
      className="form-control"
      value={value}
      onChange={onChange}
    >
      <option value=''></option>
      {opts.map((i, idx) => (
        <option
          key={idx}
          value={i.abbreviation}>{labelFormat(i)}</option>
      ))}
    </select>
  )
}

export default IndicatorFormSelect