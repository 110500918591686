import React, { useEffect, useState } from 'react'
import api from './api-client'
import Spinner from './spinner'
import { useDispatch } from 'react-redux'
import ImageUploader from './image-uploader'
import { useHistory } from 'react-router-dom'
import notify from './notifications-helper'
import { Datasource, logoUrl } from '@ecountabl/lib'
import { REACT_APP_CDN_ROOT } from './config'

interface Props {
  match: {
    params: {
      id: string;
    };
  };
}

const DatasourceEditor: React.FC<Props> = ({ match }: Props) => {
  const { id } = match.params
  const [datasource, setDatasource] = useState<Partial<Datasource>>({})
  const [loading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    if (id === 'new') {
      return
    }

    setLoading(true)
    api.getDataSource(id).then(ds => {
      setDatasource(ds)
    }).catch(ex => {
      dispatch(notify.error({ message: `Error loading datasource: ${ex.message}` }))
    }).finally(() => setLoading(false))
  }, [setDatasource, dispatch, id])

  const handleSubmit = (ev) => {
    setSubmitting(true)
    ev.preventDefault()
    if (id === 'new') {
      api.createDatasource(datasource as Datasource).then(ds => {
        dispatch(notify.success({ message: "Datasource saved successfully" }))
        setTimeout(() => history.push('/datasources'))
      }).catch(ex => {
        dispatch(notify.error({ message: `Error saving Datasource: ${ex.message}` }))
      }).finally(() => setSubmitting(false))
    } else {
      api.putDatasource(datasource as Datasource).then(ds => {
        dispatch(notify.success({ message: "Datasource saved successfully" }))
        setTimeout(() => history.push('/datasources'))
      }).catch(err => {
        dispatch(notify.error({ message: `Error saving Datasource: ${err.message}` }))
      }).finally(() => setSubmitting(false))
    }
  }

  const handleChange = (event) => {
    setDatasource({ ...datasource, [event.target.name]: event.target.value })
  }

  const shareLogo = datasource?.shareLogo || (
    datasource?.img
      ? logoUrl(`indicators/${datasource?.img.replace('img/', '')}`, REACT_APP_CDN_ROOT)
      : ''
  )

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <Spinner variant='block' />
        </div>
      ) : (
        <div className="container mt-5">
          <h1 className="mb-0">Datasource: {datasource._id}</h1>
          <div className="mt-5">
            {/* The form to edit properties */}
            <form onSubmit={handleSubmit}>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Label</label>
                <input
                  value={datasource.label || ''}
                  name="label"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Type of Information</label>
                <input
                  value={datasource.type_of_information || ''}
                  name="type_of_information"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>Website</label>
                <input
                  value={datasource.website || ''}
                  name="website"
                  onChange={handleChange}
                  type="text"
                  className="form-control"
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <label>App Image</label>
                <ImageUploader
                  onChange={(s3ImgUrl: string): void => setDatasource({ ...datasource, s3ImgUrl })}
                  imgSrc={datasource.s3ImgUrl || ''}
                  style={{ maxHeight: '200px' }}
                />
              </div>
              <div className="form-group d-flex flex-lg-row flex-column">
                <div style={{ width: "200px" }}>
                  <label>Share Image</label>
                  <p className="small" style={{ fontStyle: 'italic', textAlign: 'right', marginRight: '1em' }}>
                    Shown on share image, must look good against a white background, will be constrained to 100 x 70
                  </p>
                </div>
                <div>
                  <ImageUploader
                    onChange={(shareLogo: string): void => setDatasource({ ...datasource, shareLogo })}
                    imgSrc={shareLogo || ''}
                    style={{ maxHeight: '100px', width: '70px' }}
                  />
                </div>
              </div>
              <div className="form-group d-flex flex-lg-row flex-column pb-3">
                <label>Description</label>
                <textarea
                  value={datasource.description || ''}
                  name="description"
                  onChange={handleChange}
                  className="form-control"
                  rows={10}
                />
              </div>
              <div className="pt-4 border-top mb-5">
                <button type="submit" className="btn btn-primary ">
                  {submitting ? (
                    <div className="spinner-border spinner-border-sm" />
                  ) : (
                    <div>Save</div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </>
  )
}

export default DatasourceEditor
