import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Question } from '@ecountabl/lib'
import QuestionForm from './question-form'
import notify from './notifications-helper'

import api from './api-client'

type Props = {
  q: Question
}

const QuestionCard = ({ q }: Props) => {
  const [expand, setExpand] = useState(false)

  return <div className="question card">
    <div className="card-body">
      <span className="card-title" onClick={() => setExpand(!expand)} style={{ cursor: 'pointer' }}>
        <h5>{q.label}</h5>
      </span>
      {expand && <>
        <h6>Prompt</h6>
        <p>{q.prompt}</p>
        <h6>Options</h6>
        <div className="list-group" style={{ width: '400px' }}>
          {q.options.map(o => {
            return <span key={o} className="list-group-item">{o}</span>
          })}
        </div>
      </>
      }
    </div>
  </div>
}

const Questions = () => {
  const [isAdding, setIsAdding] = useState(false)
  const [loading, setLoading] = useState(true)
  const [questions, setQuestions] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    api.getQuestions().then(qs => {
      setQuestions(qs)
      setLoading(false)
    }).catch(ex => {
      setLoading(false)
      dispatch(notify.error({ message: `error loading questions: ${ex.message}` }))
    })
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <div
            className="spinner-border"
            style={{ width: '3rem', height: '3rem' }}
          ></div>
        </div>
      ) : (
          <div className="container mt-5">
            <h1>Questions</h1>
            <p>Note that for data quality reasons, questions cannot be modified once they are saved.</p>
            <div className="questions">
              {questions.map(q => {
                return <QuestionCard q={q} key={q._id} />
              })}
            </div>
            {isAdding ? (
              <QuestionForm onDone={q => {
                setIsAdding(false)
                setQuestions([...questions, q])
              }} />
            ) : (
                <button
                  type="submit"
                  className="btn btn-primary"
                  onClick={() => setIsAdding(true)}>
                  Add Question
                </button>
              )}
          </div>
        )
      }
    </>
  )
}

export default Questions
