import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import notify from './notifications-helper'
import Spinner from './spinner'

export const UpdateUserScores = () => {
  const [saving, setSaving] = useState(false)

  const dispatch = useDispatch()

  return (
    <button
      type="button"
      className="btn btn-danger"
      disabled={saving}
      onClick={ev => {
        const proceed = window.confirm([
          'This will update company scores for all users! 😬 ',
          'However, it will NOT back-date scores, it will only change scores going forward.',
          'Before doing this, make sure all ratings have been trickled. ',
          'Are you sure you want to proceed?'
        ].join(''))

        if (proceed) {
          setSaving(true)
          api.updateAllUserScores().then(res => {
            dispatch(notify.success({
              message: `Server received the request and is processing the background, check logs for progress`
            }))
          }).finally(() => {
            setSaving(false)
          })
        }
      }}>Update ALL User Scores {saving && <Spinner />}</button>
  )
}

export default UpdateUserScores
