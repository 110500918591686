import React, { useState, useRef } from 'react'
import { Indicator, Dictionary, SCORES_INDEX } from '@ecountabl/lib'
import { sortIndForDisplay } from '../indicator-form-select'

type SimpleSupercederProps = {
  ind: Indicator
  indsByAbb: Dictionary<Indicator>
}

type SupercededByProps = SimpleSupercederProps & {
  onChange: (ev: React.SyntheticEvent) => void
}

const getSortedSuperceders = ({ ind, indsByAbb }: SimpleSupercederProps): Indicator[] => {
  let indicators = Object.values(indsByAbb)
  indicators = indicators.filter(i => i.slug === ind.slug && i.abbreviation !== ind.abbreviation)
  indicators.sort(sortIndForDisplay)
  return indicators
}

export const SupercededBy = ({ ind, indsByAbb, onChange }: SupercededByProps): React.ReactElement => {
  const [open, setOpen] = useState(false)

  const sortedIndicators = useRef<Indicator[]>()

  const doOpen = () => {
    sortedIndicators.current = getSortedSuperceders({ ind, indsByAbb })
    setOpen(true)
  }

  const doChange = ev => {
    onChange(ev)
    setOpen(false)
  }

  if (!open && !ind.superceded_by) {
    return <span onClick={doOpen}>none</span>
  }

  if (open) {
    return <select
      className='form-control'
      style={{ width: '120px' }}
      onChange={doChange}
      onBlur={() => setOpen(false)}
      value={ind.superceded_by}
    >
      <option value=''>(none)</option>
      {sortedIndicators.current.map(_ind => {
        return <option key={_ind.abbreviation} value={_ind.abbreviation}>
          {_ind.indicator_family} {_ind.data_year} - {_ind.label}
        </option>
      })}
    </select>
  }

  if (ind.superceded_by) {
    const _ind = indsByAbb[ind.superceded_by]
    if (!_ind) {
      return <div onClick={doOpen}>archived</div>
    }

    const { label, indicator_family } = _ind
    return <div style={{ fontSize: '0.8em' }} onClick={doOpen}>{indicator_family} - {label}</div>
  }
}

export default SupercededBy
