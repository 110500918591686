import React from 'react'
import { connect } from 'react-redux'
import { Route, Switch, Redirect } from 'react-router-dom'
import Nav from './nav.jsx'
import Customers from './customers'
import CustomerForm from './customer-form'
import Companies from './companies'
import Notifications from 'react-notification-system-redux'
import { style } from './notifications-helper'
import CompanyEditor from './company-editor/'
import NotFound from './not-found.jsx'
import ModerateCompanies from './moderate-companies'
import ImpactAreas from './impact-areas'
import Values from './values'
import DataPage from './data-page'
import DealsPage from './deals-page'
import { CompanyDataUploader } from './company-data-upload'
import Datasources from './datasources'
import DatasourceEditor from './datasource-editor'
import Questions from './questions'
import ValueForm from './value-form'
import Transactions from './transactions'
import Indicators from './indicators'
import Segments from './segments'
import MappingManager from './mapping-manager'

const Page = (props) => {
    return (
      <div style={{ marginBottom: '10em' }}>
        <Nav />
        <Notifications notifications={props.notifications} style={style} />
        <Switch>
          <Route
            path="/companies/merge/:id"
            render={(routeProps) => {
              return (
                <CompanyEditor
                  key={routeProps.match.params.id}
                  merge={true}
                  {...routeProps}
                />
              )
            }}
          />
          <Route
            path="/companies/:id"
            render={(routeProps) => {
              return (
                <CompanyEditor key={routeProps.match.params.id} {...routeProps} />
              )
            }}
          />
          <Route path="/companies" component={Companies} />
          <Route path="/customers/:id" component={CustomerForm} />
          <Route path="/customers" component={Customers} />
          <Route
            path="/moderate/:id"
            render={(routeProps) => {
              return (
                <CompanyEditor
                  key={routeProps.match.params.id}
                  moderate={true}
                  {...routeProps}
                />
              )
            }}
          />
          <Route path="/moderate" component={ModerateCompanies} />
          <Route path="/values" component={Values} />
          <Route path="/value/:id" component={ValueForm} />
          <Route path="/data/upload/:id" component={CompanyDataUploader} />
          <Route path="/data" component={DataPage} />
          <Route path="/indicators" component={Indicators} />
          <Route path="/impactareas" component={ImpactAreas} />
          <Route path="/datasources" component={Datasources} />
          <Route path="/datasource/:id" component={DatasourceEditor} />
          <Route path="/transactions" component={Transactions} />
          <Route path="/mappings" component={MappingManager} />
          <Route path="/deals" component={DealsPage} />
          <Route path="/questions" component={Questions} />
          <Route path="/segments" component={Segments} />
          <Route component={NotFound} />
        </Switch>
      </div>
    )
}

export default connect((state) => state)(Page)
