import React, { useEffect, useRef } from 'react'
import { Company } from '@ecountabl/lib'
import { GetIndicatorsResponse } from '@ecountabl/api-client'
import InputField from './input-field'

type Props = {
  indicatorGroups: GetIndicatorsResponse
  company: Company
  onRatingsChange: (abb: string, { target: { value } }) => void
}

const Ratings: React.FC<Props> = ({ indicatorGroups, company, onRatingsChange }) => {
  const sortedCategories = useRef<string[]>()
    
  sortedCategories.current = Object.keys(indicatorGroups.byCategory).sort((a, b) => a.localeCompare(b))

  useEffect(() => {
    sortedCategories.current = Object.keys(indicatorGroups.byCategory).sort((a, b) => a.localeCompare(b))
  }, [indicatorGroups])

  return (
    <div className="rating-inputs">
      {sortedCategories.current.map(cat => {
        const abbs = indicatorGroups.byCategory[cat].sort()
        return <div key={cat} className="rating-group card bg-light">
          <h5 className="card-title">{cat || "Un-mapped Ratings"}</h5>
          <div className="inputs">
            {abbs.map(abbreviation => {
              const { label, included_in_scoring, scale, attachment } = indicatorGroups.byAbbreviation[abbreviation]
              if (attachment === 'bank') {
                // don't show bank-side indicators in the admin
                return null
              }

              const field = <div>
                <span className={included_in_scoring ? "included" : ""}>
                  {label}
                  {included_in_scoring ? (
                    <span title="included in scoring">
                      &nbsp; <i className="fa fa-check-circle" />
                    </span>
                  ) : null}
                </span>
                <span className="scale">{scale}</span>
                <span className="abb">{abbreviation}</span>
              </div>
              return <InputField
                key={abbreviation}
                field={field}
                onChange={ev => onRatingsChange(abbreviation, ev)}
                value={company.ratings[abbreviation]}
              />
            }
            )}
          </div>
        </div>
      })}
    </div>
  )
}

export default Ratings
