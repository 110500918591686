import { useState, useRef, useEffect } from 'react'
import { SelectedIcon } from '@ecountabl/lib'

import AntDesign from 'react-native-vector-icons/dist/AntDesign'
import Entypo from 'react-native-vector-icons/dist/Entypo'
import EvilIcons from 'react-native-vector-icons/dist/EvilIcons'
import Feather from 'react-native-vector-icons/dist/Feather'
import FontAwesome from 'react-native-vector-icons/dist/FontAwesome'
import Fontisto from 'react-native-vector-icons/dist/Fontisto'
import Foundation from 'react-native-vector-icons/dist/Foundation'
import Ionicons from 'react-native-vector-icons/dist/Ionicons'
import MaterialCommunityIcons from 'react-native-vector-icons/dist/MaterialCommunityIcons'
import MaterialIcons from 'react-native-vector-icons/dist/MaterialIcons'
import Octicons from 'react-native-vector-icons/dist/Octicons'
import SimpleLineIcons from 'react-native-vector-icons/dist/SimpleLineIcons'
import Zocial from 'react-native-vector-icons/dist/Zocial'

import AntDesignTTF from 'react-native-vector-icons/Fonts/AntDesign.ttf'
import EntypoTTF from 'react-native-vector-icons/Fonts/Entypo.ttf'
import EvilIconsTTF from 'react-native-vector-icons/Fonts/EvilIcons.ttf'
import FeatherTTF from 'react-native-vector-icons/Fonts/Feather.ttf'
import FontAwesomeTTF from 'react-native-vector-icons/Fonts/FontAwesome.ttf'
import FontistoTTF from 'react-native-vector-icons/Fonts/Fontisto.ttf'
import FoundationTTF from 'react-native-vector-icons/Fonts/Foundation.ttf'
import IoniconsTTF from 'react-native-vector-icons/Fonts/Ionicons.ttf'
import MaterialCommunityIconsTTF from 'react-native-vector-icons/Fonts/MaterialCommunityIcons.ttf'
import MaterialIconsTTF from 'react-native-vector-icons/Fonts/MaterialIcons.ttf'
import OcticonsTTF from 'react-native-vector-icons/Fonts/Octicons.ttf'
import SimpleLineIconsTTF from 'react-native-vector-icons/Fonts/SimpleLineIcons.ttf'
import ZocialTTF from 'react-native-vector-icons/Fonts/Zocial.ttf'

import Spinner from './spinner'

const fonts = {
  AntDesign: { icon: AntDesign, ttf: AntDesignTTF },
  Entypo: { icon: Entypo, ttf: EntypoTTF },
  EvilIcons: { icon: EvilIcons, ttf: EvilIconsTTF },
  Feather: { icon: Feather, ttf: FeatherTTF },
  FontAwesome: { icon: FontAwesome, ttf: FontAwesomeTTF },
  Fontisto: { icon: Fontisto, ttf: FontistoTTF },
  Foundation: { icon: Foundation, ttf: FoundationTTF },
  Ionicons: { icon: Ionicons, ttf: IoniconsTTF },
  MaterialCommunityIcons: { icon: MaterialCommunityIcons, ttf: MaterialCommunityIconsTTF },
  MaterialIcons: { icon: MaterialIcons, ttf: MaterialIconsTTF },
  Octicons: { icon: Octicons, ttf: OcticonsTTF },
  SimpleLineIcons: { icon: SimpleLineIcons, ttf: SimpleLineIconsTTF },
  Zocial: { icon: Zocial, ttf: ZocialTTF },
}

const icons = []

const initFonts = () => {
  const fontStyles = Object.entries(fonts).map(([family, font]) => {
    icons.push(
      ...Object.keys(font.icon.getRawGlyphMap())
        .map(name => ({
          icon: font.icon,
          name,
          family
        }))
    )

    return `@font-face {
      src: url(${font.ttf});
      font-family: ${family};
    }`
  }).join('\n')

  const style = document.createElement('style')
  style.type = 'text/css'
  if (style.styleSheet) {
    style.styleSheet.cssText = fontStyles
  } else {
    style.appendChild(document.createTextNode(fontStyles))
  }

  // Inject stylesheet
  document.head.appendChild(style);
}

initFonts()

type Props = {
  value?: SelectedIcon
  onChange: (i: SelectedIcon) => void
}

const IconChooser = ({ value, onChange }: Props) => {
  const [query, setQuery] = useState('')
  const [delay, setDelay] = useState(false)
  const [matches, setMatches] = useState(icons)
  const [searching, setSearching] = useState(false)
  const [showAll, setShowAll] = useState(false)
  const timeout = useRef(null)
  const popup = useRef()

  useEffect(() => {
    const close = () => {
      setSearching(false)
      setShowAll(false)
      setQuery('')
      if (timeout.current) {
        clearTimeout(timeout.current)
        timeout.current = null
      }
    }

    const listener = ev => {
      if (!popup.current?.contains(ev.target)) {
        close()
      }
    }

    window.document.addEventListener('click', listener)

    return () => {      
      window.document.removeEventListener('click', listener)
      close()
    }
  }, [])

  const onSearchChange = (ev) => {    
    setQuery(ev.target.value)
    setDelay(true)
    setShowAll(false)

    // add a 300ms delay to avoid UI lag
    if (timeout.current) {
      clearTimeout(timeout.current)
      timeout.current = null
    }

    const q = ev.target.value
    timeout.current = setTimeout(() => {
      if (q === '') {
        setMatches(icons)
      } else {
        setMatches(icons.filter(i => i.name.includes(q.toLowerCase())))
        setDelay(false)
      }
    }, 200)
  }

  const Icon = fonts[value?.family]?.icon

  const onSelect = (ev) => {
    const [family, name] = ev.currentTarget.getAttribute('title').split(' ')
    onChange({ family, name })
    setQuery('')
    setMatches(icons)
    setSearching(false)
  }

  return <div className="icon-chooser">
    <div className="trigger" onClick={ev => { setSearching(!searching); ev.stopPropagation() }}>      
      {value?.family ? <Icon name={value.name} size={40} /> : <span>search for icon</span>}
    </div>
    {searching && (
      <div className="popup" ref={popup}>
        <div className="form-group">
          <input
            autoFocus
            className="form-control"
            onChange={onSearchChange}
            value={query}
            placeholder="search icons"
          />
          <button
            type="button"
            className="btn btn-secondary"
            onClick={() => setShowAll(true)}
          >Show All</button>
        </div>
        {showAll && <p style={{ color: 'red', fontSize: '0.8em', fontWeight: 'bold' }}>
          Warning: showing all will make page slower while displayed
        </p>}
        {(query.length || showAll) && <div className="icon-options">
          {delay ? <Spinner /> : matches.map((icon, i) => (
            <div key={i}
              className="icon-opt"
              title={`${icon.family} ${icon.name}`}
              onClick={onSelect}
            >
              <icon.icon name={icon.name} size={20} />
              <span className="icon-label">{icon.name}</span>
            </div>
          ))}
          {!delay && matches.length === 0 && <div className="alert alert-secondary">no matches</div>}
        </div>}
      </div>
    )}
  </div>
}

export default IconChooser



