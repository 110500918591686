import { Dictionary } from './index'

export type CompanyDataUploadRawInfo = {
  headers: string[];
  data: any[];
}

export interface CompanyUploadMatch {
  reviewed?: boolean;
  type: MatchType;
  name?: string;
  isFuzzy?: boolean;
  noMatches?: boolean;
  searchName?: string;
  parent?: string;
  match_score?: string;
  match_gap?: string;
  match_pct?: string;
  data?: Record<string, any>;
}

enum MatchType {
  Fuzzy = 'fuzzy',
  Exact = 'exact',
  Search = 'search',
  None = 'none'
}

enum CompanyUploadStatus {
  New = "new",
  DoingFuzzySearch = "doing_fuzzy_search",
  WorkInProgress = "wip",
  Processed = "processed"
}

export interface CompanyDataUpload {
  _id?: string;
  name?: string;
  csvFileUrl: URL;
  status: CompanyUploadStatus;
  coLookupHeader?: string;
  fuzzySearchStatus?: string;
  matches?: Dictionary<CompanyUploadMatch>;
  headerMap: Dictionary<string>;
  bulkOpResult?: { result: any };
}

