import { ObjectId } from 'mongodb'
import { Company } from './index'

export enum CampaignPlatform {
  Impact = 'impact'
}

enum DealResponseType {
  Company = "company",
  Tag = "tag"
}

export type CachedDealPointer = {
  type: DealResponseType
  label: string
  ref: string | ObjectId
  _id: string | ObjectId
}

export interface Campaign {
  AdvertiserId: string
  AdvertiserName: string
  AdvertiserUrl: string
  CampaignId: string
  CampaignName: string
  CampaignUrl: string
  CampaignDescription: string
  ShippingRegions: string[]
  CampaignLogoUri: string
  PublicTermsUri: string
  ContractStatus: string
  ContractUri: string
  TrackingLink: string
  AllowsDeeplinking: string
  DeeplinkDomains: string[]
  Uri: string

  // ecountabl fields
  _id?: string
  platform?: CampaignPlatform
  active?: boolean
  mapsToCompanyId?: ObjectId | string
  company: Company
  companyScore: number
  deals?: Deal[]
  deal?: Deal
  ad?: Ad
}

enum DealState {
  Active = "ACTIVE",
  Expired = "EXPIRED"
  // TODO: incomplete?
}

enum DealType {
  GeneralSale = "GENERAL_SALE"  
  // TODO: incomplete
}

enum DealScope {
  EntireStore = "ENTIRE_STORE"
  // TODO: incomplete
}

enum DealDiscountType {
  PercentMaximum = "PERCENT_MAXIMUM"
  // TODO: incomplete
}

export interface Deal {
  // There are lots more fields in the impact API, but these appear to be the relevant items
  Id: string
  Name: string
  Description: string
  CampaignId: string
  State: DealState
  Type: DealType
  Scope: DealScope
  DiscountType: DealDiscountType
  StartDate: string | Date
  EndDate: string | Date
  Uri: string

  // ecountabl field
  ads?: Ad[]
}

enum AdType {
  Banner = "BANNER",
  TextLink = "TEXT_LINK"
}

export interface Ad {
  // There are lots more fields in the impact API, but these appear to be the relevant items
  Id: string
  Name: string
  Description: string
  CampaignId: string
  CampaignName: string
  Type: AdType
  TrackingLink: string
  LandingPageUrl: string
  AdvertiserId: string
  AdvertiserName: string
  Width: string
  Height: string
  CreativeUrl: string
  Labels: string
  AllowDeepLinking: string
  MobileReady: string
  Language: string
  StartDate: string | Date
  EndDate: string | Date
  DealId: string
  DealStartDate: string
  DealEndDate: string
  Uri: string
}
