import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { isNoSubstitutionTemplateLiteral } from 'typescript'
import api from './api-client'
import notify from './notifications-helper'

type Props = {
  parent_id: string;
  parentName?: string;
}

const ChildCompanies: React.FC<Props> = ({ parent_id, parentName }) => {
  const [children, setChildren] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    if (parent_id) {
      api.getCompanyInfo(parent_id).then(({ company }) => {
        setChildren(company.brands || [])
      }).catch(ex => dispatch(notify.error({ message: `error loading children: ${ex.message}` })))
    } else {
      setChildren([])
    }
  }, [parent_id])

  return <div className="corp-children">
    <h4>Other Brands related to {parentName}</h4>
    {children.length === 0 ? (
      <div className="alert alert-secondary">No related brands</div>
    ) : null}
    {children?.map((child, i) => (
      <span key={i}><a href={`/companies/${child._id}`}>{child.name}</a></span>
    ))}
  </div>
}

export default ChildCompanies