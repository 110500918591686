import React, { useState, useRef, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from '../spinner'
import notify from '../notifications-helper'
import api from '../api-client'
import {
  CompanyDataUploadRawInfo,
  CompanyDataUpload,
  CompanyUploadMatch
} from '@ecountabl/lib'
import CompanyDataUploadSummary, { calculateSummary } from './summary'
import CoMatchRow from './match-row'
import HeaderMappings from './header-mappings'
import HeaderNameLookup from './header-name-lookup'

const CompanyDataUploader: React.FC = (props) => {
  const [loading, setLoading] = useState(true)
  const [rawInfo, setRawInfo] = useState<CompanyDataUploadRawInfo>()
  const [upload, setUpload] = useState<CompanyDataUpload>()
  const [indicators, setIndicators] = useState([])
  const [sortedMatches, setSortedMatches] = useState<SortedMatch[]>([])
  const [viewHeaders, setViewHeaders] = useState([])

  const dispatch = useDispatch()

  useEffect(() => {
    (async () => {
      const { id } = props.match.params
      try {
        console.log('init effect')
        const u = await api.getCompanyDataUpload(id)
        setUpload(u)
        if (u.coLookupHeader) {
          await Promise.all([fetchMatches(id), fetchRawInfo(id)])
        } else {
          await fetchRawInfo(id)
        }
        setLoading(false)
      } catch (ex) {
        dispatch(notify.error({ message: `error loading upload: ${ex.message}` }))
      }
    })()

    api.getAdminIndicators().then(res => {
      const sortedIndicators = res.indicators.sort((a, b) => a.label.localeCompare(b.label))
      setIndicators(sortedIndicators)
    }).catch(ex => dispatch(notify.error({ message: `error loading indicators: ${ex.message}` })))
  }, [])

  useEffect(() => {
    if (!upload) {
      return
    }

    const matches = Object.entries(upload.matches)

    matches.forEach(([name, match]) => { match.inboundName = name })
    const sortedMatches = matches.map(([, match]) => match)
    sortedMatches.sort((a, b) => {
      return a.type.localeCompare(b.type) || a.inboundName.localeCompare(b.inboundName)
    })

    if (rawInfo && sortedMatches.length) {
      console.time('stitching')
      sortedMatches.forEach((sm, i) => {
        sm.data = rawInfo.data.find(d => d[upload.coLookupHeader] === sm.inboundName)

        if (!sm.data) {
          console.log('no data', sm)
        }

        return sm
      })
      console.timeEnd('stitching')
    }

    setSortedMatches(sortedMatches)
  }, [rawInfo, upload])

  const fetchMatches = async (id?: string) => {
    try {
      const u = await api.companyCSVUploadPreviewMatches(id || upload._id)
      setUpload(u)

      if (u.status === 'doing_fuzzy_search') {
        setTimeout(fetchMatches, 2000)
      }
    } catch (ex) {
      dispatch(notify.error({ message: `error loading matches: ${ex.message}` }))
    }
  }

  const fetchRawInfo = async (id?: string) => {
    try {
      const info = await api.getCompanyCSVUploadRawInfo(id || upload._id)
      setRawInfo(info)
    } catch (ex) {
      dispatch(notify.error({ message: `error loading raw info: ${ex.message}` }))
    }
  }

  const finishUpload = async () => {
    const summary = calculateSummary(upload)

    const messages = [
      `This will complete the upload and finalize the updates, are you sure you wish to proceed?`
    ]

    if (summary.fuzzyReviewed < summary.fuzzy) {
      messages.push(`${summary.fuzzy - summary.fuzzyReviewed} fuzzy matches will be sent to moderation if you proceed`)
    }

    const result = window.confirm(messages.join(' '))

    if (result) {
      try {
        setLoading(true)
        let u = { ...upload }
        delete u.matches
        await api.saveCompanyCSVUpload(u)
        u = await api.doCompanyCSVUpload(upload._id)
        // hack because bulkOpResult is different on this response
        u.bulkOpResult.result = u.bulkOpResult
        setUpload(u)
        dispatch(notify.success({ message: 'Upload Successful!' }))
        setLoading(false)
      } catch (ex) {
        dispatch(notify.error({ message: `error processing upload: ${ex.message}` }))
      }
    }
  }

  const setHeaderMap = (header, value) => {
    const headerMap = { ...upload.headerMap }
    headerMap[header] = value
    setUpload({ ...upload, headerMap })
  }

  if (loading) {
    return <div className="container">
      <Spinner />
    </div>
  }

  const hasMappedHeaders = Object.values(upload.headerMap).filter(h => h).length > 0

  return (
    <div className="company-data-uploader">
      <div className="container">
      <h4>{upload.name}</h4>
      <h5>Header Config</h5>
        <div className="header-config">
          <HeaderNameLookup
            upload={upload}
            onChange={coLookupHeader => {
              setUpload({
                ...upload,
                coLookupHeader,
              })
            }}
            onPreview={async () => {
              setLoading(true)
              await api.saveCompanyCSVUpload(upload)
              await fetchMatches()
              setLoading(false)
            }}
          />
          {upload.status !== 'new' ? <HeaderMappings
            upload={upload}
            indicators={indicators}
            onChange={(header, value) => setHeaderMap(header, value)}
          /> : null}
      </div>
        {upload.status === 'processed' ? (
          <div className="alert alert-secondary">
            <h6>This upload has been processed</h6>
            <div>Companies updated: <b>{upload.bulkOpResult.result.nModified}</b></div>
          </div>
        ) : null}
        {upload.status === 'wip' ? <div className="buttons">
          <p>Matches are saved automatically, header mappings must be saved manually</p>
          {!hasMappedHeaders ? <div className="alert alert-danger">
            At least 1 header must be mapped, otherwise no data will be updated
          </div> : null}
           <button
            type="button"
            className="btn btn-primary"
            onClick={async () => {
              const u = { ...upload }
              delete u.matches
              await api.saveCompanyCSVUpload(u)
              dispatch(notify.success({ message: 'header mappings saved' }))
            }}
          >Save Header Mappings</button>
          <button
            type="button"
            className="btn btn-warning"
            disabled={!hasMappedHeaders}
            onClick={finishUpload}
          >Complete Upload...</button>
        </div> : null}
      {
        upload.status === 'doing_fuzzy_search' ? (
          <div className="alert alert-info">
            <Spinner style={{ fontSize: '2em' }} />
            &nbsp;
            {upload.fuzzySearchStatus}
          </div>
        ) : null
      }
      {
        rawInfo && !Object.keys(upload.matches).length ? (
          <div className="company-data-raw">
            <h4>Raw Data Preview (unmatched, max 20 rows)</h4>
            <table>
              <tbody>
                <tr>{rawInfo.headers.map(h => <th key={h}>{h}</th>)}</tr>
                {rawInfo.data.slice(0, 20).map((row, i) => {
                  return <tr key={`raw-${i}`}>
                    {Object.values(row).map((d, i) => <td key={i}>{d}</td>)}
                  </tr>
                })}
              </tbody>
            </table>
          </div>
        ) : null
      }
      </div>{/* closes non-full width */}
      {
        Object.keys(upload.matches).length ? (
          <>
            <div className='container'>
            <h4>Preview Upload Matches</h4>
            <CompanyDataUploadSummary upload={upload} />
            <div>
              <div className="alert alert-danger">
                <div><b>REVIEW THIS LIST CAREFULLY!!</b> Especially the "fuzzy" matches for accuracy.</div>
                <div>Unmapped companies and unreviewed fuzzy matches will be sent to the moderation queue</div>
              </div>
              <div>
                  <h5>View Headers</h5>
                  <p>Toggle on and off to view additional data from the upload</p>
                  {Object.keys(upload.headerMap).map((header, i) => (
                    header !== upload.coLookupHeader ? (
                      <button
                        key={i}
                        type='button'
                        style={{ marginRight: '0.5em', marginBottom: '0.5em' }}
                        onClick={() => {
                          if (viewHeaders.includes(header)) {
                            setViewHeaders(viewHeaders.filter(h => h !== header))
                          } else {
                            setViewHeaders([...viewHeaders, header])
                          }
                        }}
                        className={`btn btn-sm btn-${viewHeaders.includes(header) ? 'info' : 'light'}`}>{header}</button>
                    ) : null
                  ))}
              </div>
            </div>
          </div>

          {/* begin full-width container */}
          <div style={{ margin: '1em' }}>
            <div> 
              <table className="co-upload-matches">
                <colgroup>
                  <col width="200px" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>Company Name from Upload</th>
                    <th>Match Type</th>
                    <th>Found Company</th>
                    {viewHeaders.map((vh, i) => <th key={i}>{vh}</th>)}
                  </tr>
                  {sortedMatches.map((match, i) => (
                    <CoMatchRow
                      key={`match-${i}`}
                      match={match}
                      viewHeaders={viewHeaders}
                      onMatchChange={m => {
                        const matches = { ...upload.matches }
                        matches[m.inboundName] = m
                        delete matches[m.inboundName].data
                        setUpload({
                          ...upload,
                          matches
                        })

                        api.updateUploadMapping(upload._id, m.inboundName, m).catch(ex => {
                          dispatch(notify.error({ message: `error updating match: ${ex.message}` }))
                        })
                      }}
                    />
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          </>
        ) : null
      }
    </div >
  )
}

export default CompanyDataUploader