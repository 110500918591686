import React, { useState, useEffect, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { Indicator, Dictionary, verifyIndicatorConfig } from '@ecountabl/lib'
import notify from '../notifications-helper'
import Spinner from '../spinner'
import { v4 } from 'uuid'
import api from '../api-client'
import { FaCaretDown, FaLeaf } from 'react-icons/fa'
import { DropdownCell, CheckboxCell, TextAreaCell, TextCell, ReadOnlyCell } from '../table-form'
import { ActionProps, IndicatorActions } from './actions'
import SupercededBy from './superceded-by'
import Overlay from '../overlay'
import DetailEditor from './detail-editor'

const COLUMNS = {
  'label': {
    component: TextCell,
    width: '300px',
    label: 'Label'
  },
  'actions': {
    component: IndicatorActions
  },
  'attachment': {
    component: DropdownCell,
    label: 'Attachment'
  },
  'superceded_by': {
    component: SupercededBy,
    label: 'Superceded By'
  },
  'included_in_scoring': {
    component: CheckboxCell,
    width: '100px',
    label: 'Include in Scoring'
  },
  'hide_from_ui': {
    component: CheckboxCell,
    width: '100px',
    label: 'Hide in UI'
  },
  'indicator_family': {
    component: DropdownCell,
    width: '150px',
    label: 'Datasource'
  },
  'impact_area': {
    component: DropdownCell,
    width: '150px',
    label: 'Impact Area'
  },
  'scale': {
    component: TextCell,
    width: '150px',
    label: 'Scale'
  },
  'certification_value': {
    component: TextCell,
    width: '100px',
    label: 'Cert Value'
  },
  'segment': {
    component: DropdownCell,
    width: '150px',
    label: 'Value'
  },
  'weight_in_segment': {
    component: TextCell,
    width: '80px',
    label: 'Weight'
  },
  'data_year': {
    component: TextCell,
    width: '150px',
    label: 'Data Year'
  },
  'description': {
    component: TextAreaCell,
    width: '300px'
  },
  // 'rating_type': {
  //   component: TextCell,
  //   width: '150px'
  // },
  'twitter': {
    component: TextCell,
    width: '150px'
  },
  'abbreviation': {
    component: ReadOnlyCell,
    width: 'auto'
  },
}

export const IndicatorTableEditor = () => {
  let values = useRef([])

  const [loading, setLoading] = useState(true)
  const [indicatorList, setIndicatorList] = useState([])
  const [valueOptions, setValueOptions] = useState([])
  const [datasourceOptions, setDatasourceOptions] = useState([])
  const [hasChanges, setHasChanges] = useState(false)
  const [saving, setSaving] = useState(false)
  const [warnings, setWarnings] = useState([])
  const [sort, setSort] = useState('')
  const [modalIndicator, setModalIndicator] = useState()
  const [impactAreaOptions, setImpactAreaOptions] = useState([])

  const dispatch = useDispatch()
  const byAbbRef = useRef()

  const fetchImpactAreaOptions = () => {
    api.getImpactAreas().then(_impactAreas => {
      setImpactAreaOptions([{
        label: '<auto-gen-and-link>',
        value: '__autogen__'
      }, ..._impactAreas.list.map(ia => ({
        label: ia.label,
        value: ia._id
      }))])
    }).catch(ex => dispatch(notify.error({ message: ex.message })))
  }

  useEffect(() => {
    fetchImpactAreaOptions()

    Promise.all([
      api.getValues(),
      api.getAdminIndicators(),
      api.getDatasources(),
    ]).then(([_values, { indicators, byAbbreviation }, _datasources]) => {
      byAbbRef.current = byAbbreviation
      setIndicatorList(indicators)
      values.current = _values
      setValueOptions(_values.map(v => ({
        label: v.label,
        value: v.slug
      })))
      setDatasourceOptions(_datasources
        .sort((a, b) => (a.label || a._id).localeCompare(b.label || b._id))
        .map(ds => ({
          label: ds.label || ds._id,
          value: ds._id
        })))

      const _warnings = verifyIndicatorConfig(values.current, indicators)
      setWarnings(_warnings)
    }).catch(ex => dispatch(notify.error({ message: ex.message })))
      .finally(() => setLoading(false))
  }, [])

  if (loading) {
    return <Spinner variant='block' />
  }

  const onArchive = (abb: Indicator) => {
    const message = `Archiving an indicator will hide it from the admin UI and archive it's company ratings. Changes will be applied on save.`
    dispatch(notify.success({ message }))

    const indicators = indicatorList.slice()
    const ind = indicators.find(i => i.abbreviation === abb)
    ind.archived = true
    ind.modified = true
    setHasChanges(true)
    setIndicatorList(indicators)

    const _warnings = verifyIndicatorConfig(values.current, indicators)
    setWarnings(_warnings)
  }

  const onChange = (key: string, abb: string, ev: React.SyntheticEvent) => {
    const indicators = indicatorList.slice()
    const ind = indicators.find(i => i.abbreviation === abb)
    if (key === 'segment') {
      ind.slug = ev.target.value
    } else if (ev.target.type === 'checkbox') {
      ind[key] = ev.target.checked
    } else {
      ind[key] = ev.target.value
    }

    ind.modified = true
    setHasChanges(true)
    setIndicatorList(indicators)

    const _warnings = verifyIndicatorConfig(values.current, indicators)
    setWarnings(_warnings)
  }

  const onSubmit = () => {
    // for future performance reasons, only send the modified list to the server
    const indicators = indicatorList.filter(i => i.modified)
    indicators.forEach(i => delete i.modified)
    setSaving(true)
    api.updateIndicators(indicators).then(res => {
      dispatch(notify.success({
        message: 'successfully updated indicators'
      }))
      setSaving(false)
      const list = indicatorList.slice().filter(i => !i.archived)
      list.forEach(i => delete i.modified)
      setIndicatorList(list)
    }).catch(ex => dispatch(notify.error(ex)))
  }

  const onSort = (key) => {
    setSort(key)
    const indicators = indicatorList.slice()
    indicators.sort((a, b) => {
      if (a[key] === b[key]) {
        return 0
      }

      if (a[key] === undefined) {
        return 1
      }

      if (b[key] === undefined) {
        return -1
      }

      if (typeof a[key] === 'string') {
        return a[key].localeCompare(b[key])
      } else if (typeof a[key] === 'number') {
        return a[key] - b[key]
      } else if (typeof a[key] === 'boolean') {
        return a[key] - b[key]
      }
    })

    setIndicatorList(indicators)
  }

  return <div className="indicators-editor">
    {warnings.length > 0 && <div className="indicator-warnings">
      <h5>The following warnings should be addressed to ensure accurate scoring</h5>
      {warnings.map((w, i) => <div key={i}>{w}</div>)}
    </div>}
    <div className='alert alert-info'>
      <p>Not seeing an indicator in the 'superceded by' dropdown? Ensure that it's not disabled from scoring and assigned the
        Value of the indicator you're looking to supercede.</p>
      <p>Chained supercession is supported; IE, if i1 is superceded by i2, which is superceded by i3, then i3's configuration
        will take precedence. If superceding, the weight is taken from the superceding indicator. Superceded indicators will
        be hidden from the API (and as a result, from the UI as well).
      </p>
    </div>
    {modalIndicator ? <Overlay onClose={() => setModalIndicator(undefined)}>
      <DetailEditor
        indicator={modalIndicator}
        onDone={_ind => {
          const indicators = indicatorList.slice()
          const ind = indicatorList.find(i => i.abbreviation === _ind.abbreviation)
          Object.assign(ind, _ind, { modified: true })
          setHasChanges(true)
          setIndicatorList(indicators)
          setModalIndicator(undefined)
        }}
      />
    </Overlay> : null}
    <div className="buttons">
      <button
        type="button"
        className="btn btn-primary"
        disabled={!hasChanges || saving}
        onClick={onSubmit}>{saving ? <Spinner /> : "Save Changes"}</button>
      <button
        type="button"
        className="btn btn-success"
        disabled={saving}
        onClick={() => {
          setIndicatorList([...indicatorList, {
            modified: true,
            included_in_scoring: false,
            abbreviation: 'i_' + v4().split('-').pop()
          }])
          setHasChanges(true)
          dispatch(notify.success({ message: 'new indicator row created at bottom of table' }))
        }}>Add</button>
    </div>

    <div className="table-wrapper">
      <table className="table-form">
        <thead>
          <tr>
            {Object.entries(COLUMNS).map(([key, stx]) => (
              <th
                key={key}
                style={{ minWidth: stx.width }}
                onClick={onSort.bind(this, key === 'segment' ? 'slug' : key)}
              >
                {stx.label || key}
                {key === sort ? <FaCaretDown /> : ""}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {indicatorList.map((ind, i) => (
            <tr key={i} className={[ind.modified && "modified", ind.archived && "archived"].join(' ')}>{
              Object.entries(COLUMNS).map(([key, stx]) => {
                let p = {
                  ind,
                  value: ind[key],
                  indsByAbb: byAbbRef.current,
                  onChange: onChange.bind(undefined, key, ind.abbreviation)
                }

                if (key === 'actions') {
                  p = {
                    ind,
                    onArchive,
                    onEdit: i => setModalIndicator(i)
                  }
                } else if (key === 'indicator_family') {
                  p.values = datasourceOptions
                } else if (key === 'impact_area') {
                  p.values = impactAreaOptions
                } else if (key === 'attachment') {
                  p.values = [
                    { label: 'biz', value: 'biz' },
                    { label: 'bank', value: 'bank' }
                  ]
                  p.value = ind.attachment || 'biz' // by default attachment is biz
                } else if (key === 'segment') {
                  p.values = valueOptions
                  p.value = ind.slug
                }

                if (key === 'included_in_scoring' && ind[key] !== false) {
                  // true by default
                  p.value = true
                }

                return <td key={key}>
                  <stx.component {...p} />
                </td>
              })
            }</tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
}

export default IndicatorTableEditor