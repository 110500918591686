import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import Spinner from './spinner'
import { Link } from 'react-router-dom'
import { useHistory } from 'react-router-dom'
import notify from './notifications-helper'
import { Datasource } from '@ecountabl/lib'

const Datasources: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [datasources, setDatasources] = useState([])

  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    api.getDatasources().then(
      (ds) => {
        setDatasources(ds)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        dispatch(notify.error({ message: `Error loading datasources [${error.message}]` }))
      }
    )
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <Spinner variant='block' />
        </div>
      ) : (
        <div className="container mt-5">
          <h1>Datasources</h1>
            <p>Click on any of the datasources below to view or edit them.</p>
            <button className='btn btn-primary'
              onClick={() => {
                history.push(`/datasource/new`)
              }}
            >New Datasource</button>
          <table className="table mt-5">
            <tbody>
              {datasources.map((datasource: Datasource) => (
                <tr key={datasource._id}>
                  <td style={{ cursor: 'pointer' }}>
                    <Link to={`/datasource/${datasource._id}`}>{datasource.label || datasource._id}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default Datasources
