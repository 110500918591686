import Notifications from 'react-notification-system-redux'
import _ from 'lodash'

/*******************************************
  Notification options, per https://github.com/gor181/react-notification-system-redux: {
    uid: 'once-please', // you can specify your own uid if required
    title: 'Hey, it\'s good to see you!',
    message: 'Now you can see how easy it is to use notifications in React!',
    position: 'tr',
    autoDismiss: 0,
    action: {
     label: 'Click me!!',
     callback: () => alert('clicked!')
    }
  }
*******************************************/

const NOTIFICATION_POSITION = 'tr';
const ERROR_TIMEOUT = 0;
const DEFAULT_TIMEOUT = 3;


export default {
  error: function(opts) {
    return Notifications.error(_.extend({
      position: NOTIFICATION_POSITION,
      autoDismiss: ERROR_TIMEOUT,
    }, opts));
  },

  success: function(opts) {
    return Notifications.success(_.extend({
      position: NOTIFICATION_POSITION,
      autoDismiss: DEFAULT_TIMEOUT,
    }, opts));
  }
}

export const style = {
  NotificationItem: { // Override the notification item
    DefaultStyle: { // Applied to every notification, regardless of the notification level
      height: 'auto',
      fontSize: '1em',
      borderTop: 'none',
      border: 'none',
      color: 'white',
    },

    success: { // Applied only to the success notification item
      backgroundColor: '#336600'
    },

    error: {
      backgroundColor: '#aa1717'
    }
  },

  Dismiss: {
    DefaultStyle: {
      'background': 'none'
    }
  }
}
