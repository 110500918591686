import React from 'react';
import { Route, Switch } from 'react-router-dom';
import EcountablNav from './ecountabl-nav'
import ResetPassword from './reset-password'
import ForgotPassword from './forgot-password'
import './public-page.css'

const PublicPage = (props) => {
  return (
    <div className="public-page">
      <EcountablNav />
      <div className="page-content">
        <Switch>
          <Route path="/reset-password/:token" component={ResetPassword} />
          <Route path="/forgot-password" component={ForgotPassword} />
        </Switch>
      </div>
    </div>
  )
}

export default PublicPage
