import React, { useEffect, useState } from 'react'
import { Customer, Dictionary } from '@ecountabl/lib'
import FormField from '../form-field'
import api from '../api-client'
import { useDispatch } from 'react-redux'
import notify from '../notifications-helper'
import Spinner from '../spinner'

type Props = {
  sortOrder: Dictionary<number>
  onChange: (order: Dictionary<number>) => void
}

export const ValueSortOrderForm = ({ sortOrder, onChange }: Props) => {
  const dispatch = useDispatch()

  const [loading, setLoading] = useState(true)
  const [values, setValues] = useState([])

  useEffect(() => {
    api.getValues().then(_values => {
      _values.sort((a, b) => {
        return sortOrder[b.slug] - sortOrder[a.slug]
      })

      setValues(_values)
      setLoading(false)
    }).catch(ex => dispatch(notify.error({ message: ex.message })))
  }, [])

  useEffect(() => {
    setValues([...values].sort((a, b) => {
      return sortOrder[b.slug] - sortOrder[a.slug]
    }))
  }, [sortOrder])

  if (loading) {
    return <Spinner />
  }

  const onMove = (i, val) => {
    const _values = values.filter(_v => _v._id !== val._id)
    _values.splice(i, 0, val)

    const _order = {}
    _values.forEach((_v, _i) => _order[_v.slug] = _values.length - _i)
    onChange(_order)
  }

  return <div className="values-sort-order">
    <h4>Values Sort Order</h4>
    <p>Values will be displayed in the following order in the app</p>
    <div className="list-group">
      {values.map((v, i) => <div key={v._id} className="list-group-item">
        <div>{v.label}</div>
        <div>
          <i className="fa fa-angle-double-up" onClick={() => onMove(0, v)} />
          <i className="fa fa-angle-up" onClick={() => onMove(i-1, v)} />
          <i className="fa fa-angle-down" onClick={() => onMove(i+1, v)} />
          <i className="fa fa-angle-double-down" onClick={() => onMove(values.length - 1, v)} />
        </div>
      </div>)}
    </div>
  </div>
}

export default ValueSortOrderForm