import React, { useEffect, useState } from 'react'
import Spinner from '../spinner'
import notify from '../notifications-helper'
import { Partnership, bestPhotoFit, PARTNERSHIP_MAX_IMG_DIMS } from '@ecountabl/lib'
import ImageUploader from '../image-uploader'

type PartnershipChangeEvent = {
  partnershipHeader?: string
  partnershipSubtext?: string
  partnerships: Partnership[]
}

type FormProps = {
  partnershipSubtext?: string
  partnershipHeader?: string
  partnerships: Partnership[]
  onChange: (p: PartnershipChangeEvent) => void
}

const PartnershipsForm = ({
  partnerships,
  partnershipHeader,
  partnershipSubtext,
  onChange
}: FormProps) => {
  return (
    <div className="data-form">
      <h4>Partnerships</h4>
      <p>
        Images will be displayed {PARTNERSHIP_MAX_IMG_DIMS.width}w x {PARTNERSHIP_MAX_IMG_DIMS.height}h.
        Link URLs are optional.
      </p>
      <div className="form-group">
        <label>Header</label>
        <input
          type="text"
          value={partnershipHeader || ''}
          className="form-control"
          onChange={ev => onChange({
            partnershipSubtext,
            partnershipHeader: ev.target.value,
            partnerships
          })}
        />
      </div>
      <div className="form-group">
        <label>Subtext</label>
        <input
          type="text"
          value={partnershipSubtext || ''}
          className="form-control"
          onChange={ev => onChange({
            partnershipSubtext: ev.target.value,
            partnershipHeader,
            partnerships
          })}
        />
      </div>
      <div>
        {!partnerships?.length ? <div className="alert alert-secondary">No partnerships configured</div> : null}
        {partnerships?.map((p, i) => <div key={i} className="form-inline" style={{ marginBottom: '1em', alignItems: 'flex-start' }}>
          <div style={{ marginRight: '1em' }}>
            <ImageUploader
              onChange={(imgUrl, size) => {
                const ps = [...partnerships]
                ps[i].imgUrl = imgUrl       
                const fit = bestPhotoFit(
                  PARTNERSHIP_MAX_IMG_DIMS.width, PARTNERSHIP_MAX_IMG_DIMS.height,
                  size.width, size.height
                )
                ps[i].imgHeight = fit.height
                ps[i].imgWidth = fit.width
                onChange(ps)
              }}
              imgSrc={p.imgUrl || ''}
              style={{
                height: `${PARTNERSHIP_MAX_IMG_DIMS.height}px`,
                width: `${PARTNERSHIP_MAX_IMG_DIMS.width}px`
              }}
              hidePrompt
            />
          </div>
          <div>
            <label>Link URL</label>
            <input
              className="form-control"
              type="text"
              value={p.link}
              style={{ width: '300px' }}
              placeholder="https://example.com"
              onChange={ev => {
                const ps = [...partnerships]
                ps[i].link = ev.target.value
                onChange(ps)
              }}
            />
            <i className="fa fa-trash" style={{
              cursor: 'pointer',
              fontSize: '1.5em',
              margin: '0.5em'
            }} onClick={() => {
              const ps = [...partnerships]
              ps.splice(i, 1)
              onChange(ps)
            }} />
          </div>
          {/* <div style={{ padding: '0.5em' }}>
          </div> */}
        </div>)}
        <div>
          <button
            type="button"
            className="btn btn-normal"
            onClick={() => {
              onChange([...partnerships, { imgUrl: '', link: '' }])
            }}
          >Add New Partnership <i className="fa fa-plus-circle" /></button>
        </div>
      </div>
    </div>
  )
}

export default PartnershipsForm
