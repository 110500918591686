import React, { useState } from 'react';
import api from '../api-client'

const SEGMENTS = { 
  CLIMATE_CHANGE: 'Climate',
  ENVIRONMENTAL_PROTECTION: 'Environment',
  GENDER_EQUALITY: 'Gender Equity',
  RACIAL_EQUALITY: 'Racial Equality',
  LGBTQ_EQUALITY: 'LGBTQ Equality',
  HUMAN_RIGHTS: 'Human Rights',
  BUSINESS_INTEGRITY: 'Business Integrity',
  WORKER_PAY_BENEFITS: 'Worker Pay & Benefits',
  COMMUNITY_SUPPORT: 'Community Support' 
}

const DEFAULT_SCORES = {}
Object.keys(SEGMENTS).forEach(seg => {
  DEFAULT_SCORES[seg] = 2
})

const ScoreTinkerer = ({ companyId, style }) => {
  const [scores, setScores] = useState(DEFAULT_SCORES)
  const [results, setResults] = useState(undefined)
  const segments = Object.keys(scores)

  return <div className="score-tinkerer" style={style}>
    <h4>Score Tinkerer</h4>
    <p>
      Enter scores from 0-4 then click "test score" to see the result. The "weighted" field
      represents the weighted score from that segment based on the user's values. The total
      score is the sum of the "weighted" field.
    </p>
    <div style={{ flexDirection: 'row', display: 'flex' }}>
      <div style={{ flex: 2 }}>
        {segments.map(seg => {
          return <div key={seg} className="form-group">
            <label>{SEGMENTS[seg]}</label>
            <input 
              type='number' 
              className="form-control"
              value={scores[seg]}
              onChange={ev => {
                const draftScores = Object.assign({}, scores, { [seg]: parseInt(ev.target.value, 10) })
                setScores(draftScores)  
              }}
            />
          </div>
        })}
      </div>
      <div className="calculated-ratings" style={{ flex: 1, fontSize: '0.8em', marginLeft: '1em', marginBottom: '1em' }}>
        <pre>{results 
          ? JSON.stringify(results, false, ' ')
          : 'Click "Test Score"'
        }</pre>
      </div>
    </div>
    <div style={{ textAlign: 'right' }}>
      <button 
        type='button'
        className='btn btn-secondary'
        onClick={() => {
          api.getScoreForValues(companyId, scores).then(res => {
            setResults(res)
          }).catch(ex => {
            alert(ex.message)
          })        
        }}
      >Test Score</button>
    </div>
  </div>
}

export default ScoreTinkerer