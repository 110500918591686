import React, { useEffect, useState } from 'react'
import api from './api-client'
import { Customer } from '@ecountabl/lib'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

const Customers: React.FC = () => {
  const [loading, setLoading] = useState(true)
  const [customers, setCustomers] = useState([])
  const history = useHistory()

  useEffect(() => {
    api.getCustomers().then(
      (customers) => {
        setCustomers(customers)
        setLoading(false)
      },
      (error) => {
        setLoading(false)
        console.error('There was an error loading customers:', error)
      }
    )
  }, [])

  return (
    <>
      {loading ? (
        <div className="d-flex vh-100 justify-content-center align-items-center ">
          <div
            className="spinner-border"
            style={{ width: '3rem', height: '3rem' }}
          ></div>
        </div>
      ) : (
        <div className="container mt-5">
            <h1>Customers</h1>
            <div className="buttons">
              <button className='btn btn-primary'
                onClick={() => {
                  history.push(`/customers/new`)
                }}
              >New Customer</button>
            </div>
          <table className="table mt-5">
            <tbody>
              {customers.map((c: Customer) => (
                <tr key={c._id}>
                  <td style={{ cursor: 'pointer' }}>
                    <Link to={`/customers/${c._id}`}>{c.name}</Link>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  )
}

export default Customers
