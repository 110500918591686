import React, { useState, useRef, useEffect } from 'react'

export const AdminNavMenuDropdown = ({
  labelElement,
  menuElements,
  menuStyle = {}
}) => {
  const menu = useRef()

  const [showDropdown, setShowDropdown] = useState(false)

  useEffect(() => {
    const listener = ev => {
      if (!menu.current?.contains(ev.target)) {
        setShowDropdown(false)
      }
    }

    window.document.addEventListener('click', listener)

    return () => {
      window.document.removeEventListener('click', listener)
    }
  }, [])

  return <div className="nav-item dropdown">
    <a className="dropdown-toggle"
      href="#"
      onClick={() => setShowDropdown(!showDropdown)}
      id="navbarDropdown"
      role="button"
      data-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
      ref={menu}
    >
      {labelElement}
    </a>
    {showDropdown && <div
      className="dropdown-menu"
      aria-labelledby="navbarDropdown"
      style={{
        left: 'unset',
        display: 'block',
        ...menuStyle
      }}
    >
      {menuElements}
    </div>}
  </div>
}

export default AdminNavMenuDropdown