import React from 'react'

type OverlayProps = {
  children: React.ReactElement
  onClose?: () => void
}

export const Overlay = ({ children, onClose }) => {
  return (
    <div className="overlay">      
      <div className="overlay-cont">
        <a className="overlay-close" onClick={onClose}>
          <i className="fa fa-close" />
        </a>
        {children}
      </div>
    </div>
  )
}

export default Overlay