import React from 'react'
import GoogleLogo from '../img/google-logo.png'

const PLACEHOLDERS = {
  website: 'Paste any URL and it will automatically extract the link'
}

type Props = {
  label?: string
  field?: string | React.ReactElement
  hideIfEmpty?: boolean
  onChange?: ({ target: { value }}) => void
  value?: string | number
  readOnly?: boolean
  placeholder?: string
}

const CustomerInputField: React.FC<Props> = (props: Props) => {
  const { label, field, hideIfEmpty, onChange, value, readOnly, placeholder } = props
  if (field === "_id") { return null }

  if (!value && hideIfEmpty) { return null }

  return <div key={field} className="form-group">
    <label>{label || field}</label>
    <div className="form-group-input">
      <input
        type="text"
        className="form-control"
        onChange={onChange}
        value={value || ""}
        readOnly={readOnly}
        placeholder={placeholder || PLACEHOLDERS[field]}
      />
      {field === "name" && (
        <a href={`https://google.com/search?q=${encodeURIComponent(value)}`} target="_blank">
          <img
            src={GoogleLogo} style={{ height: "30px", margin: "4px" }}
          />
        </a>
      )}
    </div>
  </div>
}

export default CustomerInputField
