import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Nav from './nav'
import Home from './home'
import Notifications from 'react-notification-system-redux'
import { style as notificationStyle } from '../notifications-helper'
import NotFound from '../not-found'
import { useSelector } from 'react-redux'

const UserSite = () => {
  const { notifications } = useSelector(s => s)

  return (
    <div style={{ marginBottom: '10em' }}>
      <Nav />
      <Notifications notifications={notifications} style={notificationStyle} />
      <div className="container">
        <Switch>
          <Route path="/home" component={Home} />
          <Route component={NotFound} />
        </Switch>
      </div>
    </div>
  )
}

export default UserSite
