import React from 'react'
import api from './api-client'
import fileDownload from './file-download'
import { CompanyDataUploads } from './company-data-upload'
import TrickleDownUpdater from './trickle-down-updater'
import UpdateUserScores from './update-user-scores'
import UpdatePerformanceByValue from './update-performance-by-value'
import UpdatePlaidItems from './update-plaid-items'
import ReindexElasitcsearch from './reindex-elasticsearch'
import { DataDuplicator } from './indicators'

const DataPage: React.FC = () => {
  return (
    <div className="data-page container">
      <div className="data-form"><CompanyDataUploads /></div>
      <div className="data-form"><DataDuplicator /></div>

      <h4>Danger Zone</h4>
      <p>Probably talk to Justin if you feel compelled to mash any of these buttons 😜</p>
      <div className="buttons v-buttons">
        <TrickleDownUpdater />
        <UpdateUserScores />
        <UpdatePerformanceByValue />
        <UpdatePlaidItems />
        <ReindexElasitcsearch />
        <button className="btn btn-primary" onClick={() => {
          api.authenticatedFetch('/api/admin/company/export', {
            responseType: 'blob'
          }).then(async res => {
            const blob = await res.blob()
            fileDownload(blob, 'first-test.csv')
          }).catch(ex => console.error(ex))
        }}>Download Company CSV</button>
      </div>
    </div>
  )
}

export default DataPage