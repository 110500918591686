import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import api from './api-client'
import Spinner from './spinner'
import { Company } from '@ecountabl/lib'
import { CompanySearchWrapper } from './company-search'
import notify from './notifications-helper'
import fileDownload from './file-download'

const CoListItem = ({ company, selected, onSelectCurrentCo, onAddAsNew }) => {
  return (
    <div
      className={`csr-co ${selected ? 'active' : ''}`}
      key={company._id}
      onClick={e => onSelectCurrentCo(company)}
    >
      <div>
        <div>
          {company.name}
          &nbsp;{company.logourl ? <i className="fa fa-image" title="this company has a logo" /> : null}
        </div>
        <div className="small">Source: {company.source || "n/a"}</div>
        {company.dateSubmitted && <div className="small">Submitted: {company.dateSubmitted}</div>}
      </div>
      {selected && <>
        <button
          type="button"
          className="add-new btn btn-sm btn-outline-primary"
          onClick={e => onAddAsNew(e)}
        >Preview</button>
      </>}
    </div>
  )
}

const ModerateCompanies = () => {
  const [companies, setCompanies] = useState<Company[]>([])
  const [total, setTotal] = useState(0)
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(true)
  const [loadingSources, setLoadingSources] = useState(true)
  const [exporting, setExporting] = useState(false)
  const [sort, setSort] = useState('dateSubmitted')
  const [searchQuery, setSearchQuery] = useState('')
  const [sources, setSources] = useState([])
  const [hasLogo, setHasLogo] = useState(false)
  const [sourceFilter, setSourceFilter] = useState('')
  const [currentCo, setCurrentCo] = useState()
  const dispatch = useDispatch()

  const fetchCompanies = (): void => {
    setLoading(true)
    api.getCompaniesUnderModerationAtPage({
      page,
      sort,
      hasLogo,
      source: sourceFilter,
      query: searchQuery
    }).then(
      ({ companies, total }) => {
        setCompanies(companies)
        setTotal(total)
        setLoading(false)
      }
    ).catch(ex => dispatch(notify.error(ex.message)))
  }

  useEffect(() => {
    fetchCompanies()
  }, [page, sort, sourceFilter, hasLogo])

  useEffect(() => {
    if (searchQuery.length === 0 || searchQuery.length > 2) {
      fetchCompanies()
    }
  }, [searchQuery])

  useEffect(() => {    
    api.getModerationSources().then(_sources => {
      setSources(_sources)
      setLoadingSources(false)
    }).catch(ex => dispatch(notify.error(ex.message)))
  }, [])

  const changePage = (increment: boolean) => {
    setPage(increment ? page + 1 : Math.max(0, page - 1))
  }

  let rightPanel = <React.Fragment />

  if (currentCo) {
    const initialSearch = currentCo.name.replace(/,? ?(Corp\.|Co\.|Inc\.|LL[PC]|Ltd)/gi, '').trim()
    rightPanel = (
      <CompanySearchWrapper
        buttonText="Preview Merge"
        autoSearch={true}
        onSelect={(coId: string) => {
          // 'noreferrer' needed to prevent window from openining in same thread 🤯
          window.open(`/companies/merge/${coId}?sourceId=${currentCo._id}&sourceType=moderation`, '_blank', 'noreferrer')
        }}
        initialSearch={initialSearch}
      />
    )
  } else {
    rightPanel = (
      <div className="card bg-light">
        <div className="card-body">
          {'Select a company to map it to a company'}
        </div>
      </div>
    )
  }

  return (
    <>
      <div className="co-moderation container mt-5" style={{
        marginBottom: '5em',
        display: 'flex'
      }}>
        <div style={{ flex: 3 }}>
          <h3>Company Moderation Queue</h3>
          <p>
            These companies are awaiting moderation; they may have been submitted by a user or
            found in one of our datasources. Click a company
            to moderate or approve the company. Once approved it will be added to our
            database.
          </p>
          <p>Total companies in queue: <b>{total}</b>&nbsp;
            <button
              type="button"
              className="btn btn-secondary"
              disabled={exporting}
              style={{ marginLeft: '1em' }}
              onClick={() => {
                setExporting(true)
                api.authenticatedFetch('/api/admin/moderate-companies/export', {
                  responseType: 'blob'
                }).then(async res => {
                  const blob = await res.blob()
                  fileDownload(blob, 'moderate-companies.csv')
                  setExporting(false)
                }).catch(ex => dispatch(notify.error({ message: `error exporting companies: ${ex.message}` })))
              }}>
              {exporting ? <Spinner /> : <i className="fa fa-download" />}
            </button>
          </p>

          <div className="control-wrapper">
            <div className="controls">
              <label>Sort</label>
              <select
                className="form-control"
                onChange={(e) => {
                  setCurrentCo(undefined)
                  setSort(e.target.value)
                }}
                value={sort}
              >
                <option value="name">Company Name</option>
                <option value="dateSubmitted">Date Submitted</option>
                <option value="source">Source</option>
              </select>
              <label>Source</label>
              {loadingSources ? <Spinner /> : <select
                className="form-control"
                onChange={(e) => {
                  setCurrentCo(undefined)
                  setSourceFilter(e.target.value)
                }}
                value={sourceFilter}
              >
                <option></option>
                {sources.map((s, i) => <option key={i} value={s.label}>{s.label} ({s.count})</option>)}
              </select>}
              {/* <Pagination
                    page={this.state.page}
                    onChange={page => {
                      this.setState({ page }, this.fetchTransactions)
                    }}
                  /> */}
              <div className="input-group">
                <input
                  type="text"
                  className="form-control"
                  value={searchQuery}
                  placeholder="Search"
                  style={{ maxWidth: '250px' }}
                  onChange={(ev) => {
                    setSearchQuery(ev.target.value)
                  }}
                />
                <div className="input-group-append">
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      setSearchQuery('')
                    }}
                    className="input-group-text"
                  ><i className="fa fa-close" /></span>
                </div>
              </div>
            </div>
            <div className="controls">
              <label>Has Logo</label>
              <input
                className="form-control"
                type="checkbox"
                checked={hasLogo}
                onChange={() => setHasLogo(!hasLogo)}
              />
            </div>
          </div>

          <div className="csr-cos">
            {loading ? (
              <div>Loading</div>
            ) : (
              companies.map((company) => {
                const selected = company._id === currentCo?._id
                return <CoListItem
                  key={company._id}
                  company={company}
                  selected={selected}
                  onSelectCurrentCo={setCurrentCo}
                  onAddAsNew={e => {
                    // 'noreferrer' needed to prevent window from openining in same thread 🤯
                    window.open(`/moderate/${currentCo._id}`, '_blank', 'noreferrer')
                  }}
                />
              })
            )}
          </div>
          <div className="d-flex mt-3">
            <button
              className="btn btn-primary btn-sm mr-1"
              onClick={() => changePage(false)}
            > Previous Page </button>
            <button
              className="btn btn-primary btn-sm mr-1"
              onClick={() => changePage(true)}
            > Next Page </button>
            <div>Page: {page}</div>
          </div>
        </div>
        <div style={{ flex: 2, marginLeft: '1em' }}>
          <div className="csr-right-panel">
            {rightPanel}
          </div>
        </div>
      </div>
    </>
  )
}

export default ModerateCompanies
